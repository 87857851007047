import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, InputAdornment, Typography, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import CustomAlert from "../../Components/CustomAlert";
import { addUserAPI } from "../../actions/Users/authenticate";
import DialogBox from "../../Components/DialogBox";
import Scroll from "react-scroll";
import _ from "lodash";
import "./Registration.css";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomSnackbar from "../../Components/Loader/CustomSnackbar";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { Base64 } from "js-base64";
import k4Logo from "../../assets/images/k4Logo.png";
import Box from "@mui/material/Box";
import useStyles from "./style";
import k4LogoMobileView from "../../assets/images/k4LogoMobileView.png";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SC_REDIRECT_URL } from "../../config";

const scroll = Scroll.animateScroll;

function Registration({ history, authReducer, errorReducer, addUserAPI }) {
  const { userNamePar, passwordPar } = useParams();
  const classes = useStyles();
  const [userName, setUserName] = useState(userNamePar);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [contactNo, setContactNo] = useState(null);
  let [contactNoCountryCode, setContactNoCountryCode] = useState("");
  let [contactNoDialCode, setContactNoDialCode] = useState("");
  const [oldPassword, setOldPassword] = useState(passwordPar);
  const [newPassword, setNewPassword] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [helperTextList, setHelperTextList] = useState({
    firstName: "First name is invalid",
    lastName: "Last name is invalid",
    reEnterPassword: "Password and confirm password didn’t match",
    contactNo: "Contact number is invalid",
    newPassword: "Use 5 characters or more for your password",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    firstName: true,
    lastName: true,
    contactNo: true,
    newPassword: true,
    reEnterPassword: true,
  });
  let [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  let [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    snackbarVariant: "",
    snakBarMessage: "",
    position: "left",
  });

  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  let [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 15000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && "" !== errorReducer.message) {
      let message = "Unable to complete registration, unknown error.";
      if (errorReducer.description) {
        if ("string" === typeof errorReducer.description) {
          message = errorReducer.description;
        } else if (
          errorReducer.description.message &&
          "string" === typeof errorReducer.description &&
          "" !== errorReducer.description.message.trim()
        ) {
          message = errorReducer.description.message;
        }
      }
      setLoaders({ showLoader: false, loadingText: "" });
      setAlertMsg({ msg: message, type: "error" });
      setShowAlert(true);
      errorReducer.error = {};
    } else {
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.addUser)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.addUser = {};
    } else {
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [authReducer.addUser]);

  useEffect(()=>{
    if(!_.isEmpty(contactNo) && !_.isEmpty(contactNoCountryCode) && !_.isEmpty(contactNoDialCode)){
      checkContactNo(contactNo);
    }
  }, [contactNoCountryCode, contactNoDialCode, contactNo])
  
  const checkAllFieldsValidation = (notErrorInFields) => {
    let isExist = 0;
    Object.keys(notErrorInFields).map((keysName) => {
      if (notErrorInFields[keysName] === false) {
        isExist = 1;
        setAllFieldsIsValidORnot(notErrorInFields[keysName]);
      }
    });
    if (0 === isExist) setAllFieldsIsValidORnot(true);
  };

  const handlePhoneNumber = (value, country, e, formattedValue) => {
    let checkVlidation = formattedValue.split("+" + country.dialCode + " ")[1];
    let countryCode = country.countryCode;
    if (checkVlidation && checkVlidation !== "") {
      notErrorInFields["contactNo"] = isValidPhoneNumber(
        checkVlidation,
        countryCode.toUpperCase()
      ) || notErrorInFields["contactNo"] !== null;
    } else { notErrorInFields["contactNo"] = false }
    
    if(contactNoCountryCode != country.countryCode && !_.isEmpty(formattedValue)){
      let enteredDailCode = formattedValue.split(' ')[0];
      formattedValue = "+" + country.dialCode + "" + formattedValue.split(enteredDailCode)[1];
    }

    setContactNo(formattedValue);
    setContactNoCountryCode(country.countryCode);
    setContactNoDialCode("+" + country.dialCode + " ");
    //checkContactNo(formattedValue);
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const checkContactNo = (value) => {
    if (value && "" !== value && contactNoCountryCode && contactNoDialCode) {
      let checkVlidation = value.split(contactNoDialCode)[1];
      if (checkVlidation && checkVlidation !== "") {
        notErrorInFields["contactNo"] = isValidPhoneNumber( checkVlidation, contactNoCountryCode.toUpperCase());
      } else { notErrorInFields["contactNo"] = false }
    } else { notErrorInFields["contactNo"] = false }
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const checkPassword = (password) => {
    notErrorInFields["newPassword"] = password.length >= 5 && password.length <= 29 ? true : false;
    notErrorInFields["reEnterPassword"] = reEnterPassword === password ? true : false;
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const checkConfirmPassword = (password) => {
    notErrorInFields["reEnterPassword"] = newPassword === password ? true : false;
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const checkFirstName = (value) => {
    const freg = /^[a-zA-Z0-9\s-\s_ ]+$/;
    notErrorInFields["firstName"] = freg.test(value);
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const checkLastName = (value) => {
    const lreg = /^[a-zA-Z0-9\s-\s_ ]+$/;
    notErrorInFields["lastName"] = lreg.test(value);
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    } else if (name === "contactNo") {
      checkContactNo(event.target.value);
      setContactNo(event.target.value);
    } else if (name === "oldPassword") {
      setOldPassword(event.target.value);
    } else if (name === "newPassword") {
      checkPassword(event.target.value);
      setNewPassword(event.target.value);
    } else if (name === "reEnterPassword") {
      checkConfirmPassword(event.target.value);
      setReEnterPassword(event.target.value);
    } else if (name === "firstName") {
      checkFirstName(event.target.value);
      setFirstName(event.target.value);
    } else if (name === "lastName") {
      checkLastName(event.target.value);
      setLastName(event.target.value);
    }
  };

  const handleAddUser = () => {
    let payload = {};
    checkContactNo(contactNo);
    checkFirstName(firstName);
    checkLastName(lastName);
    checkPassword(newPassword);
    checkPassword(reEnterPassword);
    if (!allFieldsIsValidORnot) {
      setAlertMsg({ msg: "Please fill all required fields.", type: "error" });
      setShowAlert(true);
      return;
    }
    if (contactNo && contactNo != "") {
      setLoaders({
        showLoader: true,
        loadingText: "Registering user please wait...",
      });
      scroll.scrollToTop();
      payload = {
        userName: userName,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        currentPassword: oldPassword,
        newPassword: Base64.encode(newPassword),
        phone: contactNo,
      };
      addUserAPI(payload);
    } else {
      setAlertMsg({ msg: "Please enter a valid phone number.", type: "error" });
      setShowAlert(true);
    }
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      finalMessage: "",
      showSnackbar: false,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container component="main" className={classes.root} >
      {showAlert && showAlert === true ? (<CustomAlert type={alertMsg.type} msg={alertMsg.msg} />) : null}
      <Grid item container xs={12} style={{ height: "100%" }}>
        <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
          <Grid xs={0} sm={6} md={6} lg={6} className={classes.logoContainer}>
            <img src={k4Logo} style={{ width: "50%" }} />
          </Grid>
        </Box>
        <Grid xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{ xs: "none", sm: "contents", md: "contents", lg: "contents" }}>
            <Typography className={classes.logoCaption}> K4 Mobility Registration </Typography>
            <Typography className={classes.loginText}> Please fill following fields for the Username : {userName} to complete the registration.
            </Typography>
          </Box>
          <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
            <Grid>
              <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }} />
              <Grid className="loginMobileText"> Please fill following fields for the Username : {userName} to complete the registration.</Grid>
            </Grid>
            <Typography className={classes.logoCaptionMobile}> K4 Mobility Registration </Typography>
          </Box>
          <Grid container item xs={12}>
            <Grid xs={11} sm={10} md={5} lg={5}>
              <Box sx={{"& .MuiTextField-root": { m: 1, width: "100%",  marginTop: "40px"}}} className="TextBoxMobileView">
                <TextField
                  id="outlined-basic"
                  label="First Name*"
                  variant="outlined"
                  placeholder="Type something"
                  size="small"
                  value={firstName}
                  name="firstName"
                  onChange={handleChange}
                  InputLabelProps={{ className: "UserNameInput" }}
                  InputProps={{ className: "labelTextbox" }}
                />
              </Box>
            </Grid>
            <Grid xs={11} sm={9} md={5} lg={5} className="inputBoxMobileView">
              <Box sx={{"& .MuiTextField-root": { m: 1, width: "100%"}}}>
                <TextField
                  id="outlined-basic"
                  label="Last Name*"
                  variant="outlined"
                  placeholder="Type something"
                  size="small"
                  value={lastName}
                  name="lastName"
                  onChange={handleChange}
                  InputLabelProps={{ className: "UserNameInput" }}
                  InputProps={{ className: "labelTextbox" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} className="inputBoxMobileView1">
            <Grid xs={11} sm={10} md={5} lg={5} >
              <Box sx={{ "& .MuiTextField-root": { m: 1, height: "10px" }}} className="contactNumber">
                <PhoneInput
                  country={"us"}
                  value={contactNo}
                  placeholder="Phone number"
                  inputProps={{
                    name: "contactNo",
                    required: true,
                    autoFocus: false,
                    autocomplete: "off",
                  }}
                  inputStyle={{ width: "100% !important" }}
                  searchStyle={{ paddingLeft: "0px" }}
                  enableSearch={true}
                  onChange={(value, country, e, formattedValue) => {handlePhoneNumber(value, country, e, formattedValue)}}
                />
              </Box>
              {notErrorInFields["contactNo"] === false ? (
                <div style={{ fontSize: "15px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft: "40px"}}>Contact number is not valid</div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item xs={12} >
            <Grid xs={11} sm={10} md={5} lg={5}>
              <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", marginTop: "20px"}}} className="TextBoxMobileView NewPassword">
                <TextField
                  id="outlined-basic"
                  label="Password*"
                  variant="outlined"
                  placeholder="Password"
                  size="small"
                  value={newPassword}
                  name="newPassword"
                  onChange={handleChange}
                  className="visibilityButton"
                  type={showPassword ? "text" : "password"}
                  InputLabelProps={{ className: "UserNameInput" }}
                  InputProps={{
                    className: "labelTextbox",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <span style={{fontSize: "14px",color: "gray"}}>Note: Password length should be minimum 5 charachters and maximum 29 characters </span>
              </Box>
            </Grid>
            <Grid xs={11} sm={9} md={5} lg={5} className="inputBoxPassword">
              <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", marginTop: "20px"}}} className="ConfirmPassword">
                <TextField
                  id="outlined-basic"
                  label="Confirm Password*"
                  variant="outlined"
                  placeholder="Confirm Password"
                  size="small"
                  className="visibilityButton"
                  value={reEnterPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  name="reEnterPassword"
                  onChange={handleChange}
                  InputLabelProps={{ className: "UserNameInput" }}
                  InputProps={{
                    className: "labelTextbox",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >{showConfirmPassword ? (<Visibility />) : (<VisibilityOff />)}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {notErrorInFields["reEnterPassword"] === false ? (
              <div style={{ fontSize: "15px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft:"32px"}}>Password and confirm password didn’t match</div>
            ) : null}
            <Grid item container spacing={5}>
              <Grid item xs={12} className="registerButton">
                <Button className="registerButton1"
                  style={{background : !allFieldsIsValidORnot ? "grayButton" : "#264C86", fontSize:"12px", marginBottom:"20px" }}
                  disabled={!allFieldsIsValidORnot} onClick={handleAddUser}
                >
                  REGISTER
                </Button>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Added"
        ButtonLabel="Done"
        onClick={() => {
          /*history.push("/");*/
          window.location = SC_REDIRECT_URL;
        }}
      />
      {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."}
        />
      ) : null}

      <CustomSnackbar
        showSnackbar={snackBar["showSnackbar"]}
        variant={snackBar["snackbarVariant"]}
        message={snackBar["snakBarMessage"]}
        onClose={handleSnackBarClose}
        position={snackBar["position"]}
      />
    </Grid>
  );
}

//export default AddUser;
const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    addUserAPI,
  })(Registration)
);
