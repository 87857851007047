import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  Paper,
  Button,
  DialogContentText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  displayOUPolicies,
  detachOrganizationPolicies,
} from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomAlert from "../../../Components/CustomAlert";
import _ from "lodash";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function RemoveOUPolicy({
  history,
  authReducer,
  errorReducer,
  displayOUPolicies,
  detachOrganizationPolicies,
  location,
  match,
}) {
  const classes = useStyles();
  const { userName } = match.params;
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [Name, setName] = useState([]);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);

  console.log("loaction", location);

  useEffect(() => {
    displayOUPolicies(userName);
  }, []);

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      console.log("@@ in error");
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.displayOUPolicies)) {
      setName(
        authReducer.displayOUPolicies &&
          authReducer.displayOUPolicies.data &&
          authReducer.displayOUPolicies.data.user &&
          authReducer.displayOUPolicies.data.user.userName
          ? authReducer.displayOUPolicies.data.user.userName
          : "-"
      );
    }
  }, [authReducer.displayOUPolicies]);

  function createData(name, description) {
    return { name, description };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.detachOrganizationPolicies)) {
      setLoaders({
        showLoader: false,
      });
      setShowPopUp(true);
      authReducer.detachOrganizationPolicies = {};
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [authReducer.detachOrganizationPolicies]);

  const handleDetachUser = () => {
    var selectUser = selectedUser;
    if (selectUser.length) {
      let payload = {
        loggedInUser: JSON.parse(localStorage.getItem("loggedInEmail")),
        name: selectedUser,
        ouName: location.state.demo,
      };
      detachOrganizationPolicies(payload);
      setLoaders({
        showLoader: true,
        loadingText: "Detaching...",
      });
    } else {
      setAlertMsg({
        msg: "It can't be delete",
        type: "error",
      });
      setShowAlert(true);
    }
  };

  const _handlePolicyClick = (e, value = null) => {
    setSelectedUser(value);
    setAttachButtonDisable(false);
  };

  const _getUsers = () => {
    console.log("@@ authList policies get", authReducer.displayOUPolicies);
    if (authReducer.displayOUPolicies != null) {
      var theUserList = authReducer.displayOUPolicies.data.permissions;
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name != null &&
            theUserList[i].name !== ""
              ? theUserList[i].name
              : "-";
          let u_description =
            theUserList[i].hasOwnProperty("description") &&
            theUserList[i].description != null &&
            theUserList[i].description !== ""
              ? theUserList[i].description
              : "-";
          rows.push(createData(u_name, u_description));
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(users);
    setSearchResult(users);
  };

  return (
    <Fragment>
      <div
        className={"nav-route-container"}
        style={{ overflow: "hidden", position: "fixed" }}
      >
        <TopHeader />
        {showAlert && showAlert === true ? (
          <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
        ) : (
          ""
        )}
        <Grid item container xs={12}>
          <Grid item xs={2}>
            <SideMenu />
          </Grid>
          <Grid item xs={10} overflowY="scroll" className="outerDiv">
            <div
              style={{
                margin: "10px",
                backgroundColor: "#FFFFFF",
                height: "calc(100vh - 80px)",
                overflowX: "hidden",
                overflowY: "scroll",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "auto",
              }}
            >
              <div
                style={{
                  height: "50px",
                  paddingLeft: "10px",
                  alignContent: "center",
                  display: "flex",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <DialogContentText
                  style={{
                    fontSize: "20px",
                    color: "#008FBD",
                    alignContent: "center",
                    marginTop: "10px",
                    fontWeight: "600",
                    fontFamily: "Nunito, sans-serif",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => history.goBack()}
                >
                  {"Organizational Unit"}
                </DialogContentText>
                <DialogContentText
                  style={{
                    fontSize: "20px",
                    color: "#008FBD",
                    alignContent: "center",
                    marginTop: "10px",
                    fontWeight: "600",
                    fontFamily: "Nunito, sans-serif",
                  }}
                >
                  &nbsp;{"> Remove Policies"}
                </DialogContentText>
              </div>
              <div className="horizontal-line"></div>
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginLeft: "8px", marginTop: "5px" }}
                >
                  <input
                    style={{
                      height: "30px",
                      width: "300px",
                      fontSize: "18px",
                      marginRight: "10px",
                    }}
                    type="text"
                    placeholder="Search policy..."
                    onChange={(event) => {
                      console.log("event", event);
                      searchUser(event.target.value);
                    }}
                  />
                  <div
                    style={{
                      cursor: attachButtonDisable ? "not-allowed" : "pointer",
                    }}
                  >
                    <Button
                      style={{ width: "100px" }}
                      disabled={attachButtonDisable}
                      onClick={handleDetachUser}
                    >
                      {"Remove"}
                    </Button>
                  </div>
                </Grid>
                {_getUsers().length != 0 ? (
                  <div
                    className="container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="row"
                      style={{ marginTop: "10px", padding: "10px" }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell
                                align="left"
                                className="tableBorderRightH headerCell"
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tableBorderRightH headerCell"
                              >
                                Description
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(searchResult.length
                              ? searchResult
                              : _getUsers()
                            ).map((row, i) => (
                              <TableRow
                                key={row.name + i}
                                style={{ height: "40px" }}
                                hover
                              >
                                <TableCell
                                  className="tableBorderRightH"
                                  align="left"
                                  style={{ color: "#384089", fontSize: "15px" }}
                                >
                                  <Checkbox
                                    onClick={(event) => {
                                      _handlePolicyClick(event, row.name);
                                    }}
                                    role="checkbox"
                                    classes={{
                                      root: classes.CheckBoxroot,
                                      checked: classes.checked,
                                    }}
                                    // checked={selectedUsers.includes(i)}
                                    checked={
                                      selectedUser && selectedUser === row.name
                                        ? true
                                        : false
                                    }
                                  />
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #E5E5E5",
                                    padding: "10px",
                                    fontSize: "15px",
                                  }}
                                  align="left"
                                >
                                  {row.description}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                ) : (
                  <Grid
                    container
                    style={{
                      alignItems: "center",
                      display: "flex",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginTop: "20px",
                      backgroundColor: "white",
                      border: "1.5px solid #5A5757",
                      padding: "10px",
                      flexDirection: "column",
                      color: "#5A5757",
                    }}
                  >
                    No policy attached
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <DialogBox
          Open={showPopUp}
          onClose={() => setShowPopUp(false)}
          DialogTitle="Policy Successfully Detached"
          ButtonLabel="Done"
          onClick={() => {
            setShowPopUp(false)
                  history.goBack()
                  setTimeout(()=>{
                    window.location.reload(false);
                  },100)
          }}
        />
      </div>
      {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."
          }
        />
      ) : null}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    displayOUPolicies,
    detachOrganizationPolicies,
  })(RemoveOUPolicy)
);
