import { InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import './customPasswordField.css';

const PasswordInput = (props) => {

  const [passwordIsMasked, setPasswordMask] = useState(true);

  const togglePasswordMask = () => {
    setPasswordMask(!passwordIsMasked);
  }

  return (
    <div>
      <TextField
        className='passwordField'
        type={passwordIsMasked ? 'password' : 'text'}
        {...props}
        variant="filled"
        fullWidth
        InputLabelProps={{ className: 'input-label' }}
        classes={{ root: 'form-field' }}
        inputProps={{ maxLength: props.charLength }}
        // maxLength={charLength}
        // minLength={charLength}
        placeholder={props.placeholder ? props.placeholder : ''}
        variant={props.variant}
        required={props.required}
        autoComplete = {props.autoComplete}
        InputProps={{
          // className: 'input-field',
          maxLength: props.charLength, minLength:props.minLength,
          className: 'inputAdornment',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordMask}
              >
                {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          disableUnderline: true
        }}
      />
      <div style={{ display: props.isvalidorinvalid ? 'inline-block' : 'none', color: "#ff0000" }}
        className={props.isvalidorinvalid === 'isValid' ? "" : "invalid-feedback"}
      >
        {props.isvalidorinvalidmessage}
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};


export default PasswordInput;