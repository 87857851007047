import React, { useState, useEffect } from "react";
import {
  Grid, Button, Paper, IconButton, Slide
} from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import SideMenu from "../../../Components/SideMenu";
import {
  updatePolicies,
  getOuAttachablePermissions,
  policyDetail,
  policyUpdate,
} from "../../../actions/Users/authenticate";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from '@mui/material/Chip';
import { makeStyles } from "@material-ui/core/styles";
import { createKeyValuesPairs, returnFunctionResourceAndSubResourceDiff } from '../../../utils/functionResourceAndSubresourceDiff'
import { Close as CloseIcon, Add as AddIcon, Done as DoneIcon, LaptopWindows, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import ConfirmDialogBox from '../../../Components/ConfirmationDialogBox'
import AddOrUpdateServiceFunction from './addOrUpdate'
import { primaryColor, caseStatusColorRed, pureWhite } from '../../../constants/Color'
import * as validateData from "../../../utils/validation"
const scroll = Scroll.animateScroll;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
  heading: {
    fontWeight: "500 !important",
    fontSize: "15px !important",
    color: "#FFF !important",
    paddingTop: "6px"
  },
  primaryButton: {
    color: `${pureWhite} !important`,
    background: `${primaryColor} !important`,
    textTransform: "none !important",
  },
}));

function UpdatePolicy({
  history,
  authReducer,
  errorReducer,
  getOuAttachablePermissions,
  updatePolicies,
  policyDetail,
  location,
}) {
  const [name, setName] = useState("");
  const [options, setOptions] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [option, setOption] = useState([]);
  const [rootServices, setRootServices] = useState([
    {
      service: "",
      servicefunctions: [
        {
          function: "",
          resources: [{ name: "", "sub_resources": [] }],
        },
      ],
    },
  ]);
  const [description, setDescription] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [notErrorInFields, setNotErrorInFields] = useState({ policyName: true, description: true });
  const [notErrorInFieldsMsg, setNotErrorInFieldsMsg] = useState({ policyName: "", description: "Description length should be between 10 to 200 chars." });
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState([]);
  const [policyData, setPolicyData] = useState(null);
  const classes = useStyles();
  let [selectedData, setSelectedData] = useState(null);
  let [deleteConfirm, setDeleteConfirm] = useState(false);
  let [addOrUpdate, setAddOrUpdate] = useState(false);
  let [clickAction, setClickAction] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    policyDetail(location.state.demo1, location.state.demo, false);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.policyDetail)) {
      setOption(authReducer.policyDetail.data);
    }
  });

  useEffect(() => {
    let policyDetailData = authReducer && authReducer.policyDetail && authReducer.policyDetail.data ? authReducer.policyDetail.data : null
    let optionsData = authReducer && authReducer.ouAttachablePermissions && authReducer.ouAttachablePermissions.data ? authReducer.ouAttachablePermissions.data : null
    setOptions(optionsData && optionsData.services ? optionsData.services : []);
    setResourceOptions(optionsData && optionsData.resources ? optionsData.resources : []);
    setName(policyDetailData && policyDetailData.name ? policyDetailData.name : "");
    setDescription(policyDetailData && policyDetailData.description ? policyDetailData.description : "");
    setAllFieldsIsValidORnot(true);
    setChecked( (policyDetailData && policyDetailData.hasOwnProperty('is_common')) ? policyDetailData['is_common'] : checked )
    setIsTemplate( (policyDetailData && policyDetailData.hasOwnProperty('isTemplate')) ? policyDetailData['isTemplate'] : isTemplate )
    let tempPolicyData = policyDetailData && policyDetailData.policy ? policyDetailData.policy : [];
    let policyJSONData = []
    for (let serviceItr of tempPolicyData) {
      for (let functionItr of serviceItr['servicefunctions']) {
        if (functionItr['resources'] && functionItr['resources'].length != 0) {
          let allResources = [], allSubResources = []
          for (let resourceItr of functionItr['resources']) {
            allResources.push(resourceItr)
            if (resourceItr['sub_resources'] && resourceItr['sub_resources'].length != 0) {
              for (let subReItr of resourceItr['sub_resources']) {
                allSubResources.push(subReItr)
              }
            }
          }

          policyJSONData.push({
            service_name: serviceItr.name ? serviceItr.name : '',
            function_name: functionItr.name ? functionItr.name : '',
            resources: functionItr['resources'] ? functionItr['resources'] : [],
            allResources: allResources,
            allSubResources: allSubResources
          })
        }
        else {
          policyJSONData.push({
            service_name: serviceItr.name ? serviceItr.name : '',
            function_name: functionItr.name ? functionItr.name : '',
            resources: [],
            allResources: [],
            allSubResources: []
          })
        }
      }
    }
    setPolicyData(policyJSONData);
    setRootServices(tempPolicyData);
    setLoaders({ showLoader: false, loadingText: "" });
    if (policyDetailData && policyDetailData.policy && policyDetailData.policy.length != 0) {
      let temppolicy = JSON.stringify(tempPolicyData)
      temppolicy = JSON.parse(temppolicy)
      setCurrentPolicy(temppolicy)
    }
  }, [authReducer.policyDetail, authReducer.ouAttachablePermissions]);

  useEffect(() => {
    getOuAttachablePermissions(location.state.demo1);
  }, []);

  useEffect(() => {
  }, [authReducer.ouAttachablePermissions]);


  useEffect(() => {
    if (!_.isEmpty(authReducer.updatePolicies)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.updatePolicies = {};
      setDeleteConfirm(false)
      setSelectedData(null)
      setAddOrUpdate(false)
    } else {
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [authReducer.updatePolicies]);

  useEffect(() => {
    if (errorReducer && errorReducer.description && errorReducer.description !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const refreshPage = () => {
    window.location.reload(false);
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    if (name === "policyName") {
      let result = validateData.validateName(value)
      if("true"===result){
        notErrorInFields["policyName"] = true;
        notErrorInFieldsMsg['policyName'] = ""
      }
      else{
        notErrorInFields["policyName"] = false;
        notErrorInFieldsMsg['policyName'] = result
      }
      setName(value);
      setNotErrorInFields(notErrorInFields);
      setNotErrorInFieldsMsg(notErrorInFieldsMsg)
      valueIsVlidOrNot(notErrorInFields["policyName"]);
    }
    if (name === "description") {
      const length = value.length;
      if (length < 10) {
        notErrorInFields["description"] = false;
      } else {
        notErrorInFields["description"] = true;
      }
      setDescription(value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(notErrorInFields["description"]);
    }
  };

  const fnUpdateAPI = (dataForAction, actiontype, loadingTextMsg, constRootServices) => {
    if (_.isEmpty(description)) {
      setAlertMsg({ msg: "Policy description can not be empty", type: "error" });
      setShowAlert(true);
    } else {
      let operationType = null
      if (actiontype === "delete" && dataForAction) {
        operationType = "delete"
        let dataForDelete = [{
          service: dataForAction['service_name'],
          servicefunctions: [{
            function: dataForAction['function_name'],
            resources: (dataForAction['resources'] && dataForAction['resources'].length != 0) ? dataForAction['resources'] : []
          }]
        }]
        dataForAction = dataForDelete
      }
      else if (((actiontype === "addAction")) && dataForAction) {
        operationType = "add"
      }
      else if ((actiontype === "updateAction") && dataForAction && constRootServices) {
        operationType = "update"
      }
      if(dataForAction){
        for(let iterationOfService of dataForAction){
          for(let iterationOfServiceFunctions of iterationOfService['servicefunctions']){
            let tempResource = []
            for(let i=0; i<iterationOfServiceFunctions['resources'].length; i++){
              if(iterationOfServiceFunctions['resources'][i] && iterationOfServiceFunctions['resources'][i]['name'] && ""!==iterationOfServiceFunctions['resources'][i]['name'] && iterationOfServiceFunctions['resources'][i]['type'] && ""!==iterationOfServiceFunctions['resources'][i]['type']){
                tempResource.push(iterationOfServiceFunctions['resources'][i])
              }
            }
            iterationOfServiceFunctions['resources'] = tempResource
          }
        }
      }
      let payload = {
        name: location.state.demo,
        newName: name,
        description: description,
        policy: dataForAction,
        ouName: location.state.demo1,
        is_common: checked,
        isTemplate: isTemplate,
        operationType: operationType
      };
      updatePolicies(payload);
      if(payload.name !== payload.newName ){
        setClickAction(null)
      }
      setLoaders({ showLoader: true, loadingText: loadingTextMsg ? loadingTextMsg : "Updating Policy..." });
    }
  }

  const handleUpdate = () => {
    scroll.scrollToTop();
    if (_.isEmpty(description)) {
      setAlertMsg({ msg: "Please Enter All the Mandatory Fields", type: "error" });
      setShowAlert(true);
    } else {
      scroll.scrollToTop();
      let payload = {
        name: location.state.demo,
        newName: name,
        description: description,
        policy: [],
        ouName: location.state.demo1,
        is_common: checked,
        isTemplate: isTemplate
      };
      updatePolicies(payload);
      setLoaders({ showLoader: true, loadingText: "Updating the Policy" });
      setRootServices(rootServices);
    }
  };

  const _getServiceOptions = (service) => {
    if (service) {
      let serviceObj = options.find((el) => el.name === service);
      if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
        return serviceObj.servicefunctions.map((el) => {
          return { name: el.name };
        });
      }
    }
    return [];
  };

  const handleClick = (event) => {
    setChecked(event.target.checked);
  }

  const handleTemplateClick = (event) => {
    setIsTemplate(event.target.checked);
  }

  const _getResourceOptions = (service, functionName) => {
    if (service && functionName) {
      let existingResources = [];
      let serviceObj = options.find((el) => el.name === service);
      if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
        let serviceFunctionObj = serviceObj.servicefunctions.find((el) => el.name === functionName);
        if (serviceFunctionObj && serviceFunctionObj.resources && Array.isArray(serviceFunctionObj.resources)) {
          existingResources = serviceFunctionObj.resources.map((el) => {
            el['label'] = el.name;
            return el
          });
        }
      }

      return resourceOptions.filter(ele => {
        ele['label'] = ele.name;
        let item = existingResources.find(item => item.name == ele.name);
        return !item;
      });
    }
    return [];
  };

  const onEdit = (e, data, index) => {
    let tempSelectedData = [
      {
        service: data["service_name"],
        servicefunctions: [
          {
            function: data["function_name"],
            resources: data['resources'],
          },
        ],
      },
    ]
    setClickAction("updateAction")
    setSelectedData(tempSelectedData)
    setAddOrUpdate(true)
  }

  const onDelete = (e, data, index) => {
    setClickAction("delete")
    setDeleteConfirm(true)
    setSelectedData(data)
  }

  useEffect(() => {
  }, [deleteConfirm, selectedData])

  const closeDeleteDialog = () => {
    setDeleteConfirm(false)
    setSelectedData(null)
    setAddOrUpdate(false)
    setClickAction(null)
  }

  const confirmDeleteSelectedData = () => {
    fnUpdateAPI(selectedData, "delete", "Permission is being removed Please wait...")
  }

  const addNewServiceFunction = () => {
    setClickAction("addAction")
    setAddOrUpdate(true)
  }

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} href="/dashboard/OU/user-account">
                  Organization Units
                </Link>
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
                  {location?.state?.demo1}
                </Link>
                <Typography color="text.primary"><span>{'Policies'}</span></Typography>
                <Typography color="text.primary"><span>{location?.state?.demo}</span></Typography>
                <Typography color="text.primary"> <span>{'Update'}</span> </Typography>
              </Breadcrumbs>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "10px" }}>
              <div
                style={{
                  marginBottom: "8px",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  className="add-user-button"
                  onClick={handleUpdate}
                  disabled={!allFieldsIsValidORnot}
                >
                  {"Update"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#008fbd",
                    marginLeft: "10px",
                    width: "100px",
                  }}
                  className="delete-user-button"
                  onClick={() => {
                    history.goBack()
                    setTimeout(() => {
                      refreshPage()
                    }, 100);
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
              <div
                className="innerDiv"
                style={{ marginTop: "10px" }}
              >
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <CustomTypography
                      size={16}
                      weight="600"
                      color="#4B4C63"
                      content="Policy Name"
                      lineHeight={17.14}
                    />
                    <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                      <CustomTextField
                        label=""
                        name="policyName"
                        value={name}
                        onChange={handleChange}
                        minLength={3}
                        maxLength={60}
                        charLength={60}
                        isValidOrInvalid={
                          notErrorInFields["policyName"] ? "" : "isInvalid"
                        }
                        isValidOrInvalidMessage={notErrorInFieldsMsg['policyName']}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTypography
                      size={16}
                      weight="600"
                      color="#4B4C63"
                      content="Description"
                      lineHeight={17.14}
                    />
                    <Grid style={{ marginTop: "10px" }}>
                      <CustomTextField
                        label=""
                        name="description"
                        value={description}
                        onChange={handleChange}
                        minLength={10}
                        maxLength={200}
                        charLength={200}
                        isValidOrInvalid={
                          notErrorInFields["description"] ? "" : "isInvalid"
                        }
                        isValidOrInvalidMessage={notErrorInFieldsMsg["description"]}
                      />
                    </Grid>
                  </Grid>
                  {'K4Mobility' === location.state.demo1 ?
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <Checkbox
                        checked={checked} style={{ display: "inline-block" }} onClick={handleClick} inputProps={{ 'aria-label': 'controlled' }} />
                      <CustomTypography
                        styles={{ display: "inline-block" }}
                        size={16}
                        weight="600"
                        color="#4B4C63"
                        content="Mark as global policy"
                        lineHeight={17.14}
                      />
                    </Grid> : null
                  }
                  {'K4Mobility' === location.state.demo1 ?
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <Checkbox
                        checked={isTemplate} style={{ display: "inline-block" }} onClick={handleTemplateClick} inputProps={{ 'aria-label': 'controlled' }} />
                      <CustomTypography
                        styles={{ display: "inline-block" }}
                        size={16}
                        weight="600"
                        color="#4B4C63"
                        content="Mark as template"
                        lineHeight={17.14}
                      />
                    </Grid> : null
                  }
                </Grid>
              </div>


              <div className="container" style={{ backgroundColor: "white" }}>
                <div style={{ textAlign: "right", marginBottom: "-15px", marginTop: "25px" }}>
                  <Button
                    disabled={false}
                    size="small"
                    className={classes.primaryButton}
                    title={"Click to add new service function"}
                    style={{ height: "33px !important", cursor: "pointer" }}
                    onClick={(e) => { addNewServiceFunction() }}
                  >
                    <AddIcon /> Add New Permission
                  </Button>
                </div>

                {policyData && policyData.length!=0 && authReducer.loading === false ? (
                  <div
                    className="row"
                    style={{ marginTop: "25px", padding: "0px" }}
                  >
                    <TableContainer
                      component={Paper}
                      style={{ width: "100%" }}
                    >
                      <Table aria-label="simple table" size="small">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow style={{ height: "40px" }}>
                            <TableCell className="tableBorderRightH headerCell">
                              Service Name
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableBorderRightH headerCell"
                            >
                              Function Name
                            </TableCell>
                            <TableCell
                              className="tableBorderRightH headerCell"
                              align="left"
                            >
                              Resources
                            </TableCell>
                            <TableCell
                              align="center"
                              className="tableBorderRightH headerCell"
                            >
                              Sub Resources
                            </TableCell>
                            <TableCell
                              align="center"
                              className="tableBorderRightH headerCell"
                            >
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {policyData.map((row, i) => (
                            <TableRow
                              key={row.service_name + row.function_name + i}
                              style={{ height: "40px" }}
                              hover
                            >
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                                style={{ color: "#384089" }}
                              >
                                <span style={{ paddingLeft: "1px" }}> {row.service_name} </span>
                              </TableCell>

                              <TableCell className="tableBorderRightH">{row.function_name}</TableCell>

                              <TableCell className="tableBorderRightH">
                                {row.allResources.map((resourceData, reschipIndex) => (<Chip key={row.service_name + row.function_name + i + resourceData.name + reschipIndex} label={resourceData.name} variant="outlined" style={{ margin: "2px" }} />))}
                              </TableCell>

                              <TableCell className="tableBorderRightH">
                                {row.allSubResources.map((subresource, chipIndex) => (<Chip key={row.service_name + row.function_name + i + subresource.name + chipIndex} label={subresource.name} variant="outlined" style={{ margin: "2px" }} />))}
                              </TableCell>

                              <TableCell className="tableBorderRightH" align="center">
                                <IconButton style={{ padding: "0px" }}
                                  aria-label="Delete service function"
                                  onClick={(e) => { onDelete(e, row, i) }}
                                >
                                  <DeleteIcon
                                    style={{ color: caseStatusColorRed, cursor: "pointer" }}
                                  />
                                </IconButton>
                                &nbsp;
                                <IconButton style={{ padding: "0px" }}
                                  aria-label="Update service function"
                                  onClick={(e) => { onEdit(e, row, i) }}
                                >
                                  <EditIcon
                                    style={{ color: primaryColor, cursor: "pointer" }}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ) 
                : 
                policyData && 0===policyData.length && authReducer.loading === false ?
                  <div className="notExistData">
                    No permission exists in this policy.
                  </div>
                  :
                  (
                    <CustomLoader
                      showLoader={true}
                      loadingText={
                        loaders["loadingText"] ? loaders["loadingText"] : "Fetching policy details please wait..."
                      }
                    />
                  )}
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Policy Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false)
          if (clickAction) {
            setClickAction(null)
            setTimeout(() => {
              refreshPage()
            }, 100)
          }
          else {
            history.goBack()
            setTimeout(() => {
              refreshPage()
            }, 100)
          }
        }}
      />

      {addOrUpdate === true ?
        <AddOrUpdateServiceFunction
          clickAction={clickAction}
          addOrUpdate={addOrUpdate}
          closeDialog={closeDeleteDialog}
          callUpdateApi={fnUpdateAPI}
          dialogTitle={(clickAction === "addAction" ? 'Add new ' : 'Update ') + 'permission'}
          buttonName={(clickAction === "addAction" ? 'Add Permission' : 'Update Permission')}
          rootServicesData={selectedData}
          options={options}
          resourceOptions={resourceOptions}
          _getServiceOptions={_getServiceOptions}
          _getResourceOptions={_getResourceOptions}
        />
        :
        null
      }

      {deleteConfirm === true ?
        <ConfirmDialogBox
          deleteConfirm={deleteConfirm}
          closeDialog={closeDeleteDialog}
          confirmDeleteSelectedData={confirmDeleteSelectedData}
          dialogTitle={'Delete permission'}
          dialogContentText={'Are you sure you want to delete the selected service, function, resource, and sub-resources?'}
        />
        :
        null
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    updatePolicies,
    getOuAttachablePermissions,
    policyDetail,
    policyUpdate,
  })(UpdatePolicy)
);
