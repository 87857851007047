import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Button,
  Menu,
  MenuItem,
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import CustomTextField from "../../../../Components/CustomTextField";
import Scroll from "react-scroll";
import CustomTypography from "../../../../Components/CustomTypography";
import CustomButton from "../../../../Components/CustomButton";
import TopHeader from "../../../../Components/TopHeader";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../../Components/CustomAlert";
import DialogBox from "../../../../Components/DialogBox";
import _ from "lodash";
import "../OuUser.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Close as CloseIcon } from "@material-ui/icons/";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { GroupCreate, GroupUpdate, groupsOrganization, groupsDetails, groupDelete } from "../../../../actions/Users/authenticate";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Fragment } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scroll = Scroll.animateScroll;

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function OuGroups({
  history,
  authReducer,
  errorReducer,
  location,
  GroupCreate,
  GroupUpdate,
  groupsOrganization,
  groupsDetails,
  groupDelete
}) {
  const classes = useStyles();
  const [GroupName, setGroupName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPop, setShowPop] = useState(false);
  const [GroupPop, setGroupPop] = useState(false);
  const [showUp, setShowUp] = useState(false);
  const open = Boolean(anchorEl);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [GroupClick, setGroupClick] = useState("");
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [selectGroups, setSelectGroups] = useState("default");
  const [gpName, setGpName] = useState("");
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [notErrorInFields, setNotErrorInFields] = useState({ GroupName: true, gpName: true });
  const [groupLength, setGroupLength] = useState("");
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [helperTextList, setHelperTextList] = useState({ GroupName: "Name is invalid", gpName: "Name is invalid" });
  const [showDeleteGroupAlert, setShowDeleteGroupAlert] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    groupsOrganization(location.state.demo);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.GroupCreate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowUp(true);
    }
  }, [authReducer.GroupCreate]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.groupsOrganization)) {
      let groups = _getGroupUsers();
      setGroupLength(groups.length);
      setGroups(groups);
    }
    authReducer.groupsOrganization = {};
  });

  useEffect(() => {
    if (!_.isEmpty(authReducer.GroupUpdate)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPop(true);
    }
    authReducer.GroupUpdate = {};
  }, [authReducer.GroupUpdate]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.groupDelete)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setGroupPop(true);
    }
    authReducer.groupDelete = {};
  }, [authReducer.groupDelete]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.groupsDetails)) {
      setGpName(
        authReducer.groupsDetails &&
          authReducer.groupsDetails.data &&
          authReducer.groupsDetails.data.groupDetails &&
          authReducer.groupsDetails.data.groupDetails.name
          ? authReducer.groupsDetails.data.groupDetails.name
          : "-"
      );
    }
  }, [authReducer.groupsDetails]);

  const confirmGroupDelete = () => {
    setShowDeleteGroupAlert(!showDeleteGroupAlert);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeGroupUpdate = () => {
    setShowPop(false)
    refreshPage();
  }

  const closeGroupDelete = () => {
    setGroupPop(false)
    refreshPage();
  }

  const closeGroupCreate = () => {
    setShowUp(false)
    refreshPage();
  }
  function refreshPage() {
    groupsOrganization(location.state.demo);
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "GroupName") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["GroupName"] = re.test(event.target.value);
      setGroupName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
    }
    if (_.isEmpty(GroupName)) {
      setAllFieldsIsValidORnot(false);
    }
  };

  const handleTheChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "gpName") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["gpName"] = re.test(event.target.value);
      setGpName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
    }
  };

  const handleGroupCreate = () => {
    scroll.scrollToTop();
    let payload = { name: GroupName, ouName: location.state.demo };
    GroupCreate(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Creating group please wait...",
    });
  };

  const _handleGroupsClick = (e, value = null) => {
    setGroupClick(value);
    setAttachButtonDisable(false);
  };

  const handleGroupUpdate = () => {
    scroll.scrollToTop();
    let payload = {
      name: GroupClick,
      newName: gpName,
      ouName: location.state.demo
    };
    GroupUpdate(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Updating group please wait...",
    });
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };


  const handleDefault = () => {
    setSelectGroups("default");
    setShowUp(false);
    setShowPop(false);
    setGroupPop(false);
  };

  const handleCreate = () => {
    setSelectGroups("Create");
  };

  const handleUpdate = () => {
    setSelectGroups("Update");
    groupsDetails(GroupClick, location.state.demo);
    setLoaders({ loadingText: "Fetching Details of group.." });
    setAnchorEl(null);
  };

  function creationGroup(name, userCount, policyCount, createdAt, updatedAt) {
    return { name, userCount, policyCount, createdAt, updatedAt };
  }

  const _getGroupUsers = () => {
    var rows = [];
    if (authReducer.groupsOrganization != null) {
      var theUserLis = authReducer.groupsOrganization.data;
      if (theUserLis && theUserLis.length > 0) {
        for (let i = 0; i < theUserLis.length; i++) {
          let u_name =
            theUserLis[i].hasOwnProperty("name") &&
              theUserLis[i].name + theUserLis[i].name != null &&
              theUserLis[i].name + theUserLis[i].name !== ""
              ? theUserLis[i].name
                ? theUserLis[i].name
                : ""
              : "-";
          let u_userCount =
            theUserLis[i].hasOwnProperty("userCount") &&
              theUserLis[i].userCount != null &&
              theUserLis[i].userCount !== ""
              ? theUserLis[i].userCount
              : "0";
          let u_policyCount =
            theUserLis[i].hasOwnProperty("policyCount") &&
              theUserLis[i].policyCount != null &&
              theUserLis[i].policyCount !== ""
              ? theUserLis[i].policyCount
              : "0";
          let u_createdAt =
            theUserLis[i].hasOwnProperty("createdAt") &&
              theUserLis[i].createdAt != null &&
              theUserLis[i].createdAt !== ""
              ? moment(theUserLis[i].createdAt).format("yyyy-MM-D LTS")
              : "-";
          let u_updatedAt =
            theUserLis[i].hasOwnProperty("updatedAt") &&
              theUserLis[i].updatedAt != null &&
              theUserLis[i].updatedAt !== ""
              ? moment(theUserLis[i].updatedAt).format("yyyy-MM-D LTS")
              : "-";
          rows.push(
            creationGroup(
              u_name,
              u_userCount,
              u_policyCount,
              u_createdAt,
              u_updatedAt
            )
          );
        }
      }
    }
    return rows;
  };

  const handleConfirmGroup = () => {
    let payload = {
      names: [GroupClick],
      ouName: location.state.demo,
    };
    groupDelete(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Deleting group please wait...",
    });
    // setShowAlert(true);
  };

  return (
    <div className="add-user" style={{ width: "100%" }}>
      <Grid item container xs={12}>
        {
          alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
            <div>
              <Grid item container style={{ marginTop: "10px" }}>
                <Grid style={{ width: "120px" }}>
                  <Button className="add-user-button" onClick={handleCreate}>Create Group</Button>
                </Grid>
                <Grid style={{ marginLeft: "45px" }}>
                  <div style={{ cursor: attachButtonDisable ? "not-allowed" : "pointer" }}>
                    <Button style={{ marginLeft: "10px" }} className="delete-user-button" onClick={handleMenu} disabled={attachButtonDisable}>
                      {" Actions"}
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{
                        top: "35px",
                        width: "200px",
                        height: "500px",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleUpdate} className="Menu-item">Update Group</MenuItem>
                      <MenuItem className="Menu-item" onClick={confirmGroupDelete}>Delete Group</MenuItem>
                    </Menu>
                  </div>
                </Grid>
              </Grid>
              {selectGroups === "default" ? (
                <Fragment>
                  <div className="container" style={{ backgroundColor: "white" }}>
                    <div className="row" style={{ marginTop: "10px", paddingTop: "10px" }}>
                      <TableContainer component={Paper} style={{ width: "100%" }}>
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell align="left" className="tableBorderRightH headerCell">Name</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell">No. Users</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell">No. Policies</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell">Created At</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell">Updated At</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              groups.map((row, i) => (
                                <TableRow key={row.name + i} style={{ height: "40px" }} hover>
                                  <TableCell className="tableBorderRightH" align="left"
                                    style={{
                                      color: "#384089",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <Checkbox
                                      checked={
                                        GroupClick &&
                                          GroupClick === row.name
                                          ? true
                                          : false
                                      }
                                      onChange={(event) => {
                                        _handleGroupsClick(
                                          event,
                                          row.name
                                        );
                                      }}
                                      color="primary"
                                      classes={{
                                        root: classes.CheckBoxroot,
                                        checked: classes.checked,
                                      }}
                                    />
                                    <span
                                      onClick={() =>
                                        history.push({
                                          pathname: `/dashboard/groups/summary-groups/${row.name}`,
                                          state: {
                                            demo: row.name,
                                            demo1:
                                              location.state.demo,
                                          },
                                        })
                                      }
                                      style={{
                                        marginLeft: "8px",
                                      }}
                                    >{row.name}</span>
                                  </TableCell>
                                  <TableCell className="tableBorderRightH"
                                    style={{
                                      border: "1px solid #E5E5E5",
                                      padding: "10px",
                                      fontSize: "15px",
                                    }}
                                    align="center"
                                  >
                                    {row.userCount}
                                  </TableCell>
                                  <TableCell className="tableBorderRightH"
                                    style={{
                                      border: "1px solid #E5E5E5",
                                      padding: "10px",
                                      fontSize: "15px",
                                    }}
                                    align="center"
                                  >{row.policyCount}</TableCell>
                                  <TableCell className="tableBorderRightH"
                                    style={{
                                      border: "1px solid #E5E5E5",
                                      padding: "10px",
                                      fontSize: "15px",
                                    }}
                                    align="center"
                                  >{row.createdAt}</TableCell>
                                  <TableCell className="tableBorderRightH" align="center">{row.updatedAt}</TableCell>
                                </TableRow>
                              ))}

                            {false === authReducer.loading && 0 === groups.length ? (
                              <TableRow style={{ height: "40px" }}>
                                <TableCell align="center" className="tableBorderRightH headerCell" colSpan={5}>No group(s) found</TableCell>
                              </TableRow>
                            ) : null}

                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div>
                  {selectGroups === "Create" ? (
                    <Fragment>
                      <div className="innerDiv" style={{ marginTop: "10px" }}>
                        <CustomTypography size={18} weight="400" color="#5A5757" content="Name" lineHeight={19} />
                        <Grid style={{ marginTop: "10px" }}>
                          <CustomTextField
                            label=""
                            name="GroupName"
                            value={GroupName}
                            onChange={handleChange}
                            charLength={50}
                            isValidOrInvalid={notErrorInFields["GroupName"] ? "" : "isInvalid"}
                            isValidOrInvalidMessage={helperTextList["GroupName"]}
                          />
                        </Grid>
                        <br></br>
                        <Grid item container>
                          <Grid item xs={12} style={{ alignItems: "end", textAlign: "right" }}>
                            <Button title="Cancel" onClick={handleDefault} style={{ background: "#ffffff", color: "#384089" }}>Cancel</Button>
                            <Button title="Create" style={{ marginLeft: "10px" }} loading disabled={!allFieldsIsValidORnot} onClick={handleGroupCreate}>Create</Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="innerDiv" style={{ marginTop: "10px" }}>
                        <CustomTypography
                          size={18}
                          weight="400"
                          color="#5A5757"
                          content="Name"
                          lineHeight={19}
                        />
                        <Grid style={{ marginTop: "10px" }}>
                          <CustomTextField
                            label=""
                            name="gpName"
                            value={gpName}
                            onChange={handleTheChange}
                            charLength={50}
                            isValidOrInvalid={
                              notErrorInFields["gpName"]
                                ? ""
                                : "isInvalid"
                            }
                            isValidOrInvalidMessage={
                              helperTextList["gpName"]
                            }
                          />
                        </Grid>
                        <br></br>
                        <Grid item container>
                          <Grid item xs={12} style={{ alignItems: "end", textAlign: "right" }}>
                            <Button title="Cancel" onClick={handleDefault} style={{ background: "#ffffff", color: "#384089" }}>Cancel</Button>
                            <Button title="Create" style={{ marginLeft: "10px" }} loading disabled={!allFieldsIsValidORnot} onClick={handleGroupUpdate}>Create</Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Fragment>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Grid
              container
              style={{
                alignItems: "center",
                display: "flex",
                fontSize: "22px",
                // fontWeight: "600",
                marginTop: "15%",
                backgroundColor: "white",
                padding: "10px",
                flexDirection: "column",
                color: "rgb(245 144 144)",
              }}
            >
              You are not authorized to access this feature.
            </Grid>
          )
        }
      </Grid>

      <DialogBox Open={showUp} onClose={() => setShowUp(false)}
        DialogTitle="Group Successfully Created" ButtonLabel="Done" onClick={() => closeGroupCreate()} />
      <DialogBox Open={showPop} onClose={() => setShowPop(false)}
        DialogTitle="Group Successfully Updated" ButtonLabel="Done" onClick={() => closeGroupUpdate()} />

      <DialogBox Open={GroupPop} onClose={() => setGroupPop(false)} DialogTitle="Group Successfully Deleted"
        ButtonLabel="Done" onClick={() => closeGroupDelete()} />

      <Dialog
        open={showDeleteGroupAlert}
        TransitionComponent={Transition}
        onClose={confirmGroupDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmGroupDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              Please confirm to delete group.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmGroupDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting group."
                onClick={(e) => {
                  handleConfirmGroup();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>

      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organization details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    GroupCreate,
    GroupUpdate,
    groupsOrganization,
    groupsDetails,
    groupDelete
  })(OuGroups)
);
