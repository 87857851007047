import React, { useState, useEffect } from "react";
import {useDispatch} from 'react-redux'
import {
  Grid, Paper, Button, Menu, MenuItem, Slide, Dialog,
  DialogContent, DialogContentText, DialogTitle, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Close as CloseIcon } from "@material-ui/icons/";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import CustomAlert from "../../../../Components/CustomAlert";
import DialogBox from "../../../../Components/DialogBox";
import moment from "moment";
import _ from "lodash";
import "../OuUser.css";

import {
  displayOUPolicies,
  displayOUPermissions,
  dettachOuPermissions,
  policiesDelete
} from "../../../../actions/Users/authenticate";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {DETTACH_OU_PERMISSIONS} from "./../../../../actions/types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function OuPolicies({ history, authReducer, errorReducer, location, displayOUPolicies, policiesDelete }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [dettachOuPermissionPopUp, setDettachOuPermissionPopUp] = useState(false);
  const [showPolicyPop, setShowPolicyPop] = useState(false);
  const open = Boolean(anchorEl);
  const [showAlert, setShowAlert] = useState(false);
  const [PolicyClick, setPolicyClick] = useState("");
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [AttachButtonPolicyDisable, setAttachButtonPolicyDisable] = useState(true);
  const [ouPolicies, setOuPolicies] = useState([]);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [showDeletePoliciesAlert, setShowDeletePoliciesAlert] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    displayOUPolicies(location.state.demo);
  }, []);


  useEffect(() => {
    if (!_.isEmpty(authReducer.dettachOuPermissions)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setDettachOuPermissionPopUp(true);
      //authReducer.dettachOuPermissions = {};
      dispatch({
        type: DETTACH_OU_PERMISSIONS,
        payload: {}
      });
    }
  }, [authReducer.dettachOuPermissions]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.displayOUPolicies)) {
      setOuPolicies(authReducer.displayOUPolicies.data.permissions ? authReducer.displayOUPolicies.data.permissions : []);
    }
  }, [authReducer.displayOUPolicies]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesDelete)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowDeletePoliciesAlert(false);
      authReducer.policiesDelete = {};
      displayOUPolicies(location.state.demo);
    }
  }, [authReducer.policiesDelete]);

  const confirmPoliciesDelete = () => {
    setShowDeletePoliciesAlert(!showDeletePoliciesAlert);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleDeletePolicyClick = () => {
    handleClose();
    var selectPolicy = PolicyClick;
    if (selectPolicy.length) {
      let payload = {
        name: PolicyClick,
        ouName: location.state.demo,
        loggedInUser: JSON.parse(localStorage.getItem("loggedInEmail")),
      };
      policiesDelete(payload);
      setLoaders({ showLoader: true, loadingText: "Deleting policy please wait..." });
    } else {
      setAlertMsg({ msg: "It can't be delete", type: "error" });
      setShowAlert(true);
    }
  };

  const _handlePolicyClick = (e, value = null) => {
    setPolicyClick(value);
    setAttachButtonPolicyDisable(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid item container xs={12}>
        {
          alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
            <div>
              <Grid item container style={{
                marginTop: "10px"
              }}>
                <Grid
                  style={{
                    width: "120px"
                  }}
                >
                  <Button
                    className="add-user-button"
                    onClick={() =>
                      history.push({
                        pathname:
                          "/dashboard/policies/create-policy",
                        state: {
                          demo: location.state.demo,
                        },
                      })
                    }
                  >
                    {"Create Policy"}
                  </Button>
                </Grid>
                <Grid style={{ marginLeft: "40px" }}>
                  <div
                    style={{
                      cursor: AttachButtonPolicyDisable
                        ? "not-allowed"
                        : "pointer"
                    }}
                  >
                    <Button
                      style={{
                        marginLeft: "10px",
                      }}
                      className="delete-user-button"
                      onClick={handleMenu}
                      disabled={AttachButtonPolicyDisable}
                    >
                      {" Actions"}
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{
                        top: "35px",
                        width: "200px",
                        height: "500px",
                        justifyContent: "center",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          history.push({
                            pathname:
                              "/dashboard/policies/update-policy",
                            state: {
                              demo: PolicyClick,
                              demo1: location.state.demo,
                            },
                          });
                        }}
                        className="Menu-item"
                      >
                        Update Policy
                      </MenuItem>
                      <MenuItem
                        className="Menu-item"
                        onClick={confirmPoliciesDelete}
                      >Delete Policy
                      </MenuItem>
                    </Menu>
                  </div>
                </Grid>
              </Grid>
              <div>
                <Grid>
                  <TableContainer component={Paper} style={{ width: "100%", marginTop: "10px" }}>
                    <Table aria-label="simple table" size="small">
                      <TableHead style={{ background: "#ecf5ff" }}>
                        <TableRow style={{ height: "40px" }}>
                          <TableCell align="left" className="tableBorderRightH headerCell">Name</TableCell>
                          <TableCell align="left" className="tableBorderRightH headerCell">isGlobal</TableCell>
                          <TableCell align="left" className="tableBorderRightH headerCell">isTemplate</TableCell>
                          <TableCell align="left" className="tableBorderRightH headerCell">Description</TableCell>
                          <TableCell align="center" className="tableBorderRightH headerCell">Created At</TableCell>
                          <TableCell align="center" className="tableBorderRightH headerCell">Updated At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ouPolicies.map((row, i) => (
                          <TableRow key={row.name + i} style={{ height: "40px" }} hover>
                            <TableCell className="tableBorderRightH" align="left" style={{ color: "#384089" }}>
                              <Checkbox
                                onClick={(event) => {
                                  _handlePolicyClick(
                                    event,
                                    row.name
                                  );
                                }}
                                color="primary"
                                classes={{
                                  root: classes.CheckBoxroot,
                                  checked:
                                    classes.checked,
                                }}
                                checked={PolicyClick && PolicyClick === row.name ? true : false}
                              />
                              <span
                                onClick={() =>
                                  history.push({
                                    pathname:
                                      "/dashboard/policies/summary",
                                    state: {
                                      demo: row.name,
                                      demo1: location.state.demo,
                                    },
                                  })
                                }
                                style={{
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                {row.name}
                              </span>
                            </TableCell>
                            <TableCell className="tableBorderRightH" align="left">{JSON.stringify(row.is_common).toUpperCase()}</TableCell>
                            <TableCell className="tableBorderRightH" align="left">{JSON.stringify(row.isTemplate ? true : false).toUpperCase()}</TableCell>
                            <TableCell className="tableBorderRightH" align="left">{row.description}</TableCell>
                            <TableCell className="tableBorderRightH" align="center">{moment(row.createdAt).format("yyyy-MM-D LTS")}</TableCell>
                            <TableCell className="tableBorderRightH" align="center">{row.updatedAt != null ? moment(row.updatedAt).format("yyyy-MM-D LTS") : "-"}</TableCell>
                          </TableRow>
                        ))}

                        {false === authReducer.loading && 0 === ouPolicies.length ? (
                          <TableRow>
                            <TableCell className="tableBorderRightH" align="center" style={{ color: "#384089" }} colSpan={6}>No policies found!</TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </div>
          ) : (
            <Grid
              container
              style={{
                alignItems: "center",
                display: "flex",
                fontSize: "22px",
                // fontWeight: "600",
                marginTop: "15%",
                backgroundColor: "white",
                padding: "10px",
                flexDirection: "column",
                color: "rgb(245 144 144)",
              }}
            >
              You are not authorized to access this feature.
            </Grid>
          )
        }
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Detached"
        ButtonLabel="Done"
        onClick={refreshPage}
      />

      <DialogBox
        Open={showPolicyPop}
        onClose={() => setShowPolicyPop(false)}
        DialogTitle="Policy Successfully Delete"
        ButtonLabel="Done"
        onClick={refreshPage}
      />

      <Dialog
        open={showDeletePoliciesAlert}
        TransitionComponent={Transition}
        onClose={confirmPoliciesDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmPoliciesDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              Please confirm to delete policy.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmPoliciesDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting group."
                onClick={(e) => {
                  handleDeletePolicyClick();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>

      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organization details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    displayOUPolicies,
    displayOUPermissions,
    dettachOuPermissions,
    policiesDelete
  })(OuPolicies)
);
