import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  Paper,
  Button,
  DialogContentText,
} from "@material-ui/core";
// import "./AttachPolicyUser.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  groupsPolicyGet,
  detachGroupPolicy,
} from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomAlert from "../../../Components/CustomAlert";
import _ from "lodash";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function RemovePermissionGroup({
  //   history,
  detachGroupPolicy,
  authReducer,
  errorReducer,
  groupsPolicyGet,
  location,
  match,
}) {
  const classes = useStyles();
  let history = useHistory();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [name, setName] = useState([]);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);

  console.log("loactiondsfwef", location);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    groupsPolicyGet(location.state.demo, location.state.demo1);
  }, []);

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      console.log("@@ in error");
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [errorReducer]);

  function creationData(name, description) {
    return { name, description };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.detachGroupPolicy)) {
      setLoaders({
        showLoader: false,
      });
      setShowPopUp(true);
      authReducer.detachGroupPolicy = {};
    } else {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
    }
  }, [authReducer.detachGroupPolicy]);

  const handleDetachPolicy = () => {
    if (
      authReducer.groupsPolicyGet &&
      authReducer.groupsPolicyGet.data &&
      authReducer.groupsPolicyGet.data.permissions != null &&
      authReducer.groupsPolicyGet.data.permissions.length > 0
    ) {
      if (selectedUsers.length > 0) {
        var thePolicyList = [...authReducer.groupsPolicyGet.data.permissions];
        let payload = {
          name: location.state.demo,
          ou: location.state.demo1,
          policies: [],
        };
        console.log("payload --->>>", payload);
        for (let i = 0; i < thePolicyList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (thePolicyList[i].hasOwnProperty("name")) {
              console.log("the userlist", thePolicyList[i].name);
              payload.policies.push(thePolicyList[i].name);
            }
          }
        }
        detachGroupPolicy(payload);
        setLoaders({
          showLoader: true,
          loadingText: "Detaching...",
        });
        console.log("add user list", selectedUsers);
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  const _handlePolicyClick = (index) => {
    let indexList = [...selectedUsers];
    console.log("user click", indexList);
    if (
      authReducer.groupsPolicyGet.data.permissions != null &&
      authReducer.groupsPolicyGet.data.permissions.length > 0
    ) {
      var thePolicy = [...authReducer.groupsPolicyGet.data.permissions];
      for (let i = 0; i < thePolicy.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
            console.log("selected policies", updateSelectedUsers);
            setAttachButtonDisable(false);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
            console.log("selected policies", updateSelectedUsers);
            setAttachButtonDisable(false);
          }
        }
      }
    } else {
      return [];
    }
  };

  const getUse = () => {
    console.log("@@ authList", authReducer.groupsPolicyGet);
    console.log("@@authList2", authReducer.groupsPolicyGet);
    if (authReducer.groupsPolicyGet != null) {
      var theUserList =
        authReducer.groupsPolicyGet &&
        authReducer.groupsPolicyGet.data &&
        authReducer.groupsPolicyGet.data.permissions
          ? authReducer.groupsPolicyGet.data.permissions
          : "-";
      console.log("@@authList3", theUserList);
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name != null &&
            theUserList[i].name !== ""
              ? theUserList[i].name
              : "-";
          let u_description =
            theUserList[i].hasOwnProperty("description") &&
            theUserList[i].description != null &&
            theUserList[i].description !== ""
              ? theUserList[i].description
              : "-";
          rows.push(creationData(u_name, u_description));
          console.log("Getting all users' details ---------->", rows);
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <Fragment>
      <div
        className={"nav-route-container"}
        style={{ overflow: "hidden", position: "fixed" }}
      >
        <TopHeader />
        {showAlert && showAlert === true ? (
          <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
        ) : (
          ""
        )}
        <Grid item container xs={12}>
          <Grid item xs={2}>
            <SideMenu />
          </Grid>
          <Grid item xs={10} overflowY="scroll" className="outerDiv">
            <div
              style={{
                margin: "10px",
                backgroundColor: "#FFFFFF",
                height: "calc(100vh - 80px)",
                overflowX: "hidden",
                overflowY: "scroll",
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "auto",
              }}
            >
              <div
                style={{
                  height: "50px",
                  paddingLeft: "10px",
                  alignContent: "center",
                  display: "flex",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <DialogContentText
                  style={{
                    fontSize: "20px",
                    color: "#008FBD",
                    alignContent: "center",
                    marginTop: "10px",
                    fontWeight: "600",
                    fontFamily: "Nunito, sans-serif",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => history.goBack()}
                >
                  {"Group"}
                </DialogContentText>
                <DialogContentText
                  style={{
                    fontSize: "20px",
                    color: "#008FBD",
                    alignContent: "center",
                    marginTop: "10px",
                    fontWeight: "600",
                    fontFamily: "Nunito, sans-serif",
                  }}
                >
                  &nbsp;{" > " + location.state.demo + " > Detach Policies"}
                </DialogContentText>
              </div>
              <div className="horizontal-line"></div>
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginLeft: "8px", marginTop: "5px" }}
                >
                  <div
                    style={{
                      cursor: attachButtonDisable ? "not-allowed" : "pointer",
                    }}
                  >
                    <Button
                      style={{ width: "100px" }}
                      disabled={attachButtonDisable}
                      onClick={handleDetachPolicy}
                    >
                      {"Remove"}
                    </Button>
                  </div>
                </Grid>
                {getUse().length != 0 && authReducer.loading === false ? (
                  <div
                    className="container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="row"
                      style={{ marginTop: "10px", padding: "10px" }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell
                                align="left"
                                className="tableBorderRightH headerCell"
                              >
                                Name
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tableBorderRightH headerCell"
                              >
                                Description
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(searchResult.length
                              ? searchResult
                              : getUse()
                            ).map((row, i) => (
                              <TableRow
                                key={row.name + i}
                                style={{ height: "40px" }}
                                hover
                              >
                                <TableCell
                                  className="tableBorderRightH"
                                  align="left"
                                  style={{ color: "#384089", fontSize: "15px" }}
                                >
                                  <Checkbox
                                    onClick={() => {
                                      _handlePolicyClick(i);
                                    }}
                                    role="checkbox"
                                    classes={{
                                      root: classes.CheckBoxroot,
                                      checked: classes.checked,
                                    }}
                                    checked={selectedUsers.includes(i)}
                                  />
                                  {row.name}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #E5E5E5",
                                    padding: "10px",
                                    fontSize: "15px",
                                  }}
                                  align="left"
                                >
                                  {row.description}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                ) : (
                  <Grid
                    container
                    style={{
                      alignItems: "center",
                      display: "flex",
                      fontSize: "22px",
                      fontWeight: "600",
                      marginTop: "15%",
                      backgroundColor: "white",
                      padding: "10px",
                      flexDirection: "column",
                      color: "#5A5757",
                    }}
                  >
                    No Policy Found
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
        <DialogBox
          Open={showPopUp}
          onClose={() => setShowPopUp(false)}
          DialogTitle="Policy Successfully Detached"
          ButtonLabel="Done"
          onClick={() => {
            setShowPopUp(false);
            history.goBack();
            setTimeout(() => {
              refreshPage();
            }, 100);
          }}
        />
        {authReducer.loading ? (
          <CustomLoader
            showLoader={true}
            loadingText={
              loaders["loadingText"]
                ? loaders["loadingText"]
                : "Fetching policies please wait..."
            }
          />
        ) : null}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    groupsPolicyGet,
    detachGroupPolicy,
  })(RemovePermissionGroup)
);
