import React, { useState, useEffect } from "react";
import "./forgotPassword.css";
import DialogBox from "../../Components/DialogBox";
import _ from "lodash";
import {
  Grid,
  Paper,
  Input,
  Typography as MyTypography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import CustomAlert from "../../Components/CustomAlert";
import { isValidEmail } from "../../utils/validation";
import { Typography } from "../../Components/wrappers/Wrappers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import KonnectLogo from "../../assets/images/K4-logo.png";
import { forgotPassword } from "../../actions/Users/authenticate";
import CustomSnackbar from "../../Components/snackbar/CustomSnackbar";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { returnVersion } from "../../utils/validation";

function ForgotPassword({
  authReducer,
  errorReducer,
  history,
  forgotPassword,
}) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [releaseNo, setReleaseNo] = useState("");
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.forgotPassword)) {
      setFullLoader(false);
      setShowPopUp(true);
      authReducer.forgotPassword = {};
    }
  }, [authReducer.forgotPassword]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setFullLoader(false);
      setError({ message: errorReducer.description, status: true });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = () => {
    if (_.isEmpty(email) ) {
      setError({ message: "Please Enter All the Mandatory Fields", status: true });
    } else if(!isValidEmail(email)) {
      setError({ message: "Please Enter Valid Email Address", status: true });
    }
    else {
      let payload = {
        userName: email,
      };
      forgotPassword(payload);
      setFullLoader(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
     {showErrorMsg ? <CustomAlert type="error" msg={errorMsg} /> : ""}
      <Paper className={classes.cardRoot}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={KonnectLogo} />
          <Typography
            variant="h5"
            className={classes.logoCaption}
            style={{
              fontWeight: 600,
              lineHeight: "33px",
              color: "#384089",
              marginLeft: "118px",
              width: "313px",
            }}
          >
            Simple. Reliable. Powerful.
          </Typography>
          <Typography className={classes.copyright}>
            {`© 2021 K4 Mobility Inc-All Rights Reserved. | IAM Version ${
              releaseNo ? releaseNo : returnVersion()
            }
                        (Official Build)`}
          </Typography>
        </div>
        <div className={classes.inputRootContainer}>
          {/* <Typography
            className={classes.loginTitle}
            style={{ marginTop: "160px" }}
          >
            Password Assistance
          </Typography> */}
          {/* <Typography
            className={classes.usernameHeading}
            style={{ fontSize: "13px", marginBottom: "10px" }}
          >
            Enter the email address associated with your account
          </Typography> */}
          <Typography
            className={classes.usernameHeading}
            style={{ fontSize: "20px", marginBottom: "10px", marginTop:"202px" }}
          >
            Email Id
          </Typography>
          <Input
            inputProps={{ "aria-label": "userName", className:"login-input-border" }}
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter the email associated with your account"
          />
          <Typography
            className={classes.tiltHeading}
            style={{ 
              // justifyContent: "center",
              display: "flex", }}
          >
            Password Assistance
          </Typography>
          <div
            className={classes.submitBtnWrapper}
            // style={{ marginTop: "50px" }}
          >
            <Button
              style={{ width: "120px", fontSize: "20px", marginTop:"30px" }}
              color="primary"
              onClick={handleForgotPassword}
              disabled={email.length === 0}
              title="Forget Password"
            >
              Continue
            </Button>
            {/* {isLoading && (
              <CircularProgress size={26} className={classes.loginLoader} />
            )} */}
          </div>
        </div>
      </Paper>
      {error ? (
        <CustomSnackbar
          variant={error.variant ? error.variant : "error"}
          message={error.message}
          open={error.status}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
      {fullLoader && <CustomLoader showLoader={fullLoader} />}
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Email Sent Successfully"
        DialogBody="Please follow the instructions sent to your registered account"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.push("/");
        }}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    forgotPassword,
  })(ForgotPassword)
);
