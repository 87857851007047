import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import "./customButton.css";

export default function CustomButton(props) {
  return (
    <Fragment>
      {props.ButtonType === "outlined" ? (
        <div className={`${props.ButtonType}-button`} >
          <Button
          type={props.type}
            href={props.href}
            target={props.target}
            download={props.download}
            className={`${props.ButtonType}-button-container`}
            disableRipple
            onClick={props.type==="submit" ? ()=>{} : props.onClick}
            disabled={props.Disabled}
          >
            {props.ButtonLabel}
          </Button>
        </div>
      ) : (
        <div className="filled-button">
          <Button
            type={props.type}
            className="filled-button-container"
            onClick={props.type==="submit" ? ()=>{} : props.onClick}
            disabled={props.Disabled}
          >
            {props.ButtonLabel}
          </Button>
        </div>
      )}
    </Fragment>
  );
}

