import React from "react";
import "./SideMenu.css";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  ORGANIZATIONAL_UNITS_ID,
  DASHBOARD_ID,
  SERVICES_ID,
  USERS_ID,
  RESOURCES_ID,
  POLICIES_ID,
  GROUPS_ID,
  USER_ACCOUNTS,
  SETTINGS,
  SERVICE_CONTROL_POLICIES,
} from "../../constants/Constants";
import { DASHBOARD_MENU_ID } from "../../actions/types";

const menuData = [
  { name: "DASHBOARD", id: DASHBOARD_ID, subItems: [], expandId: 101 },
  // {
  //   name: "ORGANIZATIONAL UNITS",
  //   id: ORGANIZATIONAL_UNITS_ID,
  //   expandId: 102,
  //   subItems: [
  //     { name: "USER ACCOUNTS", id:USER_ACCOUNTS,  expandId: 21 },
  //     { name: "SETTINGS", id: SETTINGS, expandId: 22 },
  //     { name: "SERVICE CONTROL POLICIES", id:SERVICE_CONTROL_POLICIES, expandId: 23 },
  //   ],
  // },
  // {
  //   name: "ORGANIZATIONAL UNITS",
  //   id: ORGANIZATIONAL_UNITS_ID,
  //   subItems: [],
  //   expandId: 102,
  // },
  { name: "SERVICES", id: SERVICES_ID, subItems: [], expandId: 103 },
  { name: "USERS", id: USERS_ID, subItems: [], expandId: 104 },
  // { name: "GROUPS", id: GROUPS_ID, subItems: [], expandId: 105 },
  { name: "RESOURCES", id: RESOURCES_ID, subItems: [], expandId: 106 },
  // { name: "POLICIES", id: POLICIES_ID, subItems: [], expandId: 107 },

  // { name: "DASHBOARD", id: DASHBOARD_ID, subItems: [], expandId: 101 },
  // // {
  // //   name: "ORGANIZATIONAL UNITS",
  // //   id: ORGANIZATIONAL_UNITS_ID,
  // //   expandId: 102,
  // //   subItems: [
  // //     { name: "USER ACCOUNTS", id:USER_ACCOUNTS,  expandId: 21 },
  // //     { name: "SETTINGS", id: SETTINGS, expandId: 22 },
  // //     { name: "SERVICE CONTROL POLICIES", id:SERVICE_CONTROL_POLICIES, expandId: 23 },
  // //   ],
  // // },
  // {
  //   name: "ORGANIZATIONAL UNITS",
  //   id: ORGANIZATIONAL_UNITS_ID,
  //   subItems: [],
  //   expandId: 102,
  // },
  // { name: "USERS", id: USERS_ID, subItems: [], expandId: 104 },
  // { name: "GROUPS", id: GROUPS_ID, subItems: [], expandId: 105 },
  // { name: "SERVICES", id: SERVICES_ID, subItems: [], expandId: 103 },
  // { name: "RESOURCES", id: RESOURCES_ID, subItems: [], expandId: 106 },
  // { name: "POLICIES", id: POLICIES_ID, subItems: [], expandId: 107 },

];

const useStyles = withStyles({
  outerGrid: { backgroundColor: "#ffffff" },
  // leftText: { padding: "10px" },
});

class SideMenu extends React.Component {
  constructor(props) {   
    super(props);

    this.state = {
      selectedOptionIndex: -1,
      selectedMenuId: localStorage.getItem("tabSel") ? localStorage.getItem("tabSel") : 1,
      selectedMenuExpandId: -1,
    };
  }

  componentDidMount() {
  }
  
  toggleMenu = (id) => {
    if (this.state.selectedMenuExpandId == id) {
      this.setState({ selectedMenuExpandId: -1 });
    } else {
      this.setState({ selectedMenuExpandId: id });
    }
  };

  _checkData = () => {
    console.log("## data ", this.props.authReducer.dashboardData);
  };

  _onSideMenuClicked = (item) => {
    this.props.changeMenuId(item.id);
    localStorage.setItem("tabSel", item.id)
    if (item.id === DASHBOARD_ID) {
      this.props.history.push('/dashboard');
    }
    if (item.id === USERS_ID) {
      this.props.history.push('/dashboard/users');
    }
    // if (item.id === POLICIES_ID) {
    //   this.props.history.push('/dashboard/policies');
    // }
    if (item.id === ORGANIZATIONAL_UNITS_ID) {
      this.props.history.push('/dashboard/OU/user-account');
    }
    // if (item.id === GROUPS_ID) {
    //   this.props.history.push('/dashboard/groups');
    // }
    if (item.id === SERVICES_ID) {
      this.props.history.push('/dashboard/services');
    }
    if (item.id === RESOURCES_ID) {
      this.props.history.push('/dashboard/resources');
    }
  };

  render() {
    return (
      <div style={{}}>
        <div className="root">
          <Grid container>
            <Grid item xs={12} sm={2} style={{ backgroundColor: "#ffffff" }}>
              <div className={"nav-container"}>
                <div style={{ padding: "10px" }}>
                  <Typography className={"navigation-top-text"}>
                    {"Identity & Access Management"}
                  </Typography>
                </div>

                {menuData.map((item, i) => {
                  return (
                    <div
                      style={{
                        //height: "55px",
                        backgroundColor: "#f6f6fc",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        onClick={() => {
                          // if (item.subItems.length > 0) {
                            // this.toggleMenu(item.expandId);
                          // } else {
                            this._onSideMenuClicked(item);
                          // }
                          //this.props.history.replace("/");
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #D0D0FB",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          paddingLeft: "10px",
                          justifyContent: "center",
                          cursor: "pointer",
                          borderBottom:
                            menuData.length - 1 == i
                              ? "1px solid #D0D0FB"
                              : "0px",
                        }}
                      >
                        <Typography
                          className={"navigation-main-item-text"}
                          style={{
                            wordBreak: "break-all",
                            display: "flex",
                            flex: 1,
                            color:
                              item.subItems &&
                              item.subItems.length === 0 &&
                              this.props.authReducer.selectedMenuId === item.id
                                ? "#008FBD"
                                : "#4b4c63",
                          }}
                        >
                          {item.name}
                        </Typography>
                        {/* {item.subItems.length > 0 ? (
                          <div style={{ width: "30px" }}>
                            {item.expandId ==
                              this.state.selectedMenuExpandId ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </div>
                        ) : null} */}
                      </div>

                      {item.expandId == this.state.selectedMenuExpandId
                        ? item.subItems.map((subItem, i_s) => {
                          return (
                            <div
                              onClick={() => {
                                this._onSideMenuClicked(subItem);
                              }}
                              style={{
                                display: "flex",
                                backgroundColor: "#ffffff",
                                paddingLeft: "20px",
                                cursor: "pointer",
                              }}
                            >
                              <Typography
                                className={"navigation-subitem-text"}
                                style={{
                                  paddingTop: "12px",
                                  paddingBottom: "12px",
                                  color:
                                    this.props.authReducer.selectedMenuId === subItem.id
                                      ? "#008FBD"
                                      : "#4b4c63",
                                }}
                              >
                                {subItem.name}
                              </Typography>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

// export default InviteUser;

const mapStateToProps = (state) => ({
  //props made from redux store
  errorReducer: state.errorReducer,
  authReducer: state.authReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeMenuId: (id) =>
      dispatch({
        type: DASHBOARD_MENU_ID,
        payload: id,
      }),
    //dashboardAPI: () => dashboardAPI(),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);