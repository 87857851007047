import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import { Grid, Button } from "@mui/material";
import "./ConfirmDialog.css";

export default function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const handleConfirmClick = () => {
        // close confirm dialog
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        // call back function for onlcick of YES
        confirmDialog.onConfirm();
    }

    return (
        <Dialog open={confirmDialog.isOpen} style={{background: "rgba(38, 76, 134, 0.75)"}} className="custom_confirm_dialog">
            <DialogTitle style={{background:"#264C86"}}>
                <div className="custom_confirm_dialog_title" style={{color:"#fff"}}>
                    {confirmDialog.title}
                </div>
            </DialogTitle>
            <DialogContent style={{padding:"8px, 16px", paddingTop:"20px"}}>
                <div className="custom_confirm_dialog_sub_title">
                    {confirmDialog.subTitle}
                </div>
            </DialogContent>
            <DialogActions style={{ marginLeft: "18px", marginRight: "20px", marginBottom: "5px"}}>
                <Button className="CancelRoleEditButton" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}> No </Button>
                <Button className="role-button" onClick={() => handleConfirmClick()}> Yes </Button>
            </DialogActions>
        </Dialog>
    )
}