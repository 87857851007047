const maxNameLength = 60
const minNameLength = 3

//valid email
export const isValidEmail = (email) => {
    return ((/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email))
}

export const isValidName = (name) => {
    return ( (/^[a-zA-Z0-9\s- ]+$/).test(name) )
}

export const validateName = (name) => {
    const nameRegex = /^[a-zA-Z0-9\s-\s_ ]+$/;
    const result = (nameRegex.test(name))
	if( name && name!="" && name!="false" && name!="true" && ((typeof(name) === 'string') || (name instanceof String)) ){
        return result===true? 
            name.length > maxNameLength || name.length < minNameLength ?
                "Name length should be between " + minNameLength + " to " + maxNameLength + " chars."
                :
                "true"
            : 
            "Name is not valid, can only contain char, number, - and _"
    }
    else{
        return "Name cannot be empty, null, or undefined"
    }
};

export const validateEmailAddress = (email) => {
    const nameRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = (nameRegex.test(email))
	if( email && email!="" && email!="false" && email!="true" && ((typeof(email) === 'string') || (email instanceof String)) ){
        return result===true? "true" : "Email is not valid"
    }
    else{
        return "Email cannot be empty, null, or undefined"
    }
};

export const returnVersion = () => {
    return "2.1.3"
}