import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Paper,
} from "@material-ui/core";
import "./Service.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import DialogBox from "../../Components/DialogBox";
import { serviceGet, serviceDelete } from "../../actions/Users/authenticate";
import CustomAlert from "../../Components/CustomAlert";
import _ from "lodash";
import TopHeader from "../../Components/TopHeader";
import SideMenu from "../../Components/SideMenu";
import { Close as CloseIcon } from "@material-ui/icons/";
import CustomLoader from "../../Components/Loader/CustomLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function Services({
  history,
  authReducer,
  serviceGet,
  serviceDelete,
  errorReducer,
}) {
  const classes = useStyles();
  // const [errorMsg, setErrorMsg] = useState(null);
  // const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  // const [popUp, setPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  // const [serviceClick, setServiceClick] = useState("");
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [alertMessage, setAlertMessage] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  useEffect(() => {
    serviceGet("K4Mobility");
    console.log("service get api", serviceGet);
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setAlertMessage({
        msg: errorReducer.message,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceGet)) {
      console.log("service get", authReducer.serviceGet);
      let records = authReducer.serviceGet.data;
      console.log("records", records);
      setServiceData(records);
    }
  });

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceDelete)) {
      setShowDeleteAlert(false);
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.serviceDelete = {};
    }
  }, [authReducer.serviceDelete]);

  const handleCheckboxClick = (e, value = null) => {
    setSelectedService(value);
    setAttachButtonDisable(false);
  };

  const confirmDelete = () => {
    setShowDeleteAlert(!showDeleteAlert);
  };

  const handleDeleteServiceClick = () => {
    var selectService = selectedService;
    if (selectService.length) {
      let payload = {
        name: selectedService,
        ouName: "K4Mobility",
        deletedBy: JSON.parse(localStorage.getItem("loggedInEmail")),
      };
      serviceDelete(payload);
      console.log("bhfghabfya", payload);
      setLoaders({
        showLoader: true,
        loadingText: "Deleting the service...",
      });
    } else {
      setAlertMsg({
        msg: "It can't be delete",
        type: "error",
      });
      setShowAlert(true);
    }
  };

  return (
    <div className={"nav-route-container"} style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert &&
      showAlert === true &&
      alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#008FBD",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                {"Services"}
              </Typography>
            </div>
            <div className="horizontal-line"></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Button
                    className="add-user-button"
                    onClick={() =>
                      history.push("/dashboard/services/add-service")
                    }
                  >
                    {"Add"}
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    className="add-user-button"
                    onClick={() => {
                      history.push({
                        pathname: "/dashboard/services/update-service",
                        state: { demo: selectedService },
                      });
                    }}
                    disabled={attachButtonDisable}
                  >
                    {"Update"}
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                      marginLeft: "10px",
                    }}
                    className="delete-user-button"
                    onClick={() => {
                      confirmDelete();
                    }}
                    disabled={attachButtonDisable}
                  >
                    {"Delete"}
                  </Button>
                </div>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    backgroundColor: "white",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {serviceData.length !== 0 && authReducer.loading === false ? (
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell className="tableBorderRightH headerCell">
                                Name
                              </TableCell>
                              <TableCell
                                className="tableBorderRightH headerCell"
                                align="center"
                              >
                                Description
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tableBorderRightH headerCell"
                              >
                                Created At
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tableBorderRightH headerCell"
                              >
                                Updated At
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {serviceData.map((row, i) => (
                              <TableRow
                                key={row.name + i}
                                style={{ height: "40px" }}
                                hover
                              >
                                <TableCell
                                  className="tableBorderRightH"
                                  align="left"
                                  style={{ color: "#384089" }}
                                >
                                  <Checkbox
                                    onClick={(event) => {
                                      handleCheckboxClick(event, row.name);
                                    }}
                                    role="checkbox"
                                    classes={{
                                      root: classes.CheckBoxroot,
                                      checked: classes.checked,
                                    }}
                                    checked={
                                      selectedService &&
                                      selectedService === row.name
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    onClick={() =>
                                      history.push({
                                        pathname: "/dashboard/services/view-service-summary",
                                        state: {
                                          demo: row.name,
                                          demo1: "K4Mobility",
                                        },
                                      })
                                    }
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {row.name}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="tableBorderRightH"
                                  align="left"
                                >
                                  {row.description}
                                </TableCell>
                                <TableCell
                                  className="tableBorderRightH"
                                  align="center"
                                >
                                  {moment(row.createdAt).format(
                                    "yyyy-MM-D LTS"
                                  )}
                                </TableCell>
                                {row.updatedAt !== null ? (
                                  <TableCell
                                    className="tableBorderRightH"
                                    align="right"
                                  >
                                    {moment(row.updatedAt).format(
                                      "yyyy-MM-D LTS"
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className="tableBorderRightH"
                                    align="right"
                                  >
                                    -
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : (
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "22px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No Service Found
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Service Successfully Deleted"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <Dialog
        open={showDeleteAlert}
        TransitionComponent={Transition}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px"
              }}
            >
              Please confirm to delete selected services.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  handleDeleteServiceClick();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."
          }
        />
      ) : null} */}
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching services please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    serviceGet,
    serviceDelete,
  })(Services)
);
