import React from 'react';
import { Typography } from '@material-ui/core';


export default function CustomTypography(props) {
    return (
        <Typography
            style={{
                textDecoration: `${props.textDecoration}`,
                fontSize: `${props.size}px`,
                color: `${props.color}`,
                fontWeight: `${props.weight}`,
                lineHeight: `${props.lineHeight}px`,
                fontFamily: `${props.fontFamily}`,
                ...props.styles
            }}>
            {props.content}
        </Typography>
    )
}
