import React from 'react';
import { TextField } from '@material-ui/core';
import './customTextField.css';

export default function CustomTextField({ label, isValidOrInvalid, isValidOrInvalidMessage, value, variant, id, onChange, isMultiline, rows, placeholder, disabled, notRequired, name, error, type, charLength }) {

    return (
        <div >
            <TextField
                className={
                    isValidOrInvalid ? isValidOrInvalid === 'isValid' ?
                        'form-control is-valid InputMdb form-group' : 'form-control is-invalid InputMdb form-group'
                        :
                        'form-control InputMdb form-group'
                }
                InputProps={error === true ? { className: 'invalid', disableUnderline: true } : { className: 'input-fields', disableUnderline: true }}
                InputLabelProps={{ className: 'input-label' }}
                fullWidth
                name={name}
                classes={{ root: 'form-field' }}
                value={value}
                onChange={onChange}
                label={label}
                multiline={isMultiline}
                inputProps={{ maxLength: charLength }}
                maxLength= {charLength}
                minLength= {charLength}
                rows={rows}
                type={type}
                id={id}
                placeholder={placeholder ? placeholder : ''}
                variant={variant}
                required={notRequired ? false : true}
                disabled={disabled}
            />
            <div style={{ display: isValidOrInvalid ? 'inline-block' : 'none', color: "#ff0000" }}
                className={isValidOrInvalid === 'isValid' ? "" : "invalid-feedback"}
            >
                {isValidOrInvalidMessage}
            </div>
        </div>
    )

}

