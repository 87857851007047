import React, { useState, useEffect } from "react";
import { Grid, DialogContentText, Button } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import CustomButton from "../../../Components/CustomButton";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./AttachServiceOu.css";
import SideMenu from "../../../Components/SideMenu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  serviceCreate,
  serviceFetch,
  ouDerived,
} from "../../../actions/Users/authenticate";
import { isValidName } from "../../../utils/validation";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";

const scroll = Scroll.animateScroll;

function AttachServiceOu({
  history,
  authReducer,
  errorReducer,
  ouDerived,
  serviceCreate,
  serviceFetch,
  location,
}) {
  const [Name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rootServices, setRootServices] = useState([
    {
      function: "",
      resources: [
        { objKey: "", objValue: "", isKeyValid: true, isValuValid: true },
      ],
      isFunctionValid: true,
    },
  ]);
  const [disableSave, setDisableSave] = useState(true);
  const [textFieldCount, setTextFieldCount] = useState(1);
  const [textFieldResources, setTextFieldResources] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    Name: true,
    isFunctionValid: true,
    isKeyValid: true,
    isValuValid: true,
  });
  const [organizationChildName, setOrganizationChildName] = useState("");
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [helperTextList, setHelperTextList] = useState({
    Name: "Name is invalid",
    isFunctionValid: "Function is invalid",
    isKeyValid: "Resources Key is invalid",
    isValuValid: "Resources valid is invalid",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [detailPolicy, setDetailPolicy] = useState([]);
  const [summary, setSummary] = useState([]);
  const [childName, setChildName] = useState([]);
  const [parentServiceName, setParentServiceName] = useState("");

  console.log("props", location);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceCreate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.serviceCreate = {};
    }
  }, [authReducer.serviceCreate]);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    serviceFetch(location.state.demo1, location.state.demo.toString());
  }, []);

  useEffect(() => {
    ouDerived();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.ouDerived)) {
      let records = authReducer.ouDerived.data;
      setDetailPolicy(location.state.demo1);
      setChildName(authReducer.ouDerived.data);
      if (detailPolicy) {
        if (
          records &&
          records[detailPolicy] &&
          records[detailPolicy]["children"]
        ) {
          setSummary(records[detailPolicy]["children"]);
        } else {
          console.log("recordsssss23");
        }
      }
    }
  });

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceFetch)) {
      setParentServiceName(
        authReducer.serviceFetch &&
          authReducer.serviceFetch.data &&
          authReducer.serviceFetch.data[0] &&
          authReducer.serviceFetch.data[0].name
          ? authReducer.serviceFetch.data[0].name
          : "NULL"
      );
      let tempInputList = [];
      if (
        authReducer.serviceFetch.data[0] &&
        "servicefunctions" in authReducer.serviceFetch.data[0] &&
        authReducer.serviceFetch.data[0].servicefunctions.length !== 0
      ) {
        for (
          let i = 0;
          i < authReducer.serviceFetch.data[0].servicefunctions.length;
          i++
        ) {
          let funName =
            authReducer.serviceFetch.data[0].servicefunctions[i]["function"];
          let tmpRes = [];
          for (
            let j = 0;
            j <
            authReducer.serviceFetch.data[0].servicefunctions[i].resources
              .length;
            j++
          ) {
            let objKey = "",
              objValue = "";
            let tempObjdata =
              authReducer.serviceFetch.data[0].servicefunctions[i].resources[j];
            Object.keys(tempObjdata).map((keyName) => {
              objKey = keyName;
              objValue = tempObjdata[keyName];
            });
            tmpRes.push({
              objKey: objKey,
              objValue: objValue,
              isKeyValid: true,
              isValuValid: true,
            });
          }
          tempInputList.push({
            function: funName,
            resources: tmpRes,
            isFunctionValid: true,
          });
        }
        setRootServices(tempInputList);
        setTextFieldCount(
          authReducer.serviceFetch.data[0].servicefunctions.length
            ? authReducer.serviceFetch.data[0].servicefunctions.length
            : 1
        );
      }
    }
  }, [authReducer.serviceFetch]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rootServices];
    list[index]["function"] = value;
    list[index]["isFunctionValid"] = isValidName(value);
    setRootServices(list);
    setDisableSave(isValidName(value));
    console.log("yeahh", rootServices);
  };

  const handleInputChange1 = (e, index, resIndex) => {
    const { name, value } = e.target;
    const newlist = [...rootServices];
    let obj = newlist[index];
    newlist[index]["resources"][resIndex][name] = value;
    if (name === "objKey")
      newlist[index]["resources"][resIndex]["isKeyValid"] = isValidName(value);
    else if (name === "objValue")
      newlist[index]["resources"][resIndex]["isValuValid"] = isValidName(value);
    setRootServices([...newlist]);
    setDisableSave(isValidName(value));
  };

  const handleRemoveClick = (index) => {
    const list = [...rootServices];
    list.splice(index, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]["isValid"] === false) {
        isError = 1;
        break;
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldCount(textFieldCount - 1);
  };

  const handleRemoveResources = (index, resIndex) => {
    const list = [...rootServices];
    list[index]["resources"].splice(resIndex, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i]["resources"].length; j++) {
        if (list[i]["resources"][j]["isKeyValid"] === false) {
          isError = 1;
          break;
        }
        if (list[i]["resources"][j]["isValuValid"] === false) {
          isError = 1;
          break;
        }
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldResources(textFieldResources - 1);
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "Name") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["Name"] = re.test(event.target.value);
      setName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
      setDisableSave(setName(event.target.value));
    }
    if (name === "description") {
      setDescription(event.target.value);
    }
    if (name === "organizationChildName") {
      setOrganizationChildName(event.target.value);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    let tempinputList = [];
    for (let i = 0; i < rootServices.length; i++) {
      let funName = rootServices[i]["function"];
      let tmpRes = [];
      for (let j = 0; j < rootServices[i]["resources"].length; j++) {
        let keyName = rootServices[i]["resources"][j]["objKey"];
        let keyValue = rootServices[i]["resources"][j]["objValue"];
        tmpRes.push({ [keyName]: keyValue });
      }
      tempinputList.push({
        function: funName,
        resources: tmpRes,
      });
    }
    let payload = {
      name: Name,
      description: description,
      serviceFunctions: tempinputList,
      createdBy: JSON.parse(localStorage.getItem("loggedInEmail")),
      loggedInUser: JSON.parse(localStorage.getItem("loggedInEmail")),
      parentServiceName: location.state.demo,
      ouName: organizationChildName,
      parentOUName: location.state.demo1,
    };
    console.log("functionpayload", payload);
    serviceCreate(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Adding the service...",
    });
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Organizational Unit"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;
                {" > " +
                  location.state.demo1 +
                  " > Service > " +
                  location.state.demo +
                  " > Attach service to child organization"}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "12px", width: "100%" }}>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content="Enter Service Details"
                  lineHeight={19}
                />
                <div className="horizontal-line"></div>
                <form
                  onSubmit={(e) => {
                    handleCreate(e);
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid xs={4}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Name"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <CustomTextField
                          style={{ height: "35px" }}
                          label=""
                          name="Name"
                          value={Name}
                          onChange={handleChange}
                          charLength={50}
                          isValidOrInvalid={
                            notErrorInFields["Name"] ? "" : "isInvalid"
                          }
                          isValidOrInvalidMessage={helperTextList["Name"]}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Description"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <CustomTextField
                          style={{ height: "35px" }}
                          label=""
                          name="description"
                          value={description}
                          onChange={handleChange}
                          charLength={100}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Child organization"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <Autocomplete
                          id="organizationChildName"
                          options={summary}
                          getOptionLabel={(option) => option}
                          inputValue={organizationChildName}
                          onInputChange={(event, newInputValue) => {
                            setOrganizationChildName(newInputValue);
                          }}
                          onChange={(event, value) => {
                            handleChange(event);
                          }}
                          style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Name"
                              variant="outlined"
                              required
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid style={{ background: "#F0F8FF" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: "15px", marginLeft: "10px" }}
                    >
                      <Grid xs={4}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Functions"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Resource"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {rootServices.map((obj, index) => {
                      return (
                        <div
                          className="box"
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={3} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="function"
                                value={obj["function"]}
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj.isFunctionValid ? "" : "isInvalid"
                                }
                                disabled
                                isValidOrInvalidMessage={
                                  helperTextList["isFunctionValid"]
                                }
                              />
                            </Grid>
                            <Grid>
                              <CloseOutlinedIcon
                                className={
                                  rootServices.length <= 1
                                    ? "iconNotAllow"
                                    : "iconAllow"
                                }
                                onClick={
                                  rootServices.length === 1
                                    ? () => {}
                                    : () => handleRemoveClick(index)
                                }
                              />
                            </Grid>
                            <Grid xs={7}>
                              {obj.resources.map((el, indexx) => {
                                return (
                                  <Grid
                                    item
                                    container
                                    xs={12}
                                    style={{ marginTop: "4px" }}
                                  >
                                    <Grid style={{ marginLeft: "80px" }} xs={5}>
                                      <CustomTextField
                                        onChange={(el) =>
                                          handleInputChange1(el, index, indexx)
                                        }
                                        disabled
                                        name={"objKey"}
                                        value={el.objKey}
                                        isValidOrInvalid={
                                          el.isKeyValid ? "" : "isInvalid"
                                        }
                                        isValidOrInvalidMessage={
                                          helperTextList["isKeyValid"]
                                        }
                                      />
                                    </Grid>
                                    <Grid style={{ marginLeft: "10px" }} xs={5}>
                                      <CustomTextField
                                        onChange={(el) =>
                                          handleInputChange1(el, index, indexx)
                                        }
                                        disabled
                                        name={"objValue"}
                                        value={el.objValue}
                                        isValidOrInvalid={
                                          el.isValuValid ? "" : "isInvalid"
                                        }
                                        isValidOrInvalidMessage={
                                          helperTextList["isValuValid"]
                                        }
                                      />
                                    </Grid>
                                    <Grid>
                                      <CloseOutlinedIcon
                                        className={
                                          obj.resources.length <= 1
                                            ? "iconNotAllow"
                                            : "iconAllow"
                                        }
                                        onClick={
                                          obj.resources.length === 1
                                            ? () => {}
                                            : () =>
                                                handleRemoveResources(
                                                  index,
                                                  indexx
                                                )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    <br></br>
                    <br></br>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item>
                      <CustomButton
                        ButtonType=""
                        ButtonLabel="Attach"
                        style={{ width: "300px" }}
                        // Disabled={!disableSave}
                        type="submit"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "#008fbd",
                          width: "100px",
                        }}
                        className="delete-user-button"
                        onClick={() => history.goBack()}
                      >
                        {"Cancel"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Service Successfully Attached"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching service details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    serviceCreate,
    serviceFetch,
    ouDerived,
  })(AttachServiceOu)
);
