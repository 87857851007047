
import axios from "axios";
import { API_URL, REDIRECT_URL } from '../../src/config';

const _authorizationHeaders = () => ({
  "Content-Type": "application/json",
  "Authorization": localStorage.getItem('loggedInUser') ? "Bearer " + JSON.parse(localStorage.getItem('loggedInUser')).data.id_token : '',
});

const _authorizationHeadersOut = () => ({
  "Content-Type": "application/json",
  "Authorization": ''
});

const logout = async () => {
  let logoutPayload = {
    idToken: JSON.parse(localStorage.getItem('loggedInUser')).data.id_token,
    redirectUrl: REDIRECT_URL,
  }

  try {
    const res = await axios({
      url: API_URL + "/user/logout",
      method: "POST",
      headers: Object.assign({}, _authorizationHeaders()),
      data: logoutPayload,
    });
  } catch (error) {
    console.log("logout ", error);
  }

  localStorage.setItem('loggedInEmail', '');
  localStorage.setItem('loggedInUpdateEmail', '');
  localStorage.setItem('loggedInUser', '');
  localStorage.setItem('Refresh Token', '');
  localStorage.clear();
  window.location = REDIRECT_URL;
}
const parseJwt = (token) => {
  if (token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
  return null;
};

const renewTokenIfRequired = async () => {
  console.log("renewTokenIfRequired");

  var loggedInUser = localStorage.getItem("loggedInUser");
  if (loggedInUser) {
    var id_token = JSON.parse(loggedInUser).data.id_token

    if (id_token) {
      const decodedJwt = parseJwt(id_token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        var refresh_token = JSON.parse(loggedInUser).data.refresh_token;

        var body = { refreshToken: refresh_token };
        const headers = { "Content-Type": "application/json" };
        try {
          const res = await axios({
            url: API_URL + "/token",
            method: "POST",
            headers: Object.assign({}, headers),
            data: body,
          });
          if (res && res.data && res.data.message) {
            var _loggedInUser = JSON.parse(loggedInUser);
            _loggedInUser.data = JSON.parse(res.data.message.message);
            localStorage.setItem("loggedInUser", JSON.stringify(_loggedInUser));
            return true;
          }
          console.log("Session expired, logging out.");
          await logout();
        } catch (err) {
          console.log("Session expired, logging out.");
          console.error(err);
          await logout();
        }
      }
    }
  }
  return false;
}
export const getRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  console.log("getRequest");
  console.log(headers, "{}")
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }
    const res = await axios.get(API_URL + url, {
      headers: Object.assign({}, headers), data
    });

    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const getRequestOut = async (url, data = {}, headers = _authorizationHeadersOut()) => {

  try {
    const res = await axios.get(API_URL + url, {
      // headers: Object.assign({}, headers), data
    });

    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }

    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const postRequestOut = async (url, data = {}, headers = _authorizationHeadersOut()) => {
  try {
    // var isValidToken = await renewTokenIfRequired();
    // if (isValidToken) {
    //   headers = _authorizationHeaders();
    // }

    const res = await axios({
      url: API_URL + url,
      method: "POST",
      // headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const putRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }

    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const putRequestOut = async (url, data = {}, headers = _authorizationHeadersOut()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }

    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      // headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const patchRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }

    const res = await axios({
      url: API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const deleteRequest = async (url, data = {}, headers = _authorizationHeaders()) => {
  try {
    var isValidToken = await renewTokenIfRequired();
    if (isValidToken) {
      headers = _authorizationHeaders();
    }

    const res = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
      data,
    });
    return res && res.data && res.data.result ? res.data.result : res;
  } catch (err) {

    return err && err.response && err.response.data ? err.response.data : err;
  }
};

export const api = {
  deleteRequest,
  getRequest,
  getRequestOut,
  postRequest,
  putRequest,
  patchRequest,
  postRequestOut,
  putRequestOut,
};
