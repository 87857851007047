import React, { useState, useEffect } from "react";
import { Grid, Button, DialogContentText } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./CreatePolicy.css";
import SideMenu from "../../../Components/SideMenu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  policiesCreate,
  getOuAttachablePermissions,
} from "../../../actions/Users/authenticate";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as validateData from "../../../utils/validation"

const scroll = Scroll.animateScroll;

function CreatePolicy({
  history,
  authReducer,
  errorReducer,
  policiesCreate,
  getOuAttachablePermissions,
  location,
}) {
  const [options, setOptions] = useState([]);
  const [resourceOptions, setResourceOptions] = useState([]);
  const [rootServices, setRootServices] = useState([
    {
      service: "",
      servicefunctions: [
        {
          function: "",
          resources: [{ name: "", "sub_resources": [] }],
        },
      ],
    },
  ]);
  const [rootResources, setRootResources] = useState([]);
  const [policyName, setPolicyName] = useState("");
  const [description, setDescription] = useState("");
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [inputResourceList, setInputResourceList] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    policyName: true,
    description: true,
  });
  const [notErrorInFieldsMsg, setNotErrorInFieldsMsg] = useState({ policyName: "", description: "Description length should be between 10 to 200 chars." });
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [checked, setChecked] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    getOuAttachablePermissions(location.state.demo);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.ouAttachablePermissions)) {
      setOptions(authReducer.ouAttachablePermissions.data.services);
      setResourceOptions(authReducer.ouAttachablePermissions.data.resources);
    }
  });

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesCreate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.policiesCreate = {};
    }
  }, [authReducer.policiesCreate]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleResourceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputResourceList];
    list[index] = value;
    setInputResourceList(list);
  };

  const handleAddClick = () => {
    setRootServices([
      ...rootServices,
      {
        service: "",
        servicefunctions: [
          {
            function: "",
            resources: ["---Select Resource---"],
          },
        ],
      },
    ]);
  };

  const handleResetClick = () => {
    setPolicyName("");
    setDescription("");
    for (let i = 0; i < rootServices.length; i++) {
      rootServices[i]["service"] = "";
      for (let j = 0; j < rootServices[i]["servicefunctions"].length; j++) {
        rootServices[i]["servicefunctions"][j]["resources"] = [""];
        rootServices[i]["servicefunctions"][j]["function"] = "";
      }
    }
    setRootServices(rootServices);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (idxx, idx) => {
    let listRoot = [...rootServices];
    let obj = listRoot[idx];
    let resources = obj.servicefunctions;
    resources.splice(idxx, 1);
    obj.servicefunctions = resources;
    listRoot.splice(idx, 1, obj);
    setRootServices(listRoot);
  };

  const handleRemoveClick1 = (idx) => {
    const listNew = [...rootServices];
    listNew.splice(idx, 1);
    setRootServices([...listNew]);
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    if (name === "policyName") {

      let result = validateData.validateName(value)
      if("true"===result){
        notErrorInFields["policyName"] = true;
        notErrorInFieldsMsg['policyName'] = ""
      }
      else{
        notErrorInFields["policyName"] = false;
        notErrorInFieldsMsg['policyName'] = result
      }
      setPolicyName(value);
      setNotErrorInFields(notErrorInFields);
      setNotErrorInFieldsMsg(notErrorInFieldsMsg)
      valueIsVlidOrNot(notErrorInFields["policyName"]);
    }
    if (name === "description") {
      const { value } = event.target;
      const length = value.length;
      if (length < 10) {
        notErrorInFields["description"] = false;
      } else {
        notErrorInFields["description"] = true;
      }
      setDescription(value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(notErrorInFields["description"]);
    }
    if (_.isEmpty(description) || _.isEmpty(policyName)) {
      setAllFieldsIsValidORnot(false);
    }
  };

  const handleCreate = () => {
    scroll.scrollToTop();
    let policy = [];
    for (let serviceInfo of rootServices) {
      let service = {
        service: serviceInfo["service"],
        servicefunctions: [],
      };
      let servicefunctions = service.servicefunctions;
      for (let servicefunctionInfo of serviceInfo.servicefunctions) {
        let servicefunction = {
          function: servicefunctionInfo["function"],
          resources: []
        }
        for (let resourceInfo of servicefunctionInfo.resources) {
          let r = resourceOptions.find(ele => ele.name === resourceInfo.name);
          if (r) {
            let sub_resources = [];
            if (resourceInfo.sub_resources) {
              for (let ele of resourceInfo.sub_resources) {
                sub_resources.push({ id: ele.id, name: ele.name, type: ele.type });
              }
            }
            servicefunction.resources.push({ id: r.id, name: r.name, type: r.type, sub_resources: sub_resources });
          }
        }
        servicefunctions.push(servicefunction)
      }
      policy.push(service)
    }

    let payload = {
      name: policyName,
      description: description,
      policy: policy,
      ouName: location.state.demo,
      is_common: checked,
      isTemplate: isTemplate
    };
    policiesCreate(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Creating...",
    });
  };

  const handleRemoveResources = (idx, idxx, indexx) => {
    let listResource = [...rootServices];
    listResource[idx]["servicefunctions"][idxx]["resources"].splice(indexx, 1);
    setRootServices(listResource);
  };

  const _getServiceOptions = (service) => {
    if (service) {
      let serviceObj = options.find((el) => el.name === service);
      if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
        return serviceObj.servicefunctions.map((el) => {
          return { name: el.name };
        });
      }
    }
    return [];
  };

  const handleClick = (event) => {
    setChecked(event.target.checked);
  }

  const handleTemplateClick = (event) => {
    setIsTemplate(event.target.checked);
  }

  const _getResourceOptions = (service, functionName) => {
    if (service && functionName) {
      let existingResources = [];
      let serviceObj = options.find((el) => el.name === service);
      if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
        let serviceFunctionObj = serviceObj.servicefunctions.find((el) => el.name === functionName);
        if (serviceFunctionObj && serviceFunctionObj.resources && Array.isArray(serviceFunctionObj.resources)) {
          existingResources = serviceFunctionObj.resources.map((el) => {
            return { name: el.name };
          });
        }
      }

      return resourceOptions.filter(ele => {
        let item = existingResources.find(item => item.name == ele.name);
        return !item;
      });
    }
    return [];
  };

  const _getSubResourceOptions = (service, functionName, resourceIndex) => {
    if (service && functionName && resourceIndex >= 0) {
      let serviceObj = rootServices.find((el) => el.service === service);
      if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
        let serviceFunctionObj = serviceObj.servicefunctions.find((el) => el.function === functionName);
        if (serviceFunctionObj && serviceFunctionObj.resources && Array.isArray(serviceFunctionObj.resources) && resourceIndex <= serviceFunctionObj.resources.length) {
          const selResource = serviceFunctionObj.resources[resourceIndex];
          const resource = resourceOptions.find(ele => ele.name == selResource.name);
          if (resource && resource.sub_types && Array.isArray(resource.sub_types)) {
            return resourceOptions.filter((ele) => ele.type && -1 != resource.sub_types.indexOf(ele.type));
          }
        }
      }
    }
    return [];
  };


  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} href="/dashboard/OU/user-account">
                  Organization Units
                </Link>
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
                  {location?.state?.demo}
                </Link>
                <Typography color="text.primary">Policies</Typography>
                <Typography color="text.primary">Create Policy</Typography>
              </Breadcrumbs>
              <div
                className="horizontal-line"
                style={{ marginTop: "0px" }}
              ></div>
            </Grid>
            <Grid style={{ marginTop: "15px" }}>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  style={{ width: "100px" }}
                  onClick={handleCreate}
                  disabled={!allFieldsIsValidORnot}
                >
                  {"Create"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#008fbd",
                    marginLeft: "10px",
                    width: "100px",
                  }}
                  className="delete-user-button"
                  onClick={handleResetClick}
                >
                  {"Reset"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "#008fbd",
                    marginLeft: "10px",
                    width: "100px",
                  }}
                  className="delete-user-button"
                  onClick={() => {
                    history.goBack();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 100);
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>
              <div
                className="innerDiv"
                style={{ marginTop: "10px" }}
              >
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <CustomTypography
                      size={16}
                      weight="600"
                      color="#4B4C63"
                      content="Policy Name"
                      lineHeight={17.14}
                    />
                    <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                      <CustomTextField
                        label=""
                        name="policyName"
                        value={policyName}
                        onChange={handleChange}
                        minLength={3}
                        maxLength={60}
                        charLength={60}
                        isValidOrInvalid={
                          notErrorInFields["policyName"] ? "" : "isInvalid"
                        }
                        isValidOrInvalidMessage={notErrorInFieldsMsg["policyName"]}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTypography
                      size={16}
                      weight="600"
                      color="#4B4C63"
                      content="Description"
                      lineHeight={17.14}
                    />
                    <Grid style={{ marginTop: "10px" }}>
                      <CustomTextField
                        label=""
                        name="description"
                        value={description}
                        onChange={handleChange}
                        minLength={10}
                        maxLength={200}
                        charLength={200}
                        isValidOrInvalid={
                          notErrorInFields["description"] ? "" : "isInvalid"
                        }
                        isValidOrInvalidMessage={notErrorInFieldsMsg["description"]}
                      />
                    </Grid>
                  </Grid>
                  {'K4Mobility' === location.state.demo ?
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <Checkbox
                        checked={checked} style={{ display: "inline-block" }} onClick={handleClick} inputProps={{ 'aria-label': 'controlled' }} />
                      <CustomTypography
                        styles={{ display: "inline-block" }}
                        size={16}
                        weight="600"
                        color="#4B4C63"
                        content="Mark as global policy"
                        lineHeight={17.14}
                      />
                    </Grid> : null
                  }
                  {'K4Mobility' === location.state.demo ?
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <Checkbox
                        checked={isTemplate} style={{ display: "inline-block" }} onClick={handleTemplateClick} inputProps={{ 'aria-label': 'controlled' }} />
                      <CustomTypography
                        styles={{ display: "inline-block" }}
                        size={16}
                        weight="600"
                        color="#4B4C63"
                        content="Mark as template"
                        lineHeight={17.14}
                      />
                    </Grid> : null
                  }
                </Grid>
                <Grid item container xs={12}>
                  <Grid xs={11}>
                    <Button className="add-user-button" style={{ backgroundColor: "white", color: "#008fbd", marginTop: "20px" }}
                      disabled={rootServices.length >= options.length ? true : false}
                      onClick={handleAddClick}
                    >
                      {rootServices.length >= options.length ? "no more service" : "Add Service Name"}
                    </Button>
                  </Grid>
                </Grid>
                {rootServices.map((obj, idx) => {
                  return (
                    <div className="box" key={idx}>
                      <Grid
                        item
                        conatiner
                        xs={12}
                        className="innerDiv"
                        style={{ marginTop: "10px", position: "relative" }}
                      >
                        <Grid style={{ position: "absolute", right: "10px", top: "5px" }}>
                          <CloseOutlinedIcon
                            className={
                              rootServices.length <= 1
                                ? "iconNotAllow"
                                : "iconAllow"
                            }
                            onClick={
                              rootServices.length === 1
                                ? () => { }
                                : () => handleRemoveClick1(idx)
                            }
                          />
                        </Grid>
                        <Grid>
                          <CustomTypography
                            size={16}
                            weight="600"
                            color="#4B4C63"
                            content="Service Name"
                            lineHeight={17.14}
                          />
                          <div style={{ marginTop: "10px" }}>
                            <Autocomplete
                              id="controllable-states-demo"
                              options={options}
                              getOptionLabel={(option) => option.name}
                              onSelect={(e) => {
                                let newlist = [...rootServices];
                                let obj = newlist[idx];
                                obj.service = e.target.value;
                                newlist.splice(idx, 1, obj);
                                setRootServices([...newlist]);
                                setAttachButtonDisable(false);
                              }}
                              value={{ name: obj.service }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="---Select Service Name---"
                                  variant="outlined"
                                />
                              )}
                            />
                            <Button
                              style={{
                                backgroundColor: "white",
                                color: "#008fbd"
                              }}
                              onClick={() => {
                                let newlist = [...rootServices];
                                let obj = newlist[idx];
                                obj.servicefunctions.push({
                                  function: "",
                                  resources: ["---Select Resource---"],
                                });
                                newlist.splice(idx, 1, obj);
                                setRootServices([...newlist]);
                              }}
                            >
                              Add service function
                            </Button>
                          </div>
                          <Grid style={{ background: "#F0F8FF" }}>
                            <Grid
                              item
                              container
                              xs={12}
                              style={{ marginTop: "15px", marginLeft: "10px" }}
                            >
                              <Grid xs={4}>
                                <CustomTypography
                                  size={16}
                                  weight="normal"
                                  color="#5A5757"
                                  content="Service Function"
                                  lineHeight={19}
                                  styles={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginTop: "10px",
                                  }}
                                />
                              </Grid>
                              <Grid xs={8}>
                                <CustomTypography
                                  size={16}
                                  weight="normal"
                                  color="#5A5757"
                                  content="Resource and Sub Resource"
                                  lineHeight={19}
                                  styles={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginTop: "20px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            {obj.servicefunctions.map((el, idxx) => {
                              return (
                                <div
                                  className="box"
                                  key={idxx}
                                  style={{ marginLeft: "10px", position: "relative", marginTop: "50px" }}
                                >
                                  <Grid container spacing={1}>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ marginBottom: "5px", position: "relative", paddingRight: "10px" }}
                                    >
                                      <Grid style={{ top: "-30px", position: "absolute", right: "10px" }}>
                                        <CloseOutlinedIcon
                                          className={
                                            obj.servicefunctions.length <= 1
                                              ? "iconNotAllow"
                                              : "iconAllow"
                                          }
                                          onClick={
                                            obj.servicefunctions.length === 1
                                              ? () => { }
                                              : () => handleRemoveClick(idxx, idx)
                                          }
                                        />
                                      </Grid>
                                      <Autocomplete
                                        disabled={attachButtonDisable}
                                        id="controllable-states-demo"
                                        options={_getServiceOptions(obj.service)}
                                        getOptionLabel={(option) => option.name}
                                        onSelect={(e) => {
                                          let newlist = [...rootServices];
                                          let tempobj = newlist[idx];
                                          tempobj["servicefunctions"][idxx][
                                            "function"
                                          ] = e.target.value;
                                          newlist.splice(idx, 1, tempobj);
                                          setRootServices([...newlist]);
                                          setAttachButtonDisable(false);
                                        }}
                                        value={{ name: el.function }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label="---Select Service Function---"
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid xs={8} style={{ position: "relative" }}>
                                      <Button
                                        style={{
                                          backgroundColor: "white",
                                          color: "#008fbd",
                                          position: "absolute",
                                          right: "10px",
                                          top: "-45px"
                                        }}
                                        onClick={() => {
                                          let newlist = [...rootServices];
                                          let servicefunction = newlist[idx]["servicefunctions"][idxx];
                                          servicefunction["resources"].push({ name: "", sub_resources: [] });
                                          newlist[idx]["servicefunctions"].splice(idxx, 1, servicefunction);
                                          setRootServices([...newlist]);
                                        }}
                                      >
                                        Add Resource
                                      </Button>
                                      {el.resources.map((ell, indexx) => {
                                        return (
                                          <Grid item container xs={12} style={{ "position": "relative" }}>
                                            <Grid xs={6}>
                                              <Grid style={{ position: "absolute", right: "10px" }}>
                                                <CloseOutlinedIcon
                                                  className={el.resources.length <= 1 ? "iconNotAllow" : "iconAllow"}
                                                  onClick={el.resources.length === 1 ? () => { } : () => handleRemoveResources(idx, idxx, indexx)}
                                                />
                                              </Grid>
                                              <Autocomplete
                                                style={{ marginRight: "50px" }}
                                                disabled={attachButtonDisable}
                                                id="controllable-states-demo"
                                                options={_getResourceOptions(obj.service, el.function)}
                                                getOptionLabel={(option) => option.name}
                                                onSelect={(e) => {
                                                  let newlist = [...rootServices];
                                                  let tempobj = newlist[idx]["servicefunctions"][idxx];
                                                  tempobj["resources"][indexx] = { name: e.target.value, sub_resources: [] };
                                                  newlist[idx]["servicefunctions"].splice(idxx, 1, tempobj);
                                                  setRootServices([...newlist]);
                                                  setAttachButtonDisable(false);
                                                }}
                                                value={{ name: ell.name }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select Resource"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                            <Grid xs={6}>
                                              <Autocomplete
                                                multiple
                                                options={_getSubResourceOptions(
                                                  obj.service,
                                                  el.function,
                                                  indexx
                                                )}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                  if (rootServices[idx] && rootServices[idx]["servicefunctions"][idxx]
                                                    && rootServices[idx]["servicefunctions"][idxx]["resources"][indexx]) {
                                                    rootServices[idx]["servicefunctions"][idxx]["resources"][indexx].sub_resources = newValue;
                                                  }
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Sub Types"
                                                    label="Select Sub-Resource"
                                                  />
                                                )}
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </div>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Policy Successfully Created"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    policiesCreate,
    getOuAttachablePermissions,
  })(CreatePolicy)
);