import React, { useState, useEffect } from "react";
import CustomTypography from "../../Components/CustomTypography";
import CustomPasswordField from "../../Components/CustomPasswordField";
import CustomButton from "../../Components/CustomButton";
import { ReactComponent as K4Logo } from "../../assets/images/K4Logo.svg";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAPI } from "../../actions/Users/authenticate";
import CustomAlert from "../../Components/CustomAlert";
import DialogBox from "../../Components/DialogBox";
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import "./login.css";

function Login({ history, authReducer, errorReducer, loginAPI }) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (window.location && window.location.search) {
      let idParams = window.location.search;
      let id = idParams.replace('?redirectUrl=', '').trim();
      setRedirectUrl(id);
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.loginUser)) {
      let ret = authReducer.loginUser.data
      var args = "";
      var loginUrl;
      args += "id_token=" + ret.id_token;
      args += "&access_token=" + ret.access_token;
      args += "&expires_in=" + ret.expires_in;
      args += "&refresh_token=" + ret.refresh_token;
      args += "&token_type=" + ret.token_type;
      loginUrl = redirectUrl + '#' + args;
      window.location.href = loginUrl;
      authReducer.loginUser.data = {};
    }
  }, [authReducer.loginUser]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setErrorMsg(errorReducer.message);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    console.log('do validate',event.keyCode);
    if (event.keyCode === 13) {
      console.log('do validate');
      handleLogin();
    }
  }

  const handleLogin = () => {
    if (_.isEmpty(userName) || _.isEmpty(password)) {
      setErrorMsg("Please Enter All the Mandatory Fields");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    } else {
      let payload = {
        userName: userName,
        password: password,
      };
      loginAPI(payload, redirectUrl);
    }
  };

  return (
    <div className="login-page">
      <Grid item container xs={12}>
        {showErrorMsg ? <CustomAlert type="error" msg={errorMsg} /> : ""}
        <Grid item xs={6} className="left-div">
          <Grid item className="centerized-div">
            <Grid container xs={12} spacing={2} className="centerized-div">
              <Grid item>
                <K4Logo />
              </Grid>
              <Grid item>
                <CustomTypography
                  size={45}
                  weight="700"
                  color="#2F3484"
                  content="IAM"
                  lineHeight={87.89}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="right-div"  >
          <Grid item container xs={12}>
            <Grid item container className="login-heading">
              <CustomTypography
                size={36}
                weight="600"
                color="#2F3484"
                content="Login"
                lineHeight={49.1}
              />
            </Grid>
            <Grid item container style={{ padding: "2rem" }}>
              <Grid item xs={12}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="User Name"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="Password"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPasswordField
                  value={password}
                  onChange={handleChange}
                  name="password"
                  keypress={handleKeyPress}
                />
              </Grid>
              <Grid item container xs={12} style={{ marginTop: "1rem" }}>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        style={{ color: "#008FBD" }}
                      />
                    }
                    label={
                      <CustomTypography
                        content="Remember me."
                        size={14}
                        color="#6D6D6D"
                        weight="600"
                        lineHeight={17.14}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4} style={{alignSelf: 'center' , cursor: 'pointer'}} onClick={() => history.push('/forgot-password')}>
                  <CustomTypography
                    content="Forgot your password?"
                    size={14}
                    color="#6D6D6D"
                    weight="600"
                    lineHeight={17.14}
                  />
                </Grid>

              </Grid>

              <Grid item xs={12} className="login-button">
                <CustomButton
                  ButtonType=""
                  ButtonLabel="Login"
                  onClick={handleLogin}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          Open={showPopUp}
          onClose={() => setShowPopUp(false)}
          DialogTitle="User Successfully Logged-In "
          DialogBody="Please proceed to the dashboard"
          ButtonLabel="Next"
          onClick={() => history.push("login-user")}
        />
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    loginAPI,
  })(Login)
);
