import React, { useEffect, useState } from "react";
import {
  Grid,
  Checkbox,
  Paper,
  Button,
  DialogContentText,
} from "@material-ui/core";
import "./AttachOuResource.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  notAttachedOuResources,
  attachOuResources,
} from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomAlert from "../../../Components/CustomAlert";
import _ from "lodash";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function AttachOuResource({
  attachOuResources,
  authReducer,
  errorReducer,
  notAttachedOuResources,
  location,
  match,
}) {
  const classes = useStyles();
  let history = useHistory();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedResources, updateselectedResources] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);

  useEffect(() => {
    notAttachedOuResources(location.state.demo);
  }, []);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      console.log("@@ in error");
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function createData(name, description) {
    return { name, description };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.notAttachedOuResources.data)) {
      console.log(
        "authReducer.notAttachedOuResources-> ",
        authReducer.notAttachedOuResources.data
      );
    }
  }, [authReducer.notAttachedOuResources.data]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.attachOuResources)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.attachOuResources = {};
    }
  }, [authReducer.attachOuResources]);

  const handleAttachUser = () => {
    if (
      authReducer.notAttachedOuResources.data != null &&
      authReducer.notAttachedOuResources.data.length > 0
    ) {
      if (selectedResources.length > 0) {
        var thePolicyList = [
          ...authReducer.notAttachedOuResources.data,
        ];
        let payload = {
          name: location.state.demo,
          resources: [],
        };

        for (let i = 0; i < thePolicyList.length; i++) {
          if (selectedResources.includes(i)) {
            if (thePolicyList[i].hasOwnProperty("name")) {
              payload.resources.push(thePolicyList[i].name);
            }
          }
        }
        attachOuResources(payload);
        setLoaders({ showLoader: true, loadingText: "Attaching..."});
      } else {
        alert("Please select the resources which you want to added.");
      }
    } else {
      alert("Dummy resources cannot be added.");
    }
  };

  const _handlePolicyClick = (index) => {
    let indexList = [...selectedResources];
    // console.log("user click", indexList);
    if (
      authReducer.notAttachedOuResources.data != null &&
      authReducer.notAttachedOuResources.data.length > 0
    ) {
      var thePolicy = [...authReducer.notAttachedOuResources.data];
      for (let i = 0; i < thePolicy.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateselectedResources(arr);
            // console.log("selected policies", updateselectedResources);
            setAttachButtonDisable(false);
          } else {
            indexList.push(i);
            updateselectedResources(indexList);
            // console.log("selected policies", updateselectedResources);
            setAttachButtonDisable(false);
          }
        }
      }
    } else {
      return [];
    }
  };

  const _getAllowedOuResources = () => {
    if (authReducer.notAttachedOuResources.data != null) {
      var theUserList = authReducer.notAttachedOuResources.data;
      var rows = [];
      if (theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name != null &&
            theUserList[i].name !== ""
              ? theUserList[i].name
              : "-";
          let u_description =
            theUserList[i].hasOwnProperty("description") &&
            theUserList[i].description != null &&
            theUserList[i].description !== ""
              ? theUserList[i].description
              : "-";
          rows.push(createData(u_name, u_description));
        }
        // return theUserList;
        console.log("Row123---", rows);
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <div
      className={"nav-route-container"}
      style={{ overflow: "hidden", position: "fixed" }}
    >
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10} overflowY="scroll" className="outerDiv">
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowX: "hidden",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "auto",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignContent: "center",
                display: "flex",
                backgroundColor: "#FBFBFB",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Organisation Units"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;{"> Attach Resources"}
              </DialogContentText>
            </div>
            <div className="horizontal-line"></div>
            <Grid
              container
              spacing={1}
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{ marginLeft: "8px", marginTop: "5px" }}
              >
                <div
                  style={{
                    cursor: attachButtonDisable ? "not-allowed" : "pointer",
                  }}
                >
                  <Button
                    style={{ width: "100px" }}
                    disabled={attachButtonDisable}
                    onClick={handleAttachUser}
                  >
                    {"attach"}
                  </Button>
                </div>
              </Grid>
              {_getAllowedOuResources().length != 0 ? (
                <div className="container" style={{ backgroundColor: "white" }}>
                  <div
                    className="row"
                    style={{ marginTop: "10px", padding: "10px" }}
                  >
                    <TableContainer component={Paper} style={{ width: "100%" }}>
                      <Table aria-label="simple table" size="small">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow style={{ height: "40px" }}>
                            <TableCell
                              align="left"
                              className="tableBorderRightH headerCell"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableBorderRightH headerCell"
                            >
                              Description
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {_getAllowedOuResources().map((row, i) => (
                            <TableRow key={row.name} style={{ height: "40px" }} hover>
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                                style={{ color: "#384089", fontSize: "15px" }}
                              >
                                <Checkbox
                                  onClick={() => {
                                    _handlePolicyClick(i);
                                  }}
                                  role="checkbox"
                                  classes={{
                                    root: classes.CheckBoxroot,
                                    checked: classes.checked,
                                  }}
                                  checked={selectedResources.includes(i)}
                                />
                                {row.name}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid #E5E5E5",
                                  padding: "10px",
                                  fontSize: "15px",
                                }}
                                align="left"
                              >
                                {row.description}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <div className="horizontal-line"></div>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      color: "#5A5757",
                      fontSize: "18px",
                      marginTop: "10px",
                    }}
                  >
                    There are no resources available to attach
                  </div>
                  <div
                    className="horizontal-line"
                    style={{ marginTop: "10px" }}
                  ></div>
                </div>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Policy Successfully Attached"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false)
                  history.goBack()
                  setTimeout(()=>{
                    window.location.reload(false);
                  },100)
        }}
      />
      {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Fetching user please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    attachOuResources,
    notAttachedOuResources,
  })(AttachOuResource)
);
