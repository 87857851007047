import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import Scroll from "react-scroll";
import TopHeader from "../../../Components/TopHeader";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import _ from "lodash";
import "./OuUser.css";
import SideMenu from "../../../Components/SideMenu";
import { Fragment } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import OuSummary from "./OuSummary";
import OuResources from "./OuResources";
import OuPermissions from "./OuPermissions";
import OuPolicies from "./OuPolicies";
import OuGroups from "./OuGroups";
import OuUsers from "./OuUsers";

const scroll = Scroll.animateScroll;

function OuUser({ authReducer, location }) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [selectedButton, setSelectedButton] = useState(
    localStorage.getItem("selectedTab")
      ? localStorage.getItem("selectedTab")
      : "Users"
  );

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  const handleUsers = () => {
    setSelectedButton("Users");
    localStorage.setItem("selectedTab", "Users");
  };

  const handleGroups = () => {
    setSelectedButton("Groups");
    localStorage.setItem("selectedTab", "Groups");
  };

  const handleOuPermissions = () => {
    setSelectedButton("OuPermissions");
    localStorage.setItem("selectedTab", "OuPermissions");
  };

  const handleOuResources = () => {
    setSelectedButton("OuResources");
    localStorage.setItem("selectedTab", "OuResources");
  };

  const handlePermissions = () => {
    setSelectedButton("Permissions");
    localStorage.setItem("selectedTab", "Permissions");
  };

  const handleSummary = () => {
    setSelectedButton("Summary");
    localStorage.setItem("selectedTab", "Summary");
  };

  return (
    <div className="add-user" style={{ width: "100%" }}>
      <TopHeader />
      {showAlert &&
        showAlert === true &&
        alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} href="/dashboard/OU/user-account">
                  Organization Units
                </Link>
                <Typography color="text.primary">{location?.state?.demo}</Typography>
              </Breadcrumbs>
            </Grid>
            <div
              style={{ marginLeft: "1.5px" }}
              className="horizontal-line"
            ></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <div>
                <Grid>
                  <div className="innerDiv">
                    <Grid container>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderBottom:
                            selectedButton === "Users"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                        onClick={handleUsers}
                      >
                        {"Users "}
                      </Button>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderBottom:
                            selectedButton === "Groups"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handleGroups}
                      >
                        {"Groups "}
                      </Button>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderBottom:
                            selectedButton === "OuPermissions"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handleOuPermissions}
                      >
                        {"Permissions "}
                      </Button>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderBottom:
                            selectedButton === "OuResources"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handleOuResources}
                      >
                        {"Resources "}
                      </Button>

                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderBottom:
                            selectedButton === "Permissions"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handlePermissions}
                      >
                        {"Policies "}
                      </Button>
                      <Button
                        style={{
                          marginTop: "-13px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "150px",
                          borderRadius: "0px",
                          borderBottom:
                            selectedButton === "Summary"
                              ? "2px solid #008FBD"
                              : "none",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handleSummary}
                      >
                        Summary
                      </Button>
                    </Grid>

                    {selectedButton === "Users" ? (
                      <Fragment>
                        <OuUsers></OuUsers>
                      </Fragment>
                    ) : (
                      <div>
                        {selectedButton === "Groups" ? (
                          <Fragment>
                            <OuGroups></OuGroups>
                          </Fragment>
                        ) : (
                          <div>
                            {selectedButton === "OuPermissions" ? (
                              <Fragment>
                                <OuPermissions></OuPermissions>
                              </Fragment>
                            ) : (
                              <div>
                                {selectedButton === "OuResources" ? (
                                  <Fragment>
                                    <OuResources></OuResources>
                                  </Fragment>
                                ) : (
                                  <div>
                                    {selectedButton === "Permissions" ? (
                                      <Fragment>
                                        <OuPolicies></OuPolicies>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <OuSummary></OuSummary>
                                      </Fragment>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </div>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>

      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organization details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
  })(OuUser)
);
