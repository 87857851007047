import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Button,
    Dialog, DialogContent, DialogContentText, DialogTitle, Slide, useMediaQuery
} from "@material-ui/core";
import { primaryColor, pureWhite, secondryColor } from '../../../constants/Color'
import { Close as CloseIcon } from '@material-ui/icons';
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CustomTypography from "../../../Components/CustomTypography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { values } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    CheckBoxroot: {
        fontSize: "13px",
        padding: "0px",
        color: "#384089",
        "&$checked": {
            color: "#384089",
        },
    },
    checked: {},
    rootDialog: {
        flexGrow: 1,
        padding: "5px 10px",
    },
    heading: {
        fontWeight: "500 !important",
        fontSize: "15px !important",
        color: "#FFF !important",
        paddingTop: "6px"
    },
    primaryOutlineButton: {
        background: `${primaryColor} !important`,
        color: `${pureWhite} !important`,
        textTransform: "none !important",
    },
    secondaryOutlineButton: {
        background: `transparent !important`,
        color: `${secondryColor} !important`,
        textTransform: "none !important",
        border: `1px solid ${secondryColor}`
    },
}));

export default function AddOrUpdateServiceFunction(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [attachButtonDisable, setAttachButtonDisable] = useState(true);
    let [rootServices, setRootServices] = useState(props.clickAction === "updateAction" ? props.rootServicesData : [
        {
            service: "",
            servicefunctions: [
                {
                    function: "",
                    resources: [{ name: "", type: "", "sub_resources": [] }],
                },
            ],
        },
    ]);
    const [constRootServices, setConstRootServices] = useState(props.clickAction === "updateAction" ? JSON.stringify(props.rootServicesData) : null);

    useEffect((props) => {
    }, []);

    useEffect((props) => {
        if (props && props.rootServices) {
            setRootServices(props.rootServices)
        }
    }, [rootServices]);

    const _getSubResourceOptions = (service, functionName, resourceIndex) => {
        if (service && functionName && resourceIndex >= 0) {
            let serviceObj = rootServices.find((el) => el.service === service);
            if (serviceObj && serviceObj.servicefunctions && Array.isArray(serviceObj.servicefunctions)) {
                let serviceFunctionObj = serviceObj.servicefunctions.find((el) => el.function === functionName);
                if (serviceFunctionObj && serviceFunctionObj.resources && Array.isArray(serviceFunctionObj.resources) && resourceIndex <=
                    serviceFunctionObj.resources.length) {
                    const selResource = serviceFunctionObj.resources[resourceIndex];
                    const resource = props.resourceOptions.find(ele => ele.name == selResource.name);
                    if (resource && resource.sub_types && Array.isArray(resource.sub_types)) {
                        return props.resourceOptions.filter((ele) => ele.type && -1 != resource.sub_types.indexOf(ele.type));
                    }
                }
            }
        }
        return [];
    };

    const handleRemoveResources = (idxx, idx, indexx) => {
        let listResource = [...rootServices];
        listResource[idxx]["servicefunctions"][idx]["resources"].splice(indexx, 1);
        setRootServices(listResource);
    };

    const classes = useStyles();
    return (
        <Dialog
            keepMounted
            fullScreen={fullScreen}
            open={props.addOrUpdate}
            TransitionComponent={Transition}
            onClose={props.closeDialog}
            fullWidth={true}
            maxWidth={props.boxWidth ? props.boxWidth : "lg"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            position={'top'}
        >
            <DialogContent style={{ padding: "0px", maxHeight: "800px", overflowY: 'scroll' }}>
                <DialogTitle className={classes.rootDialog} style={{ background: primaryColor }} id="alert-dialog-slide-title">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className={classes.heading}> {props.dialogTitle} </div>
                        <div onClick={props.closeDialog} title="Close" style={{ marginTop: "3px", cursor: "pointer", color: pureWhite }}>
                            <CloseIcon
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContentText id="AddorUpdatePolicy-Permission-alert-dialog-slide-description">
                    <div style={{ margin: "20px" }}>
                        {rootServices.map((obj, idx) => {
                            return (
                                <div key={"serviceDropdown-controllable-states-demo" + idx + "s"} className="box">
                                    <Grid
                                        item
                                        conatiner
                                        xs={12}
                                        className="innerDiv"
                                        style={{ marginTop: "10px", position: "relative" }}
                                    >
                                        <Grid>
                                            <CustomTypography
                                                size={16}
                                                weight="600"
                                                color="#4B4C63"
                                                content="Service Name"
                                                lineHeight={17.14}
                                            />
                                            <div style={{ marginTop: "10px", width: "100%" }}>
                                                <Autocomplete
                                                    id={"serviceDropdown-controllable-states-demo" + idx}
                                                    options={props.options}
                                                    getOptionLabel={(option) => option.name}
                                                    onSelect={(e) => {
                                                        let newlist = [...rootServices];
                                                        let tempServiceFunction = newlist[idx];
                                                        tempServiceFunction.service = e.target.value;
                                                        newlist.splice(idx, 1, tempServiceFunction);
                                                        setRootServices([...newlist]);
                                                    }}
                                                    value={{ name: obj.service }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Service Name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <Grid style={{ background: "#F0F8FF" }}>
                                                <Grid
                                                    item
                                                    container
                                                    spacing={1}
                                                    xs={12} sm={12} md={12} lg={12}
                                                    style={{ marginTop: "15px", marginLeft: "10px" }}
                                                >
                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                        <CustomTypography
                                                            size={16}
                                                            weight="normal"
                                                            color="#5A5757"
                                                            content="Service Function"
                                                            lineHeight={19}
                                                            styles={{
                                                                fontSize: "16px",
                                                                fontWeight: "600",
                                                                marginTop: "10px",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={7} sm={7} md={7} lg={7} style={{ marginLeft: "88px" }}>
                                                        <CustomTypography
                                                            size={16}
                                                            weight="normal"
                                                            color="#5A5757"
                                                            content="Resource and Sub Resource"
                                                            lineHeight={19}
                                                            styles={{
                                                                fontSize: "16px",
                                                                fontWeight: "600",
                                                                marginTop: "20px",
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {obj.servicefunctions.map((el, idxx) => {
                                                    return (
                                                        <div
                                                            key={obj.service + "-serviceFunctionDropDown-controllable-states-demo" + el.function + idxx + "sf"}
                                                            className="box"
                                                            style={{ marginLeft: "10px", position: "relative", marginTop: "50px" }}
                                                        >
                                                            <Grid item xs={12} sm={12} md={12} lg={12} container spacing={1}>
                                                                <Grid
                                                                    item
                                                                    xs={4} sm={4} md={4} lg={4}
                                                                    style={{ marginBottom: "5px", position: "relative", paddingRight: "10px" }}
                                                                >
                                                                    <Autocomplete
                                                                        id={obj.service + "-serviceFunctionDropDown-controllable-states-demo" + el.function + idxx}
                                                                        options={props._getServiceOptions(obj.service)}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onSelect={(e) => {
                                                                            console.log("get service function---", values)
                                                                            let newlist = [...rootServices];
                                                                            let obj = newlist[idx]['servicefunctions'][idxx];
                                                                            obj.function = e.target.value;
                                                                            newlist[idx]["servicefunctions"].splice(idx, 1, obj);
                                                                            setRootServices([...newlist]);
                                                                        }}
                                                                        value={{ name: el.function }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                label="Select Service Function"
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid style={{ marginTop: "-50px", marginLeft: "95px" }} item xs={7} sm={7} md={7} lg={7}>
                                                                    <div style={{ marginBottom: "25px" }}>
                                                                        <Button
                                                                            className={classes.primaryOutlineButton}
                                                                            style={{
                                                                                position: "absolute"
                                                                            }}
                                                                            variant="contained"
                                                                            size="small"
                                                                            color={primaryColor}
                                                                            onClick={() => {
                                                                                let newlist = [...rootServices];
                                                                                let servicefunction = newlist[idx]["servicefunctions"][idxx];
                                                                                servicefunction["resources"].push({ name: "", type: "", sub_resources: [] });
                                                                                newlist[idx]["servicefunctions"].splice(idxx, 1, servicefunction);
                                                                                setRootServices([...newlist]);
                                                                            }}
                                                                        >
                                                                            Add Resource
                                                                        </Button>
                                                                    </div>
                                                                    <br />
                                                                    {el.resources.map((ell, indexx) => {
                                                                        return (
                                                                            <Grid
                                                                                key={obj.service + "-serviceFunctionDropDown-controllable-states-demo" + el.function + idxx + ell.name + indexx + "sfr"}
                                                                                item container xs={12} sm={12} md={12} lg={12}
                                                                                style={{
                                                                                    "position": "relative",
                                                                                    marginBottom: "20px"
                                                                                }}
                                                                            >
                                                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                    <Grid style={{
                                                                                        position: "absolute", right: "10px",
                                                                                        top: "-26px"
                                                                                    }}>
                                                                                        <CloseOutlinedIcon
                                                                                            className={el.resources.length <= 1 ? "iconNotAllow" : "iconAllow"}
                                                                                            onClick={el.resources.length === 1 ? () => { } : () => handleRemoveResources(idx, idxx, indexx)}
                                                                                        />
                                                                                    </Grid>

                                                                                    <Autocomplete
                                                                                        style={{ marginRight: "50px" }}
                                                                                        id={obj.service + "-serviceFunctionDropDown-controllable-states-demo" + el.function + idxx + ell.name + indexx}
                                                                                        options={props._getResourceOptions(obj.service, el.function)}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        onChange={(e, obj) => {
                                                                                            console.log("resource obj---", obj)
                                                                                            let name = obj && obj.name ? obj.name : "", type = obj && obj.type ? obj.type : ""
                                                                                            if (rootServices[idx] && rootServices[idx]["servicefunctions"][idxx] && rootServices[idx]["servicefunctions"][idxx]["resources"][indexx]) {
                                                                                                rootServices[idx]["servicefunctions"][idxx]["resources"][indexx] = {
                                                                                                    name: name, type: type, sub_resources: []
                                                                                                };
                                                                                                let newlist = [...rootServices];
                                                                                                setRootServices([...newlist]);
                                                                                                setAttachButtonDisable(false);
                                                                                            }
                                                                                        }}
                                                                                        value={ell}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="outlined"
                                                                                                label="Select Resource"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                    <Autocomplete
                                                                                        id={obj.service + "-serviceFunctionDropDown-controllable-states-demo" + el.function + idxx + ell.name + indexx + "subResources"}
                                                                                        multiple
                                                                                        value={
                                                                                            (rootServices[idx] && rootServices[idx]["servicefunctions"][idxx]
                                                                                                && rootServices[idx]["servicefunctions"][idxx]["resources"][indexx]) ? rootServices[idx]["servicefunctions"][idxx]["resources"][indexx].sub_resources : []
                                                                                        }
                                                                                        options={_getSubResourceOptions(
                                                                                            obj.service,
                                                                                            el.function,
                                                                                            indexx
                                                                                        )}
                                                                                        getOptionLabel={(option) => option.name ? option.name : ""}
                                                                                        onChange={(event, newValue) => {
                                                                                            if (rootServices[idx] && rootServices[idx]["servicefunctions"][idxx]
                                                                                                && rootServices[idx]["servicefunctions"][idxx]["resources"][indexx]) {
                                                                                                rootServices[idx]["servicefunctions"][idxx]["resources"][indexx].sub_resources = newValue;
                                                                                                let newlist = [...rootServices];
                                                                                                setRootServices([...newlist]);
                                                                                            }
                                                                                        }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                variant="outlined"
                                                                                                placeholder="Sub Types"
                                                                                                label="Select Sub-Resource"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                    </div>
                </DialogContentText>

                <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "16px" }}>
                    <div style={{marginRight:"15px"}}>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.secondaryOutlineButton}
                            title="Click Cancel to go back"
                            onClick={props.closeDialog}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            color={primaryColor}
                            onClick={(e) => {
                                props.callUpdateApi(rootServices,
                                    props.clickAction,
                                    props.clickAction === "addAction" ? "Permission is being added Please wait..." : "Permission is being updated Please wait...",
                                    constRootServices
                                )
                            }}
                        >
                            {props.buttonName}
                        </Button>
                    </div>
                </div>
                <br />
            </DialogContent>
        </Dialog>
    );
}
