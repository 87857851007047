import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";
import RECT from './login-rec.png';
import {primaryColor} from '../../constants/Color';
import "./styles.css";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(56, 64, 137, 0.79) 100%)`,
  },

  cardRoot: {
    width: "990px",
    height: "600px",
    background: "#FFFFFF",
    display: "flex",
    boxShadow: "4px 4px 50px rgba(0, 0, 0, 0.5)",
  },
  logoContainer: {
    position: "relative",
    width: "607.14px",
    height: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",

    // top: 359px;
    backgroundImage: 'url('+ RECT +')',
    // background: `linear-gradient(139.1deg, ${theme.palette.secondary.main} 0%, rgba(181, 223, 236, 0.52) 96.28%)`,
  },
  logo: {
    // backgroundImage: "url(" + LOGO + ")",
    backgroundRepeat: "no-repeat",
    // border: "0px solid white",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "350px",
    height: "125px",
    marginLeft: "75px",
  },
  logoCaption: {
    // fontStyle: "normal",
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "33px",
    marginLeft: "118px",
    width: "313px",
    // height: "33px",
    color: theme.palette.primary.main,
  },
  copyright: {
    position: "absolute",
    bottom: 16,
    height: "16px",
    fontSize: "10px",
    // width: "460px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    left: 40,
  },
  inputRootContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //     justifyContent: "center",
    width: "384.86px",
    height: "600px",
    // marginLeft: "10px",
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    width: "62px",
    fontSize: "35px",
    height: "33px",
    // backgroundColor: "red",
    // marginTop: "200px",
    // lineHeight: "33px",
    color: theme.palette.primary.main,
  },
  usernameHeading: {
    marginTop: "65px",
    height: "20px",
    width: "323px",
    color: primaryColor,
    fontSize: "0.8rem",
    textDecoration:"bold"
  },
  pswrdHeading: {
    marginTop: "35.61px",
    height: "20px",
    width: "323px",
    color: primaryColor,
    fontSize: "0.8rem",
    textDecoration:"bold"
  },
  submitBtnWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgrBtnWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginLoader: {
    color: fade(theme.palette.primary.main, 1),
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
  forgotPass: {
    width: "149px",
    height: "20px",
    fontSize: "16px",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "20px",
    color: theme.palette.primary.main,
    marginLeft: "25px",
  },
}));
