import {
  INVITE_USER,
  ADD_USER,
  LOGIN_USER,
  DASHBOARD,
  DASHBOARD_MENU_ID,
  FETCH_USERS,
  DELETE_USERS,
  FORGOT_PASSWORD,
  FETCH_LOGINDATA,
  RESET_PASSWORD,
  UPDATE_USERDATA,
  LOGOUT_USER,
  RESEND_INVITE,
  OU_CREATE,
  OU_UPDATE,
  OU_DELETE,
  OU_DERIVED,
  REFRESH_TOKEN,
  ATTACH_USER,
  ORGANIZATIONUSER_USER,
  ATTACH_USER_TO_ORGANIZATION,
  ORGANIZATIONUSER_DETACH,
  CLEAR_USER_PERMISSION_CACHE,
  CHANGE_PASSWORD,
  SERVICE_GET,
  SERVICE_DELETE,
  SERVICE_CREATE,
  SERVICE_UPDATE,
  SERVICE_FETCH,
  FETCH_OU,
  POLICIES_GET,
  POLICIES_CREATE,
  POLICIES_DELETE,
  USER_POLICIES,
  POLICIES_DETACH,
  POLICIES_FETCH,
  AGREE_POLICY,
  GET_USER_POLICIES,
  UPDATE_POLICIES,
  ATTACH_POLICIES,
  POLICY_DETAIL,
  POLICY_UPDATE,
  NOT_ATTACHED_POLICIES,
  DETACH_POLICIES,
  GET_OU_POLICIES,
  DISPLAY_OU_POLICIES,
  DISPLAY_OU_PERMISSIONS,
  NOT_ATTACHED_OU_PERMISSIONS,
  ATTACH_OU_PERMISSIONS,
  DETTACH_OU_PERMISSIONS,
  DISPLAY_OU_RESOURCES,
  NOT_ATTACHED_OU_RESOURCES,
  ATTACH_OU_RESOURCES,
  DETTACH_OU_RESOURCES,
  GROUP_GET,
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_ORGANIZATION,
  GROUP_DETAILS,
  GROUP_DELETE,
  DETACH_ORGANIZATION_POLICIES,
  USER_GROUP_ATTACH,
  GROUP_ATTACH_USER,
  GROUP_USER_GET,
  DETACH_USERS_GROUP,
  GROUP_POLICY_GET,
  GROUP_POLICY_ATTACH_POLICIES,
  GROUP_POLICY_POLICY_ATTACH,
  DETACH_GROUP_POLICY,
  GET_CHILD_OU_SERVICE,
  GET_USER_ATTACH_POLICES,
  SET_LOADING,
  SERVICE_PARENT_FETCH,
  RESOURCE_GET,
  RESOURCE_DELETE,
  RESOURCE_CREATE,
  RESOURCE_UPDATE,
  RESOURCE_FETCH,
  RESOURCE_ACTIVATE,
  RESOURCE_DEACTIVATE,
  OU_ATTACHABLE_PERMISSIONS,
  SERVICE_FUNCTION_DELETE,
  SERVICE_FUNCTION_UPDATE,
  SERVICE_FUNCTION_ADD,
} from "../actions/types";
import { DASHBOARD_ID } from "../constants/Constants";

const initialState = {
  inviteUser: {},
  addUser: {},
  loginUser: {},
  agreePolicy: {},
  dashboardData: null,
  selectedMenuId: DASHBOARD_ID,
  usersList: {},
  logindataList: null,
  deleteUsersResponse: null,
  forgotPassword: {},
  updateUserData: {},
  resetPassword: {},
  loginoutUser: {},
  resendInvite: {},
  ouCreate: {},
  ouUpdate: {},
  ouDelete: {},
  refreshToken: {},
  attachUser: {},
  organizationuserDetach: {},
  clearUserPermissionCache: {},
  changePassword: {},
  serviceGet: {},
  serviceCreate: {},
  serviceUpdate: {},
  serviceDelete: {},
  serviceFetch: {},
  fetchOu: {},
  policiesGet: {},
  policiesCreate: {},
  policiesDelete: {},
  userPolicies: {},
  policiesDetach: {},
  policiesFetch: {},
  getUserPolicies: {},
  updatePolicies: {},
  attachPolicies: {},
  policyDetail: {},
  PolicyUpdate: {},
  notAttachedPolicies: {},
  detachPolicies: {},
  getOUPolicies: {},
  displayOUPolicies: {},
  displayOUPermissions: {},
  notAttachedOuPermissions: {},
  attachOuPermissions: {},
  dettachOuPermissions: {},
  displayOUResources: {},
  notAttachedOuResources: {},
  attachOuResources: {},
  dettachOuResources: {},
  GroupGet: {},
  GroupCreate: {},
  GroupUpdate: {},
  groupsOrganization: {},
  groupsDetails: {},
  detachOrganizationPolicies: {},
  UserGroupAttach: {},
  groupsAttachUser: {},
  groupsUserGet: {},
  detachUserGroup: {},
  groupsPolicyGet: {},
  groupsPolicyAttachPolicies: {},
  GroupPolicyPolicyAttach: {},
  detachGroupPolicy: {},
  getChildOuServices: {},
  getUserAttachPolicies: {},
  serviceParentFetch: {},
  resourceGet: {},
  resourceCreate: {},
  resourceUpdate: {},
  resourceDelete: {},
  resourceFetch: {},
  resourceActivate: {},
  resourceDeactivate: {},
  ouAttachablePermissions: {},
  serviceFunctionDelete: {},
  serviceFunctionUpdate:{},
  serviceFunctionAdd:{},
  loading: false,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_LOADING: return {
      ...state,
      loading: action.payload
    }
    case INVITE_USER:
      return {
        ...state,
        inviteUser: action.payload,
      };
    case RESEND_INVITE:
      return {
        ...state,
        resendInvite: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        logoutUser: action.payload,
      };
    case UPDATE_USERDATA:
      return {
        ...state,
        updateUserData: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        addUser: action.payload,
      };
    case LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload,
      };
    case AGREE_POLICY:
      return {
        ...state,
        agreePolicy: action.payload,
      };
    case DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case DASHBOARD_MENU_ID:
      return {
        ...state,
        selectedMenuId: action.payload,
      };
    case FETCH_USERS:
      return {
        ...state,
        usersList: action.payload,
      };
    case FETCH_LOGINDATA:
      return {
        ...state,
        logindataList: action.payload,
      }
    case DELETE_USERS:
      return {
        ...state,
        deleteUsersResponse: action.payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.payload
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: action.payload,
      };
    case OU_CREATE:
      return {
        ...state,
        ouCreate: action.payload
      }
    case OU_UPDATE:
      return {
        ...state,
        ouUpdate: action.payload
      }
    case OU_DELETE:
      return {
        ...state,
        ouDelete: action.payload
      }
    case OU_DERIVED:
      return {
        ...state,
        ouDerived: action.payload
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        refresh_token: action.payload,
      };
    case ATTACH_USER:
      return {
        ...state,
        attachUser: action.payload,
      };
    case ORGANIZATIONUSER_USER:
      return {
        ...state,
        organizationuserUser: action.payload,
      };
    case ATTACH_USER_TO_ORGANIZATION:
      return {
        ...state,
        attachUserToOrganization: action.payload,
      };
    case ORGANIZATIONUSER_DETACH:
      return {
        ...state,
        organizationuserDetach: action.payload,
      };
    case CLEAR_USER_PERMISSION_CACHE:
      return {
        ...state,
        clearUserPermissionCache: action.payload,
      };
    case SERVICE_GET:
      return {
        ...state,
        serviceGet: action.payload,
      };
    case SERVICE_CREATE:
      return {
        ...state,
        serviceCreate: action.payload,
      };
    case SERVICE_UPDATE:
      return {
        ...state,
        serviceUpdate: action.payload,
      };
    case SERVICE_DELETE:
      return {
        ...state,
        serviceDelete: action.payload,
      };
    case SERVICE_FETCH:
      return {
        ...state,
        serviceFetch: action.payload,
      };
    case SERVICE_PARENT_FETCH:
      return {
        ...state,
        serviceParentFetch: action.payload,
      };
    case FETCH_OU:
      return {
        ...state,
        fetchOu: action.payload,
      };
    case POLICIES_GET:
      return {
        ...state,
        policiesGet: action.payload,
      };
    case POLICIES_CREATE:
      return {
        ...state,
        policiesCreate: action.payload,
      };
    case POLICIES_DELETE:
      return {
        ...state,
        policiesDelete: action.payload,
      };
    case USER_POLICIES:
      return {
        ...state,
        userPolicies: action.payload,
      };
    case POLICIES_DETACH:
      return {
        ...state,
        policiesDetach: action.payload,
      };
    case POLICIES_FETCH:
      return {
        ...state,
        policiesFetch: action.payload,
      };
    case GET_USER_POLICIES:
      return {
        ...state,
        getUserPolicies: action.payload,
      };
    case UPDATE_POLICIES:
      return {
        ...state,
        updatePolicies: action.payload,
      };
    case ATTACH_POLICIES:
      return {
        ...state,
        attachPolicies: action.payload,
      };
    case POLICY_DETAIL:
      return {
        ...state,
        policyDetail: action.payload,
      };
    case POLICY_UPDATE:
      return {
        ...state,
        policyUpdate: action.payload,
      };
    case NOT_ATTACHED_POLICIES:
      return {
        ...state,
        notAttachedPolicies: action.payload,
      };
    case DETACH_POLICIES:
      return {
        ...state,
        detachPolicies: action.payload,
      };
    case GET_OU_POLICIES:
      return {
        ...state,
        getOUPolicies: action.payload,
      };
    case DISPLAY_OU_POLICIES:
      return {
        ...state,
        displayOUPolicies: action.payload,
      };
    case DISPLAY_OU_PERMISSIONS:
      return {
        ...state,
        displayOUPermissions: action.payload,
      };
    case NOT_ATTACHED_OU_PERMISSIONS:
      return {
        ...state,
        notAttachedOuPermissions: action.payload,
      };
    case ATTACH_OU_PERMISSIONS:
      return {
        ...state,
        attachOuPermissions: action.payload,
      };
    case DETTACH_OU_PERMISSIONS:
      return {
        ...state,
        dettachOuPermissions: action.payload,
      };
    case DISPLAY_OU_RESOURCES:
      return {
        ...state,
        displayOUResources: action.payload,
      };
    case NOT_ATTACHED_OU_RESOURCES:
      return {
        ...state,
        notAttachedOuResources: action.payload,
      };
    case ATTACH_OU_RESOURCES:
      return {
        ...state,
        attachOuResources: action.payload,
      };
    case DETTACH_OU_RESOURCES:
      return {
        ...state,
        dettachOuResources: action.payload,
      };
    case GROUP_GET:
      return {
        ...state,
        GroupGet: action.payload,
      };
    case GROUP_CREATE:
      return {
        ...state,
        GroupCreate: action.payload,
      };
    case GROUP_UPDATE:
      return {
        ...state,
        GroupUpdate: action.payload,
      };
    case GROUP_ORGANIZATION:
      return {
        ...state,
        groupsOrganization: action.payload,
      };
    case GROUP_DETAILS:
      return {
        ...state,
        groupsDetails: action.payload,
      };
    case GROUP_DELETE:
      return {
        ...state,
        groupDelete: action.payload,
      };
    case DETACH_ORGANIZATION_POLICIES:
      return {
        ...state,
        detachOrganizationPolicies: action.payload,
      };
    case USER_GROUP_ATTACH:
      return {
        ...state,
        UserGroupAttach: action.payload,
      };
    case GROUP_ATTACH_USER:
      return {
        ...state,
        groupsAttachUser: action.payload,
      };
    case GROUP_USER_GET:
      return {
        ...state,
        groupsUserGet: action.payload,
      };
    case DETACH_USERS_GROUP:
      return {
        ...state,
        detachUserGroup: action.payload,
      };
    case GROUP_POLICY_GET:
      return {
        ...state,
        groupsPolicyGet: action.payload,
      };
    case GROUP_POLICY_ATTACH_POLICIES:
      return {
        ...state,
        groupsPolicyAttachPolicies: action.payload,
      };
    case GROUP_POLICY_POLICY_ATTACH:
      return {
        ...state,
        GroupPolicyPolicyAttach: action.payload,
      };
    case DETACH_GROUP_POLICY:
      return {
        ...state,
        detachGroupPolicy: action.payload,
      };
    case GET_CHILD_OU_SERVICE:
      return {
        ...state,
        getChildOuServices: action.payload,
      };
    case GET_USER_ATTACH_POLICES:
      return {
        ...state,
        getUserAttachPolicies: action.payload,
      };
    case RESOURCE_GET:
      return {
        ...state,
        resourceGet: action.payload,
      };
    case RESOURCE_CREATE:
      return {
        ...state,
        resourceCreate: action.payload,
      };
    case RESOURCE_UPDATE:
      return {
        ...state,
        resourceUpdate: action.payload,
      };
    case RESOURCE_DELETE:
      return {
        ...state,
        resourceDelete: action.payload,
      };
    case RESOURCE_ACTIVATE:
      return {
        ...state,
        resourceActivate: action.payload,
      };
    case RESOURCE_DEACTIVATE:
      return {
        ...state,
        resourceDeactivate: action.payload,
      };
    case RESOURCE_FETCH:
      return {
        ...state,
        resourceFetch: action.payload,
      };
    case OU_ATTACHABLE_PERMISSIONS:
      return {
        ...state,
        ouAttachablePermissions: action.payload,
      };
    case SERVICE_FUNCTION_DELETE:
      return {
        ...state,
        serviceFunctionDelete: action.payload
      }
    case SERVICE_FUNCTION_UPDATE:
      return {
        ...state,
        serviceFunctionUpdate: action.payload
      }
    case SERVICE_FUNCTION_ADD:
      return {
        ...state,
        serviceFunctionAdd: action.payload
      }
    default:
      return state;
  }
}
