import {
  GET_ERRORS,
  SET_LOADING,
} from "./types";

export const handleResponse = (res, dispatch, handler, haveLoader) => {
  if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
    dispatch({ type: handler, payload: res.data });
  } else {
    dispatch({ type: GET_ERRORS, payload: res });
  }
  if(haveLoader) {
    dispatch({ type: SET_LOADING, payload: false });
  }
}

export const handleError = (err, dispatch, haveLoader) => {
  dispatch({ type: GET_ERRORS, payload: { message: err.message } });
  if(haveLoader) {
    dispatch({ type: SET_LOADING, payload: false });
  }
}
