import React, { useState, useEffect } from "react";
import CustomTypography from "../../Components/CustomTypography";
import CustomTextField from "../../Components/CustomTextField";
import CustomPasswordTextField from "../../Components/CustomPasswordTextField";
// import CustomButton from "../../Components/CustomButton";
import { Grid, Button } from "@material-ui/core";
import TopHeader from "../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../Components/CustomAlert";
import DialogBox from "../../Components/DialogBox";
import Scroll from "react-scroll";
import _ from "lodash";
import "./UpdateUser.css";
import { updateUserData } from "../../actions/Users/authenticate";
import { fetchUserDetails } from "../../actions/Users/authenticate";
import { loginAPI } from "../../actions/Users/authenticate";
// import startsWith from "lodash.startswith";
import { isValidPhoneNumber } from "libphonenumber-js";
// import CustomSnackbar from "../../Components/Loader/CustomSnackbar";
import CustomLoader from "../../Components/Loader/CustomLoader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Base64 } from 'js-base64';

const scroll = Scroll.animateScroll;

function UpdateUser({
  history,
  authReducer,
  errorReducer,
  fetchUserDetails,
  updateUserData,
}) {
  const [userName, setUserName] = useState("");
  const [contactNo, setContactNo] = useState("");
  let [contactNoCountryCode, setContactNoCountryCode] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [helperTextList, setHelperTextList] = useState({
    firstName: "First name is invalid",
    lastName: "Last name is invalid",
    contactNo: "Contact number is invalid",
    password: "Use 5 characters or more for your password",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    firstName: true,
    lastName: true,
    contactNo: true,
    password: true,
  });
  let [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  // let [snackBar, setSnackBar] = useState({
  //   showSnackbar: false,
  //   snackbarVariant: "",
  //   snakBarMessage: "",
  //   position: "left",
  // });
  let [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.updateUserData)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.updateUserData = {};
    }
  }, [authReducer.updateUserData]);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.logindataList)) {
      setUserName(authReducer.logindataList.data.userName);
      setFirstName(
        authReducer.logindataList && authReducer.logindataList.data && authReducer.logindataList.data.name.givenName
          ? authReducer.logindataList.data.name.givenName
          : "-"
      );
      setLastName(
        authReducer.logindataList && authReducer.logindataList.data && authReducer.logindataList.data.name.familyName
          ? authReducer.logindataList.data.name.familyName
          : "-"
      );
      setContactNo(
        authReducer.logindataList.data.hasOwnProperty("phoneNumbers") === true
          ? authReducer.logindataList.data.phoneNumbers[0].value
          : ""
      );
    }
  }, [authReducer.logindataList]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "contactNo") {
      setContactNo(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
    if (name === "firstName") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["firstName"] = re.test(event.target.value);
      setFirstName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
    }
    if (name === "lastName") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["lastName"] = re.test(event.target.value);
      setLastName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
    }
  };

  const handleUpdateUserData = () => {
    scroll.scrollToTop();
    if (
      _.isEmpty(userName) ||
      _.isEmpty(firstName) ||
      _.isEmpty(lastName) ||
      _.isEmpty(contactNo)
    ) {
      setAlertMsg({
        msg: "Please Enter All the Mandatory Fields",
        type: "error",
      });
      setShowAlert(true);
    } else {
      let payload = {
        userName: userName,
        firstName: firstName,
        lastName: lastName,
        phone: contactNo,
        // password: Base64.encode(password),
      };
      updateUserData(payload);
      setLoaders({
        showLoader: true,
        loadingText: "Updating...",
      });
    }
  };

  const checkAllFieldsValidation = (notErrorInFields) => {
    let isExist = 0;
    Object.keys(notErrorInFields).map((keysName) => {
      if (notErrorInFields[keysName] === false) {
        isExist = 1;
        setAllFieldsIsValidORnot(notErrorInFields[keysName]);
      }
    });
    if (isExist === 0) setAllFieldsIsValidORnot(true);
  };

  const handlePhoneNumber = (value, country, e, formattedValue) => {
    console.log(
      "(country.countryCode).toUpperCase()---",
      country.countryCode.toUpperCase()
    );
    let checkVlidation = formattedValue.split("+" + country.dialCode + " ")[1];
    let countryCode = "+" + country.dialCode + " ";
    if (checkVlidation && checkVlidation !== "") {
      notErrorInFields["contactNo"] = isValidPhoneNumber(
        checkVlidation,
        country.countryCode.toUpperCase()
      );
    } else {
      notErrorInFields["contactNo"] = false;
    }
    setContactNo(formattedValue);
    setContactNoCountryCode(countryCode);
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  return (
    <div className="add-user">
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="outerDiv">
          <div className="innerDiv">
            <CustomTypography
              size={14}
              weight="600"
              color="#4B4C63"
              content="Update User Detail "
              lineHeight={17.14}
            />
            <div className="horizontal-line"></div>
            <CustomTypography
              size={14}
              weight="normal"
              color="#5A5757"
              content="User Name"
              lineHeight={19}
            />
            <br></br>
            <CustomTextField
              label=""
              name="userName"
              value={userName}
              disabled
              onChange={handleChange}
              charLength={50}
            />
            <br></br>
            <br></br>
            <CustomTypography
              size={14}
              weight="normal"
              color="#5A5757"
              content="First Name"
              lineHeight={19}
            />
            <br></br>
            <CustomTextField
              label=""
              name="firstName"
              value={firstName}
              onChange={handleChange}
              charLength={50}
              isValidOrInvalid={
                notErrorInFields["firstName"] ? "" : "isInvalid"
              }
              isValidOrInvalidMessage={helperTextList["firstName"]}
            />
            <br></br>
            <br></br>
            <CustomTypography
              size={14}
              weight="normal"
              color="#5A5757"
              content="Last Name"
              lineHeight={19}
            />
            <br></br>
            <CustomTextField
              label=""
              name="lastName"
              value={lastName}
              onChange={handleChange}
              charLength={50}
              isValidOrInvalid={
                notErrorInFields["lastName"] ? "" : "isInvalid"
              }
              isValidOrInvalidMessage={helperTextList["lastName"]}
            />
            <br></br>
            <br></br>

            <CustomTypography
              size={14}
              weight="normal"
              color="#5A5757"
              content="Contact Number"
              lineHeight={19}
            />
            <br></br>
            <PhoneInput
              defaultCountry={'us'}
              value={contactNo}
              placeholder=""
              inputProps={{
                name: "contactNo",
                required: true,
                autoFocus: false,
                autoComplete: "on",
              }}
              inputStyle={{
                width: "65%",
              }}
              searchStyle={{
                paddingLeft: "0px",
              }}
              enableSearch={true}
              onChange={(value, country, e, formattedValue) => {
                handlePhoneNumber(value, country, e, formattedValue);
              }}
            />
            {notErrorInFields["contactNo"] === false ? (
              <div
                style={{
                  fontSize: "15px",
                  paddingTop: "1px",
                  color: "rgb(255, 0, 0)",
                }}
              >
                Contact number is not valid
              </div>
            ) : (
              ""
            )}
            <br></br>
            <br></br>

            {/* <CustomTypography
              size={14}
              weight="normal"
              color="#5A5757"
              content="Current Password"
              lineHeight={19}
            />
            <br></br>
            <CustomPasswordTextField
              name="password"
              value={password}
              onChange={handleChange}
              charLength={64}
              isValidOrInvalid={
                notErrorInFields["password"] ? "" : "isInvalid"
              }
              isValidOrInvalidMessage={helperTextList["password"]}
            />
            <br></br>
            <br></br> */}
            <Grid item container xs={12} md={4} spacing={2}>
              <Grid container item>
                <Button
                  className={
                    !allFieldsIsValidORnot ? "grayButton" : "primaryButton"
                  }
                  disabled={!allFieldsIsValidORnot}
                  onClick={handleUpdateUserData}
                >
                  Update User
                </Button>
                <Button
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "white",
                    color: "#008fbd",
                    width: "100px",
                  }}
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Updated"
        DialogBody="Please proceed further to dashboard"
        ButtonLabel="Done"
        onClick={() => {
          history.push("/dashboard");
        }}
      />
       {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Fetching user details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    fetchUserDetails,
    loginAPI,
    updateUserData,
  })(UpdateUser)
);
