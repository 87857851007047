import React, { useState, useEffect } from "react";
import CustomTypography from "../../Components/CustomTypography";
import CustomButton from "../../Components/CustomButton";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../Components/CustomAlert";
import {
  resetPassword,
  changePassword,
} from "../../actions/Users/authenticate";
import DialogBox from "../../Components/DialogBox";
import Scroll from "react-scroll";
import _ from "lodash";
import "./ChangePassword.css";
import CustomPasswordField from "../../Components/CustomPasswordField";
import { TextField } from "@material-ui/core";
import { ReactComponent as K4Logo } from "../../assets/images/K4Logo.svg";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { Base64 } from 'js-base64';

const scroll = Scroll.animateScroll;

function ChangePassword({
  history,
  authReducer,
  errorReducer,
  resetPassword,
  changePassword,
}) {
  const [userName, setUserName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.changePassword)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.changePassword = {};
    }
  }, [authReducer.changePassword]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "oldPassword") {
      setOldPassword(event.target.value);
    }
    if (name === "newPassword") {
      setNewPassword(event.target.value);
    }
    if (name === "reEnterPassword") {
      setReEnterPassword(event.target.value);
    }
    if (name === "userName") {
      setUserName(event.target.value);
    }
  };

  const handleChangePassword = () => {
    scroll.scrollToTop();
    if (
      _.isEmpty(oldPassword) ||
      _.isEmpty(newPassword) ||
      _.isEmpty(reEnterPassword)
    ) {
      setAlertMsg({
        msg: "Please Enter All the Mandatory Fields",
        type: "error",
      });
      setShowAlert(true);
    } else if (newPassword !== reEnterPassword) {
      setAlertMsg({
        msg: "Password Fields does not Match",
        type: "error",
      });
      setShowAlert(true);
    } else {
      let payload = {
        userName: JSON.parse(localStorage.getItem("loggedInEmail")),
        currentPassword: Base64.encode(oldPassword),
        newPassword: Base64.encode(newPassword),
      };
      changePassword(payload);
      setLoaders({
        showLoader: true,
        loadingText: "Changing the password..",
      });
    }
  };

  return (
    <div className="login-page">
      <Grid item container xs={12}>
        {showAlert && showAlert === true ? (
          <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
        ) : (
          ""
        )}
        <Grid
          item
          xs={6}
          className="left-div"
          style={{ backgroundColor: "#f0f8ff" }}
        >
          <Grid item className="centerized-div">
            <Grid container xs={12} spacing={2} className="centerized-div">
              <Grid item>
                <K4Logo />
              </Grid>
              <Grid item>
                <CustomTypography
                  size={45}
                  weight="700"
                  color="#2F3484"
                  content="IAM"
                  lineHeight={87.89}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="right-div">
          <Grid item container xs={12}>
            <Grid item container className="login-heading">
              <CustomTypography
                size={36}
                weight="600"
                color="#2F3484"
                content="Change Password"
                lineHeight={49.1}
              />
            </Grid>
            <Grid item container style={{ padding: "2rem" }}>
              {/* <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="User Name"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="Current Password"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPasswordField
                  fullWidth
                  id="standard-basic"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="New Password"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPasswordField
                  value={newPassword}
                  onChange={handleChange}
                  name="newPassword"
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="Re-enter New Password"
                  lineHeight={24.55}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomPasswordField
                  value={reEnterPassword}
                  onChange={handleChange}
                  name="reEnterPassword"
                />
              </Grid>
              <Grid container item xs={12} className="login-button">
                <CustomButton
                  ButtonType=""
                  ButtonLabel="Change Password"
                  onClick={handleChangePassword}
                />
                <Button
                  style={{
                    marginLeft: "10px",
                    backgroundColor: "white",
                    color: "#008fbd",
                    width: "150px",
                  }}
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          Open={showPopUp}
          onClose={() => setShowPopUp(false)}
          DialogTitle="Password is changed successfully"
          DialogBody="Please proceed further to Login"
          ButtonLabel="Done"
          onClick={() => {
            history.push("/");
          }}
        />
         {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."
          }
        />
      ) : null}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    resetPassword,
    changePassword,
  })(ChangePassword)
);
