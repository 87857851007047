import React, { useState, useEffect } from "react";
import { Grid, Button, DialogContentText } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import CustomButton from "../../../Components/CustomButton";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./UpdateOuService.css";
import SideMenu from "../../../Components/SideMenu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AddIcon from "@material-ui/icons/Add";
import {
  serviceFetch,
  serviceUpdate,
} from "../../../actions/Users/authenticate";
import { isValidName } from "../../../utils/validation";
import CustomLoader from "../../../Components/Loader/CustomLoader";

const scroll = Scroll.animateScroll;

function UpdateOuService({
  history,
  authReducer,
  errorReducer,
  serviceFetch,
  serviceUpdate,
  location,
}) {
  const [Name, setName] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [description, setDescription] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [textFieldCount, setTextFieldCount] = useState(1);
  const [inputList, setInputList] = useState(null);
  const [rootServices, setRootServices] = useState([
    {
      function: "",
      resources: [
        { objKey: "", objValue: "", isKeyValid: true, isValuValid: true },
      ],
      isFunctionValid: true,
    },
  ]);
  const [textFieldResources, setTextFieldResources] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    Name: true,
    isFunctionValid: true,
    isKeyValid: true,
    isValuValid: true,
  });
  const [helperTextList, setHelperTextList] = useState({
    Name: "Name is invalid",
    isFunctionValid: "Function is invalid",
    isKeyValid: "Resources Key is invalid",
    isValuValid: "Resources valid is invalid",
  });
  console.log("props", location);

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceUpdate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.serviceUpdate = {};
    }
  }, [authReducer.serviceUpdate]);

  useEffect(() => {
    serviceFetch(location.state.demo1, location.state.demo.toString());
  }, []);

  useEffect(() => {
    console.log("data login", authReducer.serviceFetch);
    if (!_.isEmpty(authReducer.serviceFetch)) {
      setName(
        authReducer.serviceFetch &&
          authReducer.serviceFetch.data &&
          authReducer.serviceFetch.data[0] &&
          authReducer.serviceFetch.data[0].name
          ? authReducer.serviceFetch.data[0].name
          : "NULL"
      );
      setDescription(
        authReducer.serviceFetch &&
          authReducer.serviceFetch.data &&
          authReducer.serviceFetch.data[0] &&
          authReducer.serviceFetch.data[0].description
          ? authReducer.serviceFetch.data[0].description
          : "-"
      );
      let tempInputList = [];
      if (
        authReducer.serviceFetch.data[0] &&
        "servicefunctions" in authReducer.serviceFetch.data[0] &&
        authReducer.serviceFetch.data[0].servicefunctions.length !== 0
      ) {
        for (
          let i = 0;
          i < authReducer.serviceFetch.data[0].servicefunctions.length;
          i++
        ) {
          let funName =
            authReducer.serviceFetch.data[0].servicefunctions[i]["function"];
          let tmpRes = [];
          for (
            let j = 0;
            j <
            authReducer.serviceFetch.data[0].servicefunctions[i].resources
              .length;
            j++
          ) {
            let objKey = "",
              objValue = "";
            let tempObjdata =
              authReducer.serviceFetch.data[0].servicefunctions[i].resources[j];
            Object.keys(tempObjdata).map((keyName) => {
              objKey = keyName;
              objValue = tempObjdata[keyName];
            });
            tmpRes.push({
              objKey: objKey,
              objValue: objValue,
              isKeyValid: true,
              isValuValid: true,
            });
          }
          tempInputList.push({
            function: funName,
            resources: tmpRes,
            isFunctionValid: true,
          });
        }
        setRootServices(tempInputList);
        setTextFieldCount(
          authReducer.serviceFetch.data[0].servicefunctions.length
            ? authReducer.serviceFetch.data[0].servicefunctions.length
            : 1
        );
      }
    }
  }, [authReducer.serviceFetch]);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "Name") {
      setName(event.target.value);
    }
    if (name === "description") {
      setDescription(event.target.value);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rootServices];
    list[index]["function"] = value;
    console.log("value, valid--", value, isValidName(value));
    list[index]["isFunctionValid"] = isValidName(value);
    setInputList(list);
    setDisableSave(isValidName(value));
    console.log("yeahh", rootServices);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...rootServices];
    list.splice(index, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]["isValid"] === false) {
        isError = 1;
        break;
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldCount(textFieldCount - 1);
  };

  const handleRemoveResources = (index, resIndex) => {
    const list = [...rootServices];
    list[index]["resources"].splice(resIndex, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i]["resources"].length; j++) {
        if (list[i]["resources"][j]["isKeyValid"] === false) {
          isError = 1;
          break;
        }
        if (list[i]["resources"][j]["isValuValid"] === false) {
          isError = 1;
          break;
        }
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldResources(textFieldResources - 1);
  };

  const handleAddClick = () => {
    const list = [...rootServices];
    list.push({
      function: "",
      resources: [
        { objKey: "", objValue: "", isKeyValid: true, isValuValid: true },
      ],
      isFunctionValid: true,
    });
    setRootServices(list);
    setTextFieldCount(textFieldCount + 1);
  };

  const handleAddResourecClick = (e, index) => {
    const { name, value } = e.target;
    let newlist = [...rootServices];
    let obj = newlist[index];
    obj.resources.push({
      objKey: "",
      objValue: "",
      isKeyValid: true,
      isValuValid: true,
    });
    setRootServices([...newlist]);
    setTextFieldResources(textFieldResources + 1);
    setDisableSave(isValidName(value));
  };

  const handleInputChange1 = (e, index, resIndex) => {
    const { name, value } = e.target;
    const newlist = [...rootServices];
    let obj = newlist[index];
    newlist[index]["resources"][resIndex][name] = value;
    if (name === "objKey")
      newlist[index]["resources"][resIndex]["isKeyValid"] = isValidName(value);
    else if (name === "objValue")
      newlist[index]["resources"][resIndex]["isValuValid"] = isValidName(value);
    setRootServices([...newlist]);
    setDisableSave(isValidName(value));
  };

  const handleUpdateOu = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    let tempinputList = [];
    for (let i = 0; i < rootServices.length; i++) {
      let funName = rootServices[i]["function"];
      let tmpRes = [];
      for (let j = 0; j < rootServices[i]["resources"].length; j++) {
        let keyName = rootServices[i]["resources"][j]["objKey"];
        let keyValue = rootServices[i]["resources"][j]["objValue"];
        tmpRes.push({ [keyName]: keyValue });
      }
      tempinputList.push({
        function: funName,
        resources: tmpRes,
      });
    }
    let payload = {
      name: Name,
      ouName: location.state.demo1,
      serviceFunctions: tempinputList,
      description: description,
      updatedBy: JSON.parse(localStorage.getItem("loggedInEmail")),
      loggedInUser: JSON.parse(localStorage.getItem("loggedInEmail")),
    };
    serviceUpdate(payload);
    setLoaders({
      showLoader: true,
      loadingText: "Updating the service..",
    });
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Organizational Unit"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;
                {" > " +
                  location.state.demo1 +
                  " > Edit Service > " +
                  location.state.demo}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "12px", width: "1450px" }}>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content="Enter Service Details"
                  lineHeight={17.14}
                />
                <div className="horizontal-line"></div>
                <form
                  onSubmit={(e) => {
                    handleUpdateOu(e);
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Name"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <CustomTextField
                          label=""
                          name="Name"
                          value={Name}
                          onChange={handleChange}
                          disabled
                          charLength={50}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Description"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <CustomTextField
                          label=""
                          name="description"
                          value={description}
                          onChange={handleChange}
                          charLength={50}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid style={{ background: "#F0F8FF" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: "15px", marginLeft: "10px" }}
                    >
                      <Grid xs={4}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Functions"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Resource"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {rootServices.map((obj, index) => {
                      return (
                        <div
                          className="box"
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={3} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="function"
                                value={obj["function"]}
                                disabled
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj["isFunctionValid"] ? "" : "isInvalid"
                                }
                                isValidOrInvalidMessage={
                                  helperTextList["isFunctionValid"]
                                }
                              />
                            </Grid>
                            <Grid>
                              <CloseOutlinedIcon
                                className={
                                  rootServices.length <= 1
                                    ? "iconNotAllow"
                                    : "iconAllow"
                                }
                                onClick={
                                  rootServices.length === 1
                                    ? () => {}
                                    : () => handleRemoveClick(index)
                                }
                              />
                            </Grid>
                            <Grid xs={7}>
                              {obj.resources.map((el, indexx) => {
                                return (
                                  <Grid item container xs={12}>
                                    <Grid
                                      style={{
                                        marginLeft: "80px",
                                        marginTop: "5px",
                                      }}
                                      xs={5}
                                    >
                                      <CustomTextField
                                        onChange={(el) =>
                                          handleInputChange1(el, index, indexx)
                                        }
                                        name={"objKey"}
                                        value={el.objKey}
                                        disabled
                                        isValidOrInvalid={
                                          el.isKeyValid ? "" : "isInvalid"
                                        }
                                        isValidOrInvalidMessage={
                                          helperTextList["isKeyValid"]
                                        }
                                      />
                                    </Grid>
                                    <Grid style={{ marginLeft: "10px" }} xs={5}>
                                      <CustomTextField
                                        onChange={(el) =>
                                          handleInputChange1(el, index, indexx)
                                        }
                                        name={"objValue"}
                                        value={el.objValue}
                                        disabled
                                        isValidOrInvalid={
                                          el.isValuValid ? "" : "isInvalid"
                                        }
                                        isValidOrInvalidMessage={
                                          helperTextList["isValuValid"]
                                        }
                                      />
                                    </Grid>
                                    <Grid>
                                      <CloseOutlinedIcon
                                        className={
                                          obj.resources.length <= 1
                                            ? "iconNotAllow"
                                            : "iconAllow"
                                        }
                                        onClick={
                                          obj.resources.length === 1
                                            ? () => {}
                                            : () =>
                                                handleRemoveResources(
                                                  index,
                                                  indexx
                                                )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item>
                      <CustomButton
                        ButtonType=""
                        ButtonLabel="Update"
                        Disabled={!disableSave}
                        type="submit"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "#008fbd",
                          width: "100px",
                        }}
                        className="delete-user-button"
                        onClick={() => history.goBack()}
                      >
                        {"Cancel"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Service Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching service details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    serviceFetch,
    serviceUpdate,
  })(UpdateOuService)
);
