import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import CustomButton from "../../../Components/CustomButton";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./UpdateResource.css";
import SideMenu from "../../../Components/SideMenu";
import {
  resourceFetch,
  resourceUpdate,
} from "../../../actions/Users/authenticate";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const scroll = Scroll.animateScroll;
const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function UpdateResource({
  history,
  authReducer,
  errorReducer,
  resourceFetch,
  resourceUpdate,
  location,
}) {
  const classes = useStyles();
  const [oldName, setOldName] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [resource, setResource] = useState("{}");
  const [allResource, setAllResource] = useState(false);
  const [subTypes, setSubTypes] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [notErrorInFields, setNotErrorInFields] = useState({
    Name: true,
    isFunctionValid: true,
    isKeyValid: true,
    isValuValid: true,
  });
  const [helperTextList, setHelperTextList] = useState({
    Name: "Name is invalid",
    isFunctionValid: "Function is invalid",
    isKeyValid: "Resources Key is invalid",
    isValuValid: "Resources valid is invalid",
  });
  const [types, setTypes] = useState(["Vessel", "SubscriptionPlan", "Port"]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceUpdate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.resourceUpdate = {};
    }
  }, [authReducer.resourceUpdate]);

  useEffect(() => {
    resourceFetch(location.state.demo);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceFetch)) {
      if (authReducer.resourceFetch && authReducer.resourceFetch.data) {
        let resource = authReducer.resourceFetch.data;
        setName(resource.name ? resource.name : '');
        setOldName(resource.name ? resource.name : '');
        setDescription(resource.description ? resource.description : '');
        setType(resource.type ? resource.type : '');
        setResource(JSON.stringify(resource.resource ? resource.resource : '{}'));
        setAllResource(resource.is_all ? resource.is_all : false);
        setSubTypes(resource.sub_types ? resource.sub_types : []);
      }
    }
  }, [authReducer.resourceFetch]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "name") {
      setName(event.target.value);
    }
    if (name === "description") {
      setDescription(event.target.value);
    }

    if (name === "resource") {
      try {
        setResource(event.target.value);
      } catch (ex) {
        notErrorInFields["Resource"] = false;
        setNotErrorInFields(notErrorInFields);
      }
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const handleCheckboxClick = (e, value = null) => {
    setAllResource(!allResource);
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    let payload = {
      name: oldName,
      newName: name,
      type: type,
      description: description,
      resource: JSON.parse(resource),
      is_all: allResource,
      sub_types: subTypes
    };
    resourceUpdate(payload);
    setLoaders({ showLoader: true, loadingText: "Updating the resource.." });
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" style={{ cursor: "pointer" }} href="/dashboard/resources">
                  Resources
                </Link>
                <Typography color="text.primary">Update - {location.state.demo}</Typography>
              </Breadcrumbs>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "12px" }}>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content="Enter Resource Details"
                  lineHeight={17.14}
                />
                <div className="horizontal-line"></div>
                <form
                  onSubmit={(e) => {
                    handleUpdate(e);
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Name"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="name"
                          value={name}
                          onChange={handleChange}
                          charLength={50}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Type"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <TextField
                          id="type"
                          select
                          value={type}
                          onChange={handleChange}
                          name="type"
                          value={type}
                          isValidOrInvalid={
                            notErrorInFields["Type"] ? "" : "isInvalid"
                          }
                          isValidOrInvalidMessage={helperTextList["Type"]}
                          style={{ width: "100%" }}
                        >
                          {types.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Description"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="description"
                          value={description}
                          onChange={handleChange}
                          charLength={50}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Resource"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <CustomTextField
                          label=""
                          name="resource"
                          value={resource}
                          onChange={handleChange}
                          charLength={100}
                          required
                        />
                        {/* <JSONInput
                          locale={locale}
                          height="40px"
                          width="325px"
                          onChange={handleChange}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <Grid style={{ marginTop: "10px" }}>
                        <Checkbox
                          onClick={(event) => {
                            handleCheckboxClick(event);
                          }}
                          role="checkbox"
                          classes={{
                            root: classes.CheckBoxroot,
                            checked: classes.checked,
                          }}
                          checked={allResource ? true : false}
                        />
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="All Resources"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                            display: "inline",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Supported Sub Types"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px" }}>
                        <Autocomplete
                          multiple
                          value={subTypes}
                          options={types}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            setSubTypes(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Sub Types"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item>
                      <CustomButton
                        ButtonType=""
                        ButtonLabel="Update"
                        Disabled={!disableSave}
                        type="submit"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "#008fbd",
                          width: "100px",
                        }}
                        className="delete-user-button"
                        onClick={() => history.goBack()}
                      >
                        {"Cancel"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Resource Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching service details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    resourceFetch,
    resourceUpdate,
  })(UpdateResource)
);
