import React, { useState, useEffect } from "react";
import CustomTypography from "../../Components/CustomTypography";
import CustomPasswordField from "../../Components/CustomPasswordField";
import CustomButton from "../../Components/CustomButton";
import { ReactComponent as K4Logo } from "../../assets/images/K4Logo.svg";
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAPI } from "../../actions/Users/authenticate";
import { policyAPI } from "../../actions/Users/authenticate";
import CustomAlert from "../../Components/CustomAlert";
import DialogBox from "../../Components/DialogBox";
import { useLocation } from "react-router-dom";
import CustomLogin from "../../Components/CustomLogin";
import _ from "lodash";
import "./login.css";
import useWindowDimensions from "../../utils/screenWidthHight";
import { Base64 } from "js-base64";
import CustomSnackbar from "../../Components/Loader/CustomSnackbar";
import CustomLoader from "../../Components/Loader/CustomLoader";

function Login({ history, authReducer, errorReducer, loginAPI, policyAPI }) {
  const [checked, setChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [fullLoader, setFullLoader] = useState(false);

  useEffect(() => {
    if (window.location && window.location.search) {
      let idParams = window.location.search;
      let id = idParams.replace("?redirectUrl=", "").trim();

      setRedirectUrl(id);
    }
  }, [location]);

  useEffect(() => {
    var rememberStr = localStorage.getItem("rememberMe");
    if (rememberStr === "true") {
      setUserName(localStorage.getItem("userName"));
      setPassword(localStorage.getItem("password"));
    }
    console.log("authReducer.loginUser---", authReducer.loginUser);
    if (!_.isEmpty(authReducer.loginUser)) {
      let payload = {
        userName: userName,
      };
      policyAPI(payload, redirectUrl);
    }
  }, [authReducer.loginUser]);

  useEffect(() => {
    console.log("authReducer agreePolicy---", authReducer.agreePolicy);
    console.log("serviceStr123---", authReducer.agreePolicy);
    if (!_.isEmpty(authReducer.agreePolicy)) {
      let retPolicy = authReducer.agreePolicy;
      let arr = retPolicy.permissions;

      if ("permissions" in retPolicy && retPolicy.permissions.length !== 0) {
        if (_.isEmpty(arr)) {
          console.log("serviceStr2---")
          setShowPopUp(true);
        } else {
          console.log("serviceStr3---")
          let isIAMExist = 0;
          for (var i = 0; i < arr.length; i++) {
            let serviceStr = (arr[i].service).toString();
            let serviceArr = arr[i].servicefunctions;
            console.log("serviceStr---", serviceStr, typeof (serviceStr))
            if (serviceStr === "IAM" || serviceStr.includes("IAM")) {
              console.log("serviceStr4---")
              for (var j = 0; j < serviceArr.length; j++) {
                let funtionStr = serviceArr[j].function;
                if (funtionStr === "Admin") {
                  console.log("serviceStr6---")
                  isIAMExist = 1;
                  console.log(
                    "authReducer.loginUser---",
                    authReducer.loginUser.data
                  );

                  if (checked === true) {
                    localStorage.setItem("rememberMe", true);
                    localStorage.setItem("userName", userName);
                    localStorage.setItem("password", password);
                  } else {
                    localStorage.setItem("rememberMe", false);
                    localStorage.setItem("userName", "");
                    localStorage.setItem("password", "");
                  }
                  localStorage.setItem(
                    "loggedInUser",
                    JSON.stringify(authReducer.loginUser.data)
                  );
                  let ret = authReducer.loginUser.data;
                  var args = "";
                  var loginUrl;
                  args += "id_token=" + ret.id_token;
                  args += "&access_token=" + ret.access_token;
                  args += "&expires_in=" + ret.expires_in;
                  args += "&refresh_token=" + ret.refresh_token;
                  args += "&token_type=" + ret.token_type;
                  loginUrl = window.location.href + "dashboard" + "#" + args;
                  // loginUrl = window.location.href + 'dashboard';
                  console.log("loginUrl---", loginUrl);
                  window.location.href = loginUrl;
                  authReducer.loginUser.data = {};
                  break;
                }
              }
            }

            console.log("serviceStr5---")

            if (isIAMExist === 1) {
              break;
            }

          }

          console.log("serviceStr2---", isIAMExist)

          if (isIAMExist === 0) {
            setShowPopUp(true);
          }
        }
      } else {
        setShowPopUp(true);
      }
      authReducer.agreePolicy = {};
    }
  }, [authReducer.agreePolicy]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.message === "User Not Authorize") {
        setShowPopUp(true);
      } else {
        setErrorMsg(errorReducer.message);
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 4000);
      }
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleChangeRemember = (event) => {
    setChecked(event.target.checked);
  };

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (_.isEmpty(userName) || _.isEmpty(password)) {
      setErrorMsg("Please Enter All the Mandatory Fields");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    } else {
      let payload = {
        userName: userName,
        password: Base64.encode(password),
        // password: password
      };
      loginAPI(payload, redirectUrl);

      //   if (checked === true) {
      //     localStorage.setItem("rememberMe", true);
      //     localStorage.setItem("userName", userName);
      //     localStorage.setItem("password", password);
      //   } else {
      //     localStorage.setItem("rememberMe", false);
      //     localStorage.setItem("userName", "");
      //     localStorage.setItem("password", "");
      //   }
    }
  };

  return (
    <div className="login-page">
      <Grid item container xs={12}>
        {showErrorMsg && errorMsg!="You are not authorized to access this feature." ? <CustomAlert type="error" msg={errorMsg} /> : ""}
        <Grid item xs={6} className="left-div">
          <Grid item className="centerized-div">
            <Grid container xs={12} spacing={2} className="centerized-div">
              <Grid item>
                <K4Logo style={{ width: "170.3px", height: "228px" }} />
              </Grid>
              <Grid item style={{ paddingBottom: "50px" }}>
                <CustomLogin
                  size={width <= 320 ? 31 : 82}
                  weight="700"
                  color="#2F3484"
                  fontFamily="Calibri"
                  content="IAM"
                  lineHeight={87.89}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className="right-div">
          <Grid item container xs={12}>
            <Grid item container className="login-heading">
              <CustomLogin
                size={36}
                weight="600"
                color="#2F3484"
                content="Login"
                fontFamily="Nunito"
                lineHeight={49.1}
              />
            </Grid>
            <Grid
              item
              container
              style={{ padding: "2rem" }}
              className="right-heading"
            >
              <Grid item xs={12}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="User Name"
                  lineHeight={24.55}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "3rem" }}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#2F3484"
                  content="Password"
                  lineHeight={24.55}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <CustomPasswordField
                  value={password}
                  onChange={handleChange}
                  name="password"
                  keypress={handleKeyPress}
                />
              </Grid>
              {width < 768 ? (
                <Grid item container xs={12} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          value="remember"
                          color="primary"
                          style={{ color: "#008FBD" }}
                          onChange={handleChangeRemember}
                        />
                      }
                      label={
                        <CustomTypography
                          content="Remember me."
                          size={14}
                          color="#6D6D6D"
                          weight="600"
                          lineHeight={17.14}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    style={{
                      alignSelf: "center",
                      cursor: "pointer",
                      marginTop: "1rem",
                    }}
                    onClick={() => history.push("/forgot-password")}
                  >
                    <CustomTypography
                      content="Forgot your password?"
                      size={14}
                      color="#6D6D6D"
                      weight="600"
                      lineHeight={17.14}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item container xs={12} style={{ marginTop: "1rem" }}>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          value="remember"
                          color="primary"
                          style={{ color: "#008FBD" }}
                          onChange={handleChangeRemember}
                        />
                      }
                      label={
                        <CustomTypography
                          content="Remember me."
                          size={14}
                          color="#6D6D6D"
                          weight="600"
                          lineHeight={17.14}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    onClick={() => history.push("/forgot-password")}
                  >
                    <CustomTypography
                      content="Forgot your password?"
                      size={14}
                      color="#6D6D6D"
                      weight="600"
                      lineHeight={17.14}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className="login-button">
                <CustomButton
                  ButtonType=""
                  ButtonLabel="Login"
                  onClick={handleLogin}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogBox
          Open={showPopUp}
          onClose={() => setShowPopUp(false)}
          DialogTitle="K4IAM"
          DialogBody="You are not authorized to access the K4IAM dashboard."
          ButtonLabel="Done"
          onClick={() => setShowPopUp(false)}
        />
      </Grid>
    </div>


  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    loginAPI,
    policyAPI,
  })(Login)
);
