import React, { useState, useEffect } from "react";
import { Grid, Typography, DialogContentText, Button } from "@material-ui/core";
import CustomTypography from "../../../CustomTypography";
import CustomButton from "../../../CustomButton";
import TopHeader from "../../../TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../CustomAlert";
import DialogBox from "../../../DialogBox";
import _ from "lodash";
import {
  getUserPolicies,
  policyAPI,
} from "../../../../actions/Users/authenticate";
import SideMenu from "../../../SideMenu";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./UserPolicy.css";
import moment from "moment";
import { Fragment } from "react";
import CustomLoader from "../../../Loader/CustomLoader";

function UserPolicy({
  history,
  authReducer,
  errorReducer,
  location,
  getUserPolicies,
  policyAPI,
  match,
}) {
  const [created, setCreated] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [id, setId] = useState("");
  const [orgName, setorgName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [name, setName] = useState([]);
  const [GroupName, setGroupName] = useState([]);
  const [summary, setSummary] = useState([]);
  const { userName } = match.params;
  const [selectedButton, setSelectedButton] = useState("Permissions");
  const [selectedAttachButton, setSelectedAttachButton] =
    useState("AttachedDirectly");
  const [permissionApi, setPermissionApi] = useState(false);
  const [summaryApi, setSummaryApi] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  console.log("loaction", location);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    getUserPolicies(userName);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.getUserPolicies)) {
      console.log("policyAPI getuserplocies", authReducer.getUserPolicies);
      setName(
        authReducer.getUserPolicies &&
          authReducer.getUserPolicies.userPermissions
          ? authReducer.getUserPolicies.userPermissions
          : "-"
      );
      setGroupName(
        authReducer.getUserPolicies &&
          authReducer.getUserPolicies.groupPermissions
          ? authReducer.getUserPolicies.groupPermissions
          : "-"
      );
      setId(
        authReducer.getUserPolicies.user.id
          ? authReducer.getUserPolicies.user.id
          : "-"
      );
      setorgName(
        authReducer.getUserPolicies.orgName
          ? authReducer.getUserPolicies.orgName
          : "-"
      );
      setCreated(
        authReducer.getUserPolicies.user.meta.created
          ? authReducer.getUserPolicies.user.meta.created
          : "-"
      );
    }
  }, [authReducer.getUserPolicies]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.agreePolicy)) {
      setSummary(authReducer.agreePolicy ? authReducer.agreePolicy : "-");
    }
  }, [authReducer.agreePolicy]);

  const handlePermissions = () => {
    setSelectedButton("Permissions");
  };

  const handleAttachedDirectly = () => {
    setSelectedAttachButton("AttachedDirectly");
  };

  const handleGroupDirectly = () => {
    setSelectedAttachButton("GroupDirectly");
  };

  const handleSummary = () => {
    if (summaryApi === false) {
      let payload = {
        userName: userName,
      };
      policyAPI(payload);
      setLoaders({
        showLoader: true,
        loadingText: "Fetching summary please wait...",
      });
    }
    setSummaryApi(true);
    setSelectedButton("Summary");
  };

  console.log("momemt", moment(created).format("yyyy-MM-D LTS"));
  console.log("weeeee", summary);

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Users"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                }}
              >
                &nbsp;{"> " + location.state.demo1}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <div className="innerDiv">
              <Grid item container xs={12}>
                <CustomTypography
                  size={18}
                  weight="600"
                  color="#4B4C63"
                  content="User Details"
                />
                <div className="horizontal-line"></div>
                <Grid item container xs={12}>
                  <Grid item container xs={6}>
                    <DialogContentText
                      style={{
                        size: "17",
                        fontWeight: "600",
                        color: "#4B4C63",
                      }}
                      lineHeight={19}
                    >
                      {"User Name: "}
                    </DialogContentText>
                    <DialogContentText
                      style={{
                        size: "16",
                        weight: "normal",
                        color: "#5A5757",
                      }}
                      lineHeight={19}
                    >
                      &nbsp;{location.state.demo}
                    </DialogContentText>
                  </Grid>
                  <Grid item container xs={6}>
                    <DialogContentText
                      style={{
                        size: "17",
                        fontWeight: "600",
                        color: "#4B4C63",
                      }}
                      lineHeight={19}
                    >
                      {"Organization Name: "}
                    </DialogContentText>
                    <DialogContentText
                      style={{
                        size: "16",
                        weight: "normal",
                        color: "#5A5757",
                      }}
                      lineHeight={19}
                    >
                      &nbsp;{orgName}
                    </DialogContentText>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={6}>
                    <DialogContentText
                      style={{
                        size: "17",
                        fontWeight: "600",
                        color: "#4B4C63",
                      }}
                      lineHeight={19}
                    >
                      {"User Id: "}
                    </DialogContentText>
                    <DialogContentText
                      style={{
                        size: "16",
                        weight: "normal",
                        color: "#5A5757",
                      }}
                      lineHeight={19}
                    >
                      &nbsp;{id}
                    </DialogContentText>
                  </Grid>
                  <Grid item container xs={6}>
                    <DialogContentText
                      style={{
                        size: "17",
                        fontWeight: "600",
                        color: "#4B4C63",
                      }}
                      lineHeight={19}
                    >
                      {"Created At: "}
                    </DialogContentText>
                    <DialogContentText
                      style={{
                        size: "16",
                        weight: "normal",
                        color: "#5A5757",
                      }}
                      lineHeight={19}
                    >
                      &nbsp;{moment(created).format("yyyy-MM-D LTS")}
                    </DialogContentText>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <br></br>
            <Grid>
              <div className="innerDiv">
                <Grid container>
                  <Button
                    style={{
                      marginTop: "-13px",
                      marginLeft: "-10px",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#F2F2F2",
                      color: "#000",
                      height: "44px",
                      width: "200px",
                      borderBottom:
                        selectedButton === "Permissions"
                          ? "2px solid #008FBD"
                          : "none",
                      borderRadius: "0px",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                    onClick={handlePermissions}
                  >
                    Permissions
                  </Button>
                  <Button
                    style={{
                      marginTop: "-13px",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#F2F2F2",
                      color: "#000",
                      height: "44px",
                      width: "200px",
                      borderRadius: "0px",
                      borderBottom:
                        selectedButton === "Summary"
                          ? "2px solid #008FBD"
                          : "none",
                      borderTop: "none",
                      borderLeft: "2px solid #fff",
                      borderRight: "none",
                    }}
                    onClick={handleSummary}
                  >
                    Summary
                  </Button>
                </Grid>
                <br />
                {selectedButton === "Permissions" ? (
                  <Fragment>
                    <div className="innerDiv">
                      <Grid container>
                        <Button
                          style={{
                            marginTop: "-13px",
                            marginLeft: "-10px",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#F2F2F2",
                            color: "#000",
                            height: "44px",
                            width: "200px",
                            borderBottom:
                              selectedAttachButton === "AttachedDirectly"
                                ? "2px solid #008FBD"
                                : "none",
                            borderRadius: "0px",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                          onClick={handleAttachedDirectly}
                        >
                          Attached Directly
                        </Button>
                        <Button
                          style={{
                            marginTop: "-13px",
                            marginLeft: "-10px",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#F2F2F2",
                            color: "#000",
                            height: "44px",
                            width: "200px",
                            borderBottom:
                              selectedAttachButton === "GroupDirectly"
                                ? "2px solid #008FBD"
                                : "none",
                            borderRadius: "0px",
                            borderTop: "none",
                            borderLeft: "2px solid #fff",
                            borderRight: "none",
                          }}
                          onClick={handleGroupDirectly}
                        >
                          Attached through Group
                        </Button>
                      </Grid>
                      {selectedAttachButton === "AttachedDirectly" ? (
                        <Fragment>
                          <Grid container item style={{ marginTop: "20px" }}>
                            <CustomButton
                              ButtonType=""
                              ButtonLabel="Add Permissions"
                              onClick={() =>
                                history.push({
                                  pathname: `/dashboard/users/add-permission/${userName}`,
                                  state: {
                                    demo: location.state.demo,
                                    demo1: location.state.demo1,
                                    ouName: orgName
                                  },
                                })
                              }
                            />
                            <div style={{ marginLeft: "10px" }}>
                              <CustomButton
                                ButtonType=""
                                ButtonLabel="Remove Permissions"
                                onClick={() =>
                                  history.push({
                                    pathname: `/dashboard/users/Remove-permission/${userName}`,
                                    state: {
                                      demo: location.state.demo,
                                      demo1: location.state.demo1,
                                      ouName: orgName
                                    },
                                  })
                                }
                              />
                            </div>
                          </Grid>
                          {name.length != 0 && authReducer.loading === false ? (
                            <div style={{ marginTop: "15px" }}>
                              {name.map((row, i) => (
                                <Grid key={row.name}>
                                  <Accordion style={{ boxShadow: "#f8f8ff" }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      style={{
                                        color: "rgba(0, 143, 189, 1)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {row.name}
                                    </AccordionSummary>
                                    <div
                                      style={{
                                        marginLeft: " 50px",
                                        marginTop: "0",
                                      }}
                                    >
                                      <pre>
                                        {JSON.stringify(row.policy, null, 2)}
                                      </pre>
                                    </div>
                                  </Accordion>
                                  <br></br>
                                </Grid>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <div className="horizontal-line"></div>
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  fontSize: "22px",
                                  fontWeight: "600",
                                  marginTop: "20px",
                                  backgroundColor: "white",
                                  // border: "1.5px solid #5A5757",
                                  padding: "10px",
                                  flexDirection: "column",
                                  color: "#5A5757",
                                }}
                              >
                                No policy attached
                              </div>
                            </div>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="horizontal-line"></div>
                          {GroupName.length != 0 &&
                          authReducer.loading === false ? (
                            <div>
                              {GroupName.map((row, i) => (
                                <Grid key={row.GroupName}>
                                  <Accordion style={{ boxShadow: "#f8f8ff" }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      style={{
                                        color: "rgba(0, 143, 189, 1)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {row.name}
                                    </AccordionSummary>
                                    <div
                                      style={{
                                        marginLeft: " 50px",
                                        marginTop: "0",
                                      }}
                                    >
                                      <pre>
                                        {JSON.stringify(row.policy, null, 2)}
                                      </pre>
                                    </div>
                                  </Accordion>
                                  <br></br>
                                </Grid>
                              ))}
                            </div>
                          ) : (
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                fontSize: "22px",
                                fontWeight: "600",
                                marginTop: "20px",
                                backgroundColor: "white",
                                // border: "1.5px solid #5A5757",
                                padding: "10px",
                                flexDirection: "column",
                                color: "#5A5757",
                              }}
                            >
                              No policy attached
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {authReducer.loading === false ? (
                      <pre>{JSON.stringify(summary, null, 2)}</pre>
                    ) : (
                      ""
                    )}
                  </Fragment>
                )}
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          history.push("/dashboard");
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching user details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    getUserPolicies,
    policyAPI,
  })(UserPolicy)
);
