import React, { useState, useEffect } from "react";
import { Grid, DialogContentText, Button } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import CustomButton from "../../../Components/CustomButton";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./AddServices.css";
import SideMenu from "../../../Components/SideMenu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { serviceCreate } from "../../../actions/Users/authenticate";
import { isValidName } from "../../../utils/validation";
import CustomLoader from "../../../Components/Loader/CustomLoader";

const scroll = Scroll.animateScroll;

function AddServices({ history, authReducer, errorReducer, serviceCreate }) {
  const [Name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [rootServices, setRootServices] = useState([
    {
      function: "",
      description: "",
      resources: [
        { objKey: "", objValue: "" },
      ],
      isFunctionValid: true,
      isDescriptionValid: true
    },
  ]);
  const [disableSave, setDisableSave] = useState(true);
  const [textFieldCount, setTextFieldCount] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [notErrorInFields, setNotErrorInFields] = useState({
    Name: true,
    isFunctionValid: true
  });
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [helperTextList, setHelperTextList] = useState({
    Name: "Name is invalid",
    isFunctionValid: "Function is invalid",
    isDescriptionValid: "Description is invalid",
    isKeyValid: "Resources Key is invalid"
  });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });

  useEffect(() => {
    setTimeout(() => { setShowAlert(false); }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceCreate)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.serviceCreate = {};
    }
  }, [authReducer.serviceCreate]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rootServices];
    if("function" == name) {
      list[index]["function"] = value;
      list[index]["isFunctionValid"] = isValidName(list[index]["function"]);
    }
    if("description" == name) {
      list[index]["description"] = value;
      list[index]["isDescriptionValid"] = isValidName(list[index]["description"]);
    }
    setRootServices(list);
    setDisableSave(isValidName(value));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...rootServices];
    list.splice(index, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]["isValid"] === false) {
        isError = 1;
        break;
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldCount(textFieldCount - 1);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const list = [...rootServices];
    list.push({
      function: "",
      isFunctionValid: true,
    });
    setRootServices(list);
    setTextFieldCount(textFieldCount + 1);
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "Name") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["Name"] = re.test(event.target.value);
      setName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
      setDisableSave(setName(event.target.value));
    }
    if (name === "description") {
      setDescription(event.target.value);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    let functionList = [];
    for (let i = 0; i < rootServices.length; i++) {
      functionList.push({
        name: rootServices[i]["function"],
        description: rootServices[i]["description"]
      });
    }
    let payload = {
      name: Name,
      description: description,
      serviceFunctions: functionList,
      parentServiceName: false,
      ouName: "K4Mobility",
    };
    serviceCreate(payload);
    setLoaders({ showLoader: true, loadingText: "Adding the service..." });
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "100vh",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Service "}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;{"> Add Service"}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "12px" }}>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content="Enter Service Details"
                  lineHeight={19}
                />
                <div className="horizontal-line"></div>
                <form
                  onSubmit={(e) => {
                    handleCreate(e);
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Name"
                        required="true"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="Name"
                          value={Name}
                          onChange={handleChange}
                          charLength={50}
                          isValidOrInvalid={
                            notErrorInFields["Name"] ? "" : "isInvalid"
                          }
                          isValidOrInvalidMessage={helperTextList["Name"]}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Description"
                        lineHeight={19}
                        required="true"
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="description"
                          value={description}
                          onChange={handleChange}
                          charLength={100}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                      position: 'absolute',
                      right: '20px',
                      zIndex: 5
                    }}
                    onClick={handleAddClick}
                  >
                    + Add Service Function
                  </Button>
                  <Grid style={{ background: "#F0F8FF", marginTop: '40px' }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: "15px", marginLeft: "10px" }}
                    >
                      <Grid xs={4}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Functions"
                          lineHeight={19}
                          required="true"
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {rootServices.map((obj, index) => {
                      return (
                        <div
                          className="box"
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={3} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="function"
                                placeholder="Function name"
                                value={obj["function"]}
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj.isFunctionValid ? "" : "isInvalid"
                                }
                                isValidOrInvalidMessage={
                                  helperTextList["isFunctionValid"]
                                }
                              />
                            </Grid>
                            <Grid item xs={8} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="description"
                                placeholder="Description"
                                value={obj["description"]}
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj.isDescriptionValid ? "" : "isInvalid"
                                }
                                isValidOrInvalidMessage={
                                  helperTextList["isDescriptionValid"]
                                }
                              />
                            </Grid>
                            
                            <Grid>
                              <CloseOutlinedIcon
                                className={
                                  rootServices.length <= 1
                                    ? "iconNotAllow"
                                    : "iconAllow"
                                }
                                onClick={
                                  rootServices.length === 1
                                    ? () => { }
                                    : () => handleRemoveClick(index)
                                }
                              />
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    <br></br>
                    <br></br>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item>
                      <CustomButton
                        ButtonType=""
                        ButtonLabel="Add"
                        style={{ width: "300px" }}
                        Disabled={!disableSave}
                        type="submit"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "#008fbd",
                          width: "100px",
                        }}
                        className="delete-user-button"
                        onClick={() => history.goBack()}
                      >
                        {"Cancel"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Service Successfully Added"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }}
      />
      {loaders["showLoader"] ? (
        <CustomLoader
          showLoader={loaders["showLoader"]}
          loadingText={
            loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    serviceCreate,
  })(AddServices)
);
