import React from 'react';
import { Alert } from '@material-ui/lab';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import './CustomAlert.css'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cookieAlert: {
    "&  .MuiAlert-message": {
      textAlign: 'center',
      justifyContent: 'center',
      marginLeft: 'calc(50% - 215px/2 + 0.5px)'
    },
    "& .MuiAlert-icon	": {
      display: 'none'
    }
  },
  AlertMsg: {
    "&  .MuiAlert-message": {
      textAlign: 'center',
      justifyContent: 'center',
    },
    "& .MuiAlert-icon	": {
      display: 'none'
    }
  }
});


function CustomAlert({ type, msg, handleClose }) {
  const classes = useStyles();
  return (
    <Alert
      severity={type}
      variant="filled"
      className={handleClose ? classes.cookieAlert : classes.AlertMsg}
      onClose={handleClose}
    >
      {msg || 'Oops something went wrong!!'}
    </Alert>
  )
}

const mapStateToProps = (state) => ({});


export default withRouter(connect(mapStateToProps, {})(CustomAlert));