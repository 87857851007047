import React, { useEffect, useState, Fragment } from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./users.css";
import { Close as CloseIcon } from "@material-ui/icons/";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogBox from "../../DialogBox";
import {
  fetchUserListAPI,
  deleteUserAPI,
  resendInvite
} from "../../../actions/Users/authenticate";
import CustomAlert from "../../CustomAlert";
import _ from "lodash";
import TopHeader from "../../TopHeader";
import SideMenu from "../../SideMenu";
import CustomLoader from "../../Loader/CustomLoader";
import Pagination from "@material-ui/lab/Pagination";
// import Stack from '@material-ui/lab/Stack';
import Chip from '@mui/material/Chip';
import { Autocomplete, TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from "@mui/icons-material/Search";
import { FILTER_BY_LIST, PAGE_SIZE, ENTER_KEY_CODE } from "../../../constants/Constants";

const AccountStatus = {
  LOCKED : "LOCKED",
  PENDING_EV : "PENDING_EV",
  PENDING_AP : "PENDING_AP",
  PENDING_SR : "PENDING_SR",
  DISABLED : "DISABLED",
  UNLOCKED : "UNLOCKED"
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function Users({ history, authReducer, fetchUserListAPI, deleteUserAPI, errorReducer, resendInvite, location }) {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [popUp, setPopUp] = useState(false);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resendInvite)) {
      setPopUp(true);
      authReducer.resendInvite = {};
    }
  }, [authReducer.resendInvite]);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    doSearch();
  }, []);

  const paginationChanged = (val, page) => {
    fetchUserListAPI(page, PAGE_SIZE, filterBy, search);
  };

  useEffect(() => {
    var rows = [];
    if (!_.isEmpty(authReducer.usersList.data)) {
      let data = authReducer.usersList.data;
      setPage(data.page);
      setPages(data.pages);
      setTotal(data.total);
      for (let i = 0; i < data.records.length; i++) {
        if (data.records[i].hasOwnProperty("emails") && data.records[i].emails == "admin@wso2.com") {
          data.records.datasplice(i, 1);
        }
      }

      var theUserList = [...data.records];
      if (theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !=
              null &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !== ""
              ? (theUserList[i].name.givenName
                ? theUserList[i].name.givenName
                : "") +
              " " +
              (theUserList[i].name.familyName
                ? theUserList[i].name.familyName
                : "")
              : "-";
          let u_orgName =
            theUserList[i].hasOwnProperty("orgName") &&
              theUserList[i].orgName != null &&
              theUserList[i].orgName !== ""
              ? theUserList[i].orgName
              : "-";
          let u_email =
            theUserList[i].hasOwnProperty("emails") &&
              theUserList[i].emails != null &&
              theUserList[i].emails !== ""
              ? theUserList[i].emails[0]
              : "-";
          let u_mobile =
            theUserList[i].hasOwnProperty("phoneNumbers") &&
              theUserList[i].phoneNumbers[0] != null
              ? theUserList[i].phoneNumbers[0].value
              : "-";
          let u_status =
            theUserList[i].hasOwnProperty(
              "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
            ) ?
              (theUserList[i][
              "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
              ]["accountLocked"] == false
              &&
              (
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_EV ||
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_AP ||
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_SR
              ))
              ? AccountStatus.UNLOCKED
              :  theUserList[i][
                "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
                ]["accountState"]
              : "-";
          const _permissions = theUserList[i].permissions ? theUserList[i].permissions : [];
          rows.push(createData(u_name, u_email, u_mobile, u_orgName, u_status, _permissions));
        }
      }
    }
    setUserData(rows);
  }, [authReducer.usersList]);

  useEffect(() => {
    updateSelectedUsers([]);
  }, [authReducer.deleteUsersResponse]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    updateSelectedUsers([]);
  }, [authReducer.deleteUsersResponse]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.deleteUsersResponse)) {
      setShowDeleteAlert(false);
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.deleteUsersResponse = {};
    }
  }, [authReducer.deleteUsersResponse]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.errors && errorReducer.errors.length > 0 && errorReducer.errors[0].msg !== "") {
      setLoaders({ showLoader: false, loadingText: "" });
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function createData(name, email, mobile, orgName, status, permissions) {
    return { name, email, mobile, orgName, status, permissions: permissions };
  }

  const _handleUserClick = (index) => {
    let indexList = [...selectedUsers];
    if (
      authReducer.usersList != null &&
      authReducer.usersList.data.records.length > 0
    ) {
      var theUsers = [...authReducer.usersList.data.records];
      for (let i = 0; i < theUsers.length; i++) {
        if (index == i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item != i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
          }
        }
      }
    } else {
      return [];
    }
  };

  const confirmDelete = () => {
    setShowDeleteAlert(!showDeleteAlert);
  };

  const handleResendInvite = (user) => {
    let payload = {
      userName: user.email,
      ouName: user.orgName
    };
    resendInvite(payload);
  };

  const _handleDeleteUserClick = () => {
    if (
      authReducer.usersList != null &&
      authReducer.usersList.data.records.length > 0
    ) {
      if (selectedUsers.length > 0) {
        setLoaders({ showLoader: true, loadingText: "Deleting users please wait..." });
        var theUsers = [...authReducer.usersList.data.records];
        var selectedUsersEmails = [];
        for (let i = 0; i < theUsers.length; i++) {
          if (selectedUsers.includes(i)) {
            if (theUsers[i].hasOwnProperty("emails")) {
              selectedUsersEmails.push(theUsers[i].emails);
            }
          }
        }

        deleteUserAPI(selectedUsersEmails);
      } else {
        alert("Please select the users which you want to delete.");
      }
    } else {
      alert("Dummy users cannot be deleted.");
    }
  };

  const searchBoxKeyDown = (event) => {
    if (event && event.key === ENTER_KEY_CODE) {
      doSearch();
    }
  };

  const doSearch = () => {
    fetchUserListAPI(1, PAGE_SIZE, filterBy, search);
  }

  return (
    <div className={"nav-route-container"} style={{ overflow: "scroll", position: "fixed", width: "100%" }}>
      <TopHeader />
      {
        showAlert &&
          showAlert === true &&
          alertMsg.msg !== "You are not authorized to access this feature." ? (
          <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
        ) : null
      }
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu key="sideMenu" />
        </Grid>
        <Grid item xs={10} style={{ padding: "0px 10px 10px 10px" }}>
          <div style={{ backgroundColor: "#FFFFFF"}}>
            <div style={{ height: "50px", paddingLeft: "10px", alignItems: "center", display: "flex" }}>
              <Typography
                style={{
                  fontSize: "19px",
                  color: "#008FBD",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >Users</Typography>
            </div>
            <div className="horizontal-line"></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <Grid container style={{ marginTop: 10 }}>
                <Grid container item>
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingLeft: "10px", alignItems: "start" }}>

                    <Autocomplete
                      id="filterBy"
                      label="Filter By"
                      options={FILTER_BY_LIST}
                      className="filterBy"
                      sx={{ width: 200, marginTop: 0, display: "inline-block", marginRight: "10px" }}
                      style={{ border: "#CFD0DD" }}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setFilterBy(newValue ? newValue.value : "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter By" style={{ padding: "0px" }} />
                      )}
                    />
                    <TextField
                      id="search"
                      className="search"
                      label="Search"
                      variant="outlined"
                      placeholder="Type something"
                      size="small"
                      sx={{ paddingLeft: "0px", border: "0 #fff !important", display: "inline-block" }}
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      onKeyDown={(event) => searchBoxKeyDown(event)}
                      InputLabelProps={{ className: "serachLabel" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ position: "absolute", right: "5px", border: '0px' }}
                              className="cursorPointer"
                              onClick={doSearch}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ alignItems: "end" }}>
                    <Button style={{ marginLeft: "10px" }} className="add-user-button" onClick={() => history.push("/invite-user")}>Add</Button>
                    <Button style={{ backgroundColor: "white", color: "#008fbd", marginLeft: "10px" }}
                      disabled={selectedUsers.length === 0}
                      className="delete-user-button"
                      onClick={() => { confirmDelete(); }}>Delete</Button>
                  </Grid>
                </Grid>
                <Grid container item style={{ margin: "10px" }}>
                  {userData && userData.length != 0 && authReducer.loading === false ? (
                    [
                      <TableContainer component={Paper} style={{ marginRight: "20px" }}>
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}>Name</TableCell>
                              <TableCell align="left" className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}>Email</TableCell>
                              <TableCell className="tableBorderRightH headerCell" align="center" style={{ minWidth: "200px" }}>Contacts</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}>Organization Name</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell">Permissions</TableCell>
                              <TableCell align="center" className="tableBorderRightH headerCell" style={{ minWidth: "100px" }}>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userData &&
                              userData.length != 0 &&
                              userData.map((row, i) => (
                                <TableRow
                                  key={row.name + i}
                                  style={{ height: "40px" }}
                                  hover
                                >
                                  <TableCell
                                    className="tableBorderRightH"
                                    align="left"
                                    style={{ color: "#384089" }}
                                  >
                                    <Checkbox
                                      onClick={() => {
                                        _handleUserClick(i);
                                      }}
                                      role="checkbox"
                                      classes={{
                                        root: classes.CheckBoxroot,
                                        checked: classes.checked,
                                      }}
                                      checked={selectedUsers.includes(i)}
                                    />
                                    <span
                                      onClick={() =>
                                        history.push({
                                          pathname: `/dashboard/users/policy/${row.email}`,
                                          state: {
                                            demo: row.name,
                                            demo1: row.email,
                                            ouName: location.state.demo
                                          },
                                        })
                                      }
                                      style={{
                                        marginLeft: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {row.name}
                                    </span>
                                  </TableCell>
                                  <TableCell className="tableBorderRightH">{row.email}</TableCell>
                                  <TableCell className="tableBorderRightH">{row.mobile}</TableCell>
                                  <TableCell className="tableBorderRightH">{row.orgName}</TableCell>
                                  <TableCell className="tableBorderRightH">
                                    {row.permissions.map((policy) => (<Chip label={policy.name} variant="outlined" style={{ margin: "2px" }} />))}
                                  </TableCell>
                                  <TableCell className="tableBorderRightH" align="center">
                                    {row.status == AccountStatus.PENDING_EV ? (
                                      <div>
                                        <Button
                                          style={{
                                            backgroundColor: "white",
                                            color: "#008fbd",
                                            border: "#ffffff",
                                            minHeight: "15px",
                                            fontSize: "0.875rem",
                                            paddingLeft: "0",
                                          }}
                                          content="Resend Invite"
                                          onClick={() =>
                                            handleResendInvite(row)
                                          }
                                        >
                                          Resend Invite
                                        </Button>
                                      </div>
                                    ) : row.status === AccountStatus.UNLOCKED ? ("Confirmed") : row.status === AccountStatus.LOCKED ? ("Locked") : row.status === AccountStatus.DISABLED ? ("Disabled") :("-")}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>,
                      <div style={{ marginTop: "15px" }}>
                        <Pagination count={pages} page={page} onChange={paginationChanged} shape="rounded" />
                      </div>
                    ]
                  ) : (
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No user(s) found
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Deleted"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <DialogBox
        Open={popUp}
        onClose={() => setPopUp(false)}
        DialogTitle="Invitation Sent successfully"
        ButtonLabel="Done"
        onClick={refreshPage}
      />

      <Dialog
        open={showDeleteAlert}
        TransitionComponent={Transition}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              Please confirm to delete selected users.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "right", paddingRight: "10px" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="secondayButton"
                title="Cancel"
                onClick={confirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  _handleDeleteUserClick();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching users please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchUserListAPI,
    deleteUserAPI,
    resendInvite
  })(Users)
);
