import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../../Components/CustomAlert";
import _ from "lodash";
import "../OuUser.css";
import { getOUPolicies } from "../../../../actions/Users/authenticate";

function OuSummary({ authReducer, errorReducer, location, getOUPolicies }) {
  const [showAlert, setShowAlert] = useState(false);

  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });

  const [summary, setSummary] = useState(null);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });


  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    getOUPolicies(location.state.demo);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.getOUPolicies) && authReducer.getOUPolicies.data) {
      setSummary(authReducer.getOUPolicies.data.permissions);
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [authReducer.getOUPolicies]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  return (
    <div>
      {
        authReducer.loading ? (
          <CustomLoader
            showLoader={true}
            loadingText={
              loaders["loadingText"]
                ? loaders["loadingText"]
                : "Fetching organization details please wait..."
            }
          />
        ) : null
      }

      <div style={{ marginLeft: "1.5px" }} className="horizontal-line"></div>
      {
        alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
          <div>
            {summary && authReducer.loading === false ? (
              <pre>
                {JSON.stringify(summary, null, 2)}
              </pre>
            ) : ("")}
          </div>
        ) : (
          <Grid
            container
            style={{
              alignItems: "center",
              display: "flex",
              fontSize: "22px",
              // fontWeight: "600",
              marginTop: "15%",
              backgroundColor: "white",
              padding: "10px",
              flexDirection: "column",
              color: "rgb(245 144 144)",
            }}
          >
            You are not authorized to access this feature.
          </Grid>
        )
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    getOUPolicies
  })(OuSummary)
);
