import React, { useState, useEffect } from "react";
import {
  Grid, Paper, Button, Slide, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import Chip from '@mui/material/Chip';
import Scroll from "react-scroll";
import CustomButton from "../../../../Components/CustomButton";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogBox from "../../../../Components/DialogBox";
import _ from "lodash";
import "../OuUser.css";
import { organizationuserUser, organizationuserDetach, clearUserPermissionCache, resendInvite } from "../../../../actions/Users/authenticate";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from '@mui/material/Pagination';
import { Autocomplete, TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from "@mui/icons-material/Search";
import { FILTER_BY_LIST, PAGE_SIZE, ENTER_KEY_CODE } from "../../../../constants/Constants";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

const AccountStatus = {
  LOCKED : "LOCKED",
  PENDING_EV : "PENDING_EV",
  PENDING_AP : "PENDING_AP",
  PENDING_SR : "PENDING_SR",
  DISABLED : "DISABLED",
  UNLOCKED : "UNLOCKED"
};

function OuUser({
  history, authReducer, errorReducer, organizationuserUser, location,
  organizationuserDetach, clearUserPermissionCache, resendInvite
}) {
  const classes = useStyles();
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showClearPermissionCachePopUp, setShowClearPermissionCachePopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [userApi, setUserApi] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resendInvite)) {
      setPopUp(true);
      authReducer.resendInvite = {};
      setLoaders({ showLoader: false, loadingText: "" });
    }
  }, [authReducer.resendInvite]);

  useEffect(() => {
    doSearch();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.organizationuserUser) && authReducer.organizationuserUser.data) {
      setUsers(_getUsers());
      setPage(authReducer.organizationuserUser.data.page);
      setPages(authReducer.organizationuserUser.data.pages);
      setTotal(authReducer.organizationuserUser.data.total);
      setLoaders({ showLoader: false, loadingText: "" });
    }
    authReducer.organizationuserUser = {};
  }, [authReducer.organizationuserUser]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.organizationuserDetach)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
    }
    authReducer.organizationuserDetach = {};
  }, [authReducer.organizationuserDetach]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.clearUserPermissionCache)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowClearPermissionCachePopUp(true);
    }
    authReducer.clearUserPermissionCache = {};
  }, [authReducer.clearUserPermissionCache]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function refreshPage() {
    window.location.reload(false);
  }

  const handleDetachUser = () => {
    if (
      authReducer.organizationuserUser.data &&
      authReducer.organizationuserUser.data.records.length > 0
    ) {
      if (selectedUsers.length > 0) {
        var theUserList = [...authReducer.organizationuserUser.data.records];
        let finalPayload = [];
        let payload = { ouName: location.state.demo, userNames: [] };
        for (let i = 0; i < theUserList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (theUserList[i].hasOwnProperty("emails")) {
              payload.userNames.push(theUserList[i].emails[0]);
            }
          }
        }
        finalPayload.push(payload);
        organizationuserDetach(payload);
        setLoaders({ showLoader: true, loadingText: "Detaching the user please wait..." });
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  const handleClearUserPermissionCache = () => {
    if (selectedUsers.length > 0) {
      var theUserList = [...authReducer.organizationuserUser.data.records];
      let finalPayload = [];
      let payload = { ouName: location.state.demo, userNames: [] };
      for (let i = 0; i < theUserList.length; i++) {
        if (selectedUsers.includes(i)) {
          if (theUserList[i].hasOwnProperty("emails")) {
            payload.userNames.push(theUserList[i].emails[0]);
          }
        }
      }
      finalPayload.push(payload);
      clearUserPermissionCache(payload);
      setLoaders({ showLoader: true, loadingText: "Clearing user permission cache please wait..." });
    } else {
      alert("Please select the users which you want to clear permission cache.");
    }
  }

  const _handleSelectUser = (index) => {
    let indexList = [...selectedUsers];
    if (authReducer.organizationuserUser.data && authReducer.organizationuserUser.data.records.length > 0) {
      var theUsers = [...authReducer.organizationuserUser.data.records];
      for (let i = 0; i < theUsers.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
          }
        }
      }
    } else {
      return [];
    }
  };

  function createData(name, email, mobile, orgName, status, permissions) {
    return { name, email, mobile, orgName, status, permissions };
  }

  const handlePageChange = (event, page) => {
    setLoaders({ showLoader: true, loadingText: "Loading organisation users." });
    organizationuserUser(location.state.demo, page, PAGE_SIZE, filterBy, search);
    setUserApi(true);
  }

  const _getUsers = () => {
    var rows = [];

    if (authReducer.organizationuserUser != null) {
      var theUserList = authReducer.organizationuserUser.data.records;
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !=
              null &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !== ""
              ? (theUserList[i].name.givenName
                ? theUserList[i].name.givenName
                : "") +
              " " +
              (theUserList[i].name.familyName
                ? theUserList[i].name.familyName
                : "")
              : "-";
          let u_orgName =
            theUserList[i].hasOwnProperty("orgName") &&
              theUserList[i].orgName != null &&
              theUserList[i].orgName !== ""
              ? theUserList[i].orgName
              : "-";
          let u_email =
            theUserList[i].hasOwnProperty("emails") &&
              theUserList[i].emails != null &&
              theUserList[i].emails !== ""
              ? theUserList[i].emails[0]
              : "-";
          let u_mobile =
            theUserList[i].hasOwnProperty("phoneNumbers") &&
              theUserList[i].phoneNumbers[0] != null
              ? theUserList[i].phoneNumbers[0].value
              : "-";
          let u_status =
            theUserList[i].hasOwnProperty(
              "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
            ) ?
              (theUserList[i][
              "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
              ]["accountLocked"] == false
              &&
              (
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_EV ||
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_AP ||
                theUserList[i]["urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"]["accountState"] == AccountStatus.PENDING_SR
              ))
              ? AccountStatus.UNLOCKED
              :  theUserList[i][
                "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
                ]["accountState"]
              : "-";
          let permissions = [];
          if (theUserList[i].permissions && Array.isArray(theUserList[i].permissions)) {
            permissions = theUserList[i].permissions;
          }
          rows.push(createData(u_name, u_email, u_mobile, u_orgName, u_status, permissions));
        }
      }
    }
    return rows;
  };

  const handleResendInvite = (user) => {
    let payload = {
      ouName: location.state.demo,
      userName: user.email,
    };
    resendInvite(payload);
  };

  const searchBoxKeyDown = (event) => {
    if (event && event.key === ENTER_KEY_CODE) {
      doSearch();
    }
  };

  const doSearch = () => {
    setLoaders({ showLoader: true, loadingText: "Loading organisation users." });
    organizationuserUser(location.state.demo, 1, PAGE_SIZE, filterBy, search);
  }

  return (
    <div style={{ width: "100%" }}>
      <Grid item container xs={12}>
        <Grid item style={{ width: "100%" }}>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <Grid container style={{ marginTop: 10 }}>
                <Grid item container>
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ alignItems: "start" }}>

                    <Autocomplete
                      id="filterBy"
                      label="Filter By"
                      options={FILTER_BY_LIST}
                      className="filterBy"
                      sx={{ width: 200, marginTop: 0, display: "inline-block", marginRight: "10px" }}
                      style={{ border: "#CFD0DD" }}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setFilterBy(newValue && newValue.value ? newValue.value : "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Filter By" style={{ padding: "0px" }} />
                      )}
                    />
                    <TextField
                      id="search"
                      className="search"
                      label="Search"
                      variant="outlined"
                      placeholder="Type something"
                      size="small"
                      sx={{ paddingLeft: "0px", border: "0 #fff !important", display: "inline-block" }}
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                      onKeyDown={(event) => searchBoxKeyDown(event)}
                      InputLabelProps={{ className: "serachLabel" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ position: "absolute", right: "5px", border: '0px' }}
                              className="cursorPointer"
                              onClick={doSearch}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ alignItems: "end", textAlign: "right" }}>
                    <Button onClick={() => {
                      history.push({
                        pathname:
                          "/invite-user",
                        state: { demo: location.state.demo },
                      });
                    }}
                      title="Invite user to organisation" style={{ marginLeft: "10px" }}
                    >Invite</Button>
                    <Button onClick={() => {
                      history.push({
                        pathname:
                          "/dashboard/OU/user-account/attach-user",
                        state: { demo: location.state.demo },
                      });
                    }}
                      title="Attach user to organisation" style={{ marginLeft: "10px" }}
                    >Attach</Button>
                    <Button disabled={selectedUsers.length === 0} style={{ marginLeft: "10px" }}
                      onClick={handleDetachUser}
                      title="Detach user from organisation"
                    >Detach</Button>

                    <Button disabled={selectedUsers.length === 0} style={{ marginLeft: "10px" }}
                      onClick={handleClearUserPermissionCache}
                      title="Clear permission cache"
                    >Clear cache</Button>
                  </Grid>
                </Grid>
                <div className="container" style={{ backgroundColor: "white", width: '100%', overflow: 'scroll' }}>
                  <div className="row" style={{ padding: "10px 1px 0px 1px" }}>
                    <TableContainer component={Paper}>
                      <Table aria-label="User list">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow>
                            <TableCell className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}> Name </TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}> Email </TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell" style={{ minWidth: "250px" }}> Permissions </TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell" style={{ minWidth: "100px" }}> Status </TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell" style={{ minWidth: "200px" }}> Contact </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(searchResult.length ? searchResult : users).map((row, i) => (
                            <TableRow key={row.name + i} hover>
                              <TableCell className="tableBorderRightH" align="left" style={{ color: "#384089" }}>
                                <Checkbox
                                  onClick={() => { _handleSelectUser(i); }}
                                  role="checkbox"
                                  classes={{
                                    root: classes.CheckBoxroot,
                                    checked: classes.checked,
                                  }}
                                  checked={selectedUsers.includes(i)}
                                />

                                <span
                                  onClick={() =>
                                    history.push({
                                      pathname: `/dashboard/users/policy/${row.email}`,
                                      state: {
                                        demo: row.name,
                                        demo1: row.email,
                                      },
                                    })
                                  }
                                  style={{ marginLeft: "8px", cursor: "pointer" }}
                                >
                                  {row.name}
                                </span>
                              </TableCell>
                              <TableCell className="tableBorderRightH" align="left">{row.email}</TableCell>
                              <TableCell className="tableBorderRightH" align="left">
                                {row.permissions.map((policy) => (<Chip label={policy.name} variant="outlined" style={{ margin: "2px" }} />))}
                              </TableCell>

                              <TableCell className="tableBorderRightH" align="center" >
                                {row.status == AccountStatus.PENDING_EV ? (
                                  <div>
                                    <Button
                                      style={{
                                        backgroundColor: "white",
                                        color: "#008fbd",
                                        border: "#fff",
                                        minHeight: "15px",
                                        fontSize: "0.875rem",
                                        paddingLeft: "0",
                                      }}
                                      content="Resend Invite"
                                      onClick={() =>
                                        handleResendInvite(row)
                                      }
                                    >
                                      Resend Invite
                                    </Button>
                                  </div>
                                ) : row.status === AccountStatus.UNLOCKED ? ("Confirmed") : row.status === AccountStatus.LOCKED ? ("Locked") : row.status === AccountStatus.DISABLED ? ("Disabled") :("-")}
                              </TableCell>
                              <TableCell className="tableBorderRightH" align="left">{row.mobile}</TableCell>
                            </TableRow>
                          ))}
                          {false === authReducer.loading && 0 === users.length ? (
                            <TableRow>
                              <TableCell align="center" className="tableBorderRightH headerCell" colSpan={5}> No search user(s) found </TableCell>
                            </TableRow>) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {false === authReducer.loading && 0 !== users.length ? (
                      <Pagination count={pages} page={page} onChange={handlePageChange} />
                    ) : null}
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Detached"
        ButtonLabel="Done"
        onClick={refreshPage}
      />

      <DialogBox
        Open={showClearPermissionCachePopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User permission cache cleared successfully"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organization details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    organizationuserUser,
    organizationuserDetach,
    clearUserPermissionCache,
    resendInvite
  })(OuUser)
);
