import React, { useState, useEffect } from "react";
import { Grid, Button, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import CustomAlert from "../../Components/CustomAlert";
import { resetPassword } from "../../actions/Users/authenticate";
import DialogBox from "../../Components/DialogBox";
import _ from "lodash";
import "./resetPassword.css";
import useStyles from "../Registration/style";
import { Typography } from "../../Components/wrappers/Wrappers";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { Base64 } from "js-base64";
import Box from "@mui/material/Box";
import k4Logo from "../../assets/images/k4Logo.png";
import k4LogoMobileView from "../../assets/images/k4LogoMobileView.png";
import TextField from "@mui/material/TextField";
import { SC_REDIRECT_URL } from "../../config";

function ResetPassword({ history, authReducer, errorReducer, resetPassword }) {
  const { confirmCode } = useParams();
  const [userName, setUserName] = useState("");
  const [oldPassword, setOldPassword] = useState(confirmCode);
  const [newPassword, setNewPassword] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [alertMsg, setAlertMsg] = useState({msg: null, type: "error"});
  const [notErrorInFields, setNotErrorInFields] = useState({newPassword: true, reEnterPassword: true});
  let [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  let [loaders, setLoaders] = useState({showLoader: false, loadingText: ""});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkAllFieldsValidation = (notErrorInFields) => {
    let isExist = 0;
    Object.keys(notErrorInFields).map((keysName) => {
      if (notErrorInFields[keysName] === false) {
        isExist = 1;
        setAllFieldsIsValidORnot(notErrorInFields[keysName]);
      }});
    if (0 === isExist) setAllFieldsIsValidORnot(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setFullLoader(false);
      setError({ message: errorReducer.description, status: true });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resetPassword)) {
      setFullLoader(false);
      setShowPopUp(true);
      authReducer.resetPassword = {};
    }
  }, [authReducer.resetPassword]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "oldPassword") {
      setOldPassword(event.target.value);
    }
    if (name === "newPassword") {
      setNewPassword(event.target.value);
    }
    if (name === "reEnterPassword") {
      checkConfirmPassword(event.target.value);
      setReEnterPassword(event.target.value);
    }
    if (name === "userName") {
      setUserName(event.target.value);
    }
  };

  const handleResetPassword = () => {
    let payload = {
      key: oldPassword,
      newPassword: Base64.encode(newPassword),
    };
    resetPassword(payload);
    setFullLoader(true);
  };

  const checkConfirmPassword = (password) => {
    notErrorInFields["reEnterPassword"] =
      newPassword === password ? true : false;
    setNotErrorInFields(notErrorInFields);
    checkAllFieldsValidation(notErrorInFields);
  };

  return (
    <Grid container component="main" className={classes.root}>
      {showAlert && showAlert === true ? (<CustomAlert type={alertMsg.type} msg={alertMsg.msg} />) : null}
      <Grid item container xs={12} style={{ height: "100%" }}>
        <Box display={{xs: "none", sm: "contents", md: "contents", lg: "contents"}}>
          <Grid xs={0} sm={6} md={6} lg={6} className={classes.logoContainer}><img src={k4Logo} style={{ width: "50%" }}/></Grid>
        </Box>
        <Grid xs={12} sm={6} md={6} lg={6} className={classes.textContainer}>
          <Box display={{xs: "none", sm: "contents", md: "contents", lg: "contents"}}>
            <Typography className="textCaption"> Reset Password </Typography>
          </Box>
          <Box display={{ xs: "contents", sm: "none", md: "none", lg: "none" }}>
            <Grid> <img src={k4LogoMobileView} style={{ width: "100%", marginTop: "-20px", height: "150px" }}/>
              <Grid className="loginMobileTextCaption"> Reset Password</Grid>
            </Grid>
          </Box>
          <Grid xs={10}>
            <Box sx={{"& .MuiTextField-root": {m: 1, width: "100%", marginTop: "30px"}}} className="TextBoxMobileView NewPassword">
              <TextField
                id="outlined-basic" label="Password*" variant="outlined" placeholder="Password"
                size="small" value={newPassword} name="newPassword" onChange={handleChange} className="visibilityButton" 
                type={showPassword ? "text" : "password"} InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{ className: "labelTextbox",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid xs={10}>
            <Box sx={{"& .MuiTextField-root": {m: 1, width: "100%", marginTop: "30px"}}} className="TextBoxMobileView NewPassword">
              <TextField
                id="outlined-basic" label="Confirm Password*" variant="outlined" placeholder="Confirm Password"
                size="small" className="visibilityButton" value={reEnterPassword} type={showConfirmPassword ? "text" : "password"}
                name="reEnterPassword" onChange={handleChange} InputLabelProps={{ className: "UserNameInput" }}
                InputProps={{ className: "labelTextbox",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                        {showConfirmPassword ? ( <Visibility /> ) : ( <VisibilityOff />)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          {notErrorInFields["reEnterPassword"] === false ? (
            <div style={{fontSize: "12px", paddingTop: "1px", color: "rgb(255, 0, 0)", paddingLeft: "32px"}}> Password and confirm password didn’t match </div>
          ) : null}
          <Grid>
            <Button className="confirmButtonReset" color="primary" onClick={handleResetPassword} title="Reset password"
              disabled={newPassword.length === 0 || reEnterPassword.length === 0 || notErrorInFields["reEnterPassword"] === false}> Reset </Button>
          </Grid>
        </Grid>
      </Grid>
      <DialogBox Open={showPopUp} onClose={() => setShowPopUp(false)} DialogTitle="Password Reset Successfully"
        ButtonLabel="Done" onClick={() => {window.location = SC_REDIRECT_URL;/*history.push("/")*/}}/>
      {loaders["showLoader"] ? (
        <CustomLoader showLoader={loaders["showLoader"]} loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."}/>
      ) : null}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    resetPassword,
  })(ResetPassword)
);