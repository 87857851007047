export const DASHBOARD_ID = 1;
export const ORGANIZATIONAL_UNITS_ID = 2;
export const SERVICES_ID = 3;
export const USERS_ID = 4;
export const GROUPS_ID = 5;
export const RESOURCES_ID = 6;
export const POLICIES_ID = 7;
export const USER_ACCOUNTS = 8;
export const SETTINGS = 9;
export const SERVICE_CONTROL_POLICIES = 10;

export const FONT_FAMILY = "NunitoSans";
export const SNACK_BAR_TIMEOUT = 4000;
export const PAGE_SIZE = 100;
export const ENTER_KEY_CODE = "Enter";

export const FILTER_BY_LIST = [
    { label: "Select Filter By", value: "" },
    { label: "First Name", value: "givenName" },
    { label: "Last Name", value: "familyName" },
    { label: "Mobile", value: "mobile" },
    { label: "Username", value: "userName" },
];