import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import RootRouter from './routes/rootRouter';

import store from './redux/store';


function App({history}) {


  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <RootRouter></RootRouter>
        </div>
      </Router>
    </Provider>
  );
}

export default App;