import React, { useState, useEffect } from "react";
import {useDispatch} from 'react-redux'
import {
  Grid, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Button,
  Dialog, DialogContent, DialogContentText, DialogTitle,
  Slide
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons/";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomButton from "../../../../Components/CustomButton";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import DialogBox from "../../../../Components/DialogBox";
import { displayOUPermissions, dettachOuPermissions } from "../../../../actions/Users/authenticate";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import "../OuUser.css";
import {DETTACH_OU_PERMISSIONS} from "./../../../../actions/types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function OuPermissions({ history, authReducer, errorReducer, location, displayOUPermissions, dettachOuPermissions }) {
  const [dettachOuPermissionPopUp, setDettachOuPermissionPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [ouPermissionsData, setOuPermissionsData] = useState([]);
  const [removePermisionConfirm, setRemovePermisionConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    loadPermissions();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.displayOUPermissions.data)) {
      setOuPermissionsData(authReducer.displayOUPermissions.data);
      setLoaders({ showLoader: false, loadingText: "" });
    }
    else{
      setOuPermissionsData([]);
    }
  }, [authReducer.displayOUPermissions]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.dettachOuPermissions)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setDettachOuPermissionPopUp(true);
      //authReducer.dettachOuPermissions = {};
      dispatch({
        type: DETTACH_OU_PERMISSIONS,
        payload: {}
      });
    }
  }, [authReducer.dettachOuPermissions]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const _handleRemovePermission = () => {
    let item = selectedItem;
    setRemovePermisionConfirm(false);
    let payload = {
      name: location.state.demo,
      permissions: [item.name]
    };
    dettachOuPermissions(payload);
    setLoaders({ showLoader: true, loadingText: "Dettaching permission..." });
  }

  const _dettachOuPermissions = (item) => {
    setSelectedItem(item);
    setRemovePermisionConfirm(true);
  }

  const cancelRemovePermission = () => {
    setRemovePermisionConfirm(false);
  }

  const loadPermissions = () => {
    setDettachOuPermissionPopUp(false);
    displayOUPermissions(location.state.demo);
  }

  return (
    <div>
      <div>
        <Grid
          container
          item
          style={{ marginTop: "10px", marginLeft: "15px" }}
        >
          <CustomButton
            style={{ align: 'right' }}
            ButtonType=""
            ButtonLabel="Add Permissions"
            onClick={() =>
              history.push({
                pathname: "/dashboard/ou/attach-permissions",
                state: {
                  demo: location.state.demo,
                  demo1: location.state.demo1,
                },
              })
            }
          />
        </Grid>
        <div
          className="container"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="row"
            style={{
              marginTop: "10px",
              padding: "10px",
              marginLeft: "4px",
              marginRight: "15px",
              // width: "678px",
            }}
          >
            <TableContainer component={Paper} style={{ width: "100%" }}>
              <Table aria-label="simple table" size="small">
                <TableHead style={{ background: "#ecf5ff" }}>
                  <TableRow style={{ height: "40px" }}>
                    <TableCell
                      className="tableBorderRightH headerCell"
                      style={{ width: "50%" }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="left"
                      className="tableBorderRightH headerCell"
                    >
                      Description
                    </TableCell>
                    <TableCell align="left" className="tableBorderRightH headerCell">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ouPermissionsData.map((row, i) => (
                    <TableRow
                      key={row.name + i}
                      style={{ height: "40px" }}
                    >
                      <TableCell className="tableBorderRightH" align="left">{row.name}</TableCell>
                      <TableCell className="tableBorderRightH" align="left">{row.description}</TableCell>
                      <TableCell className="tableBorderRightH" align="left">
                        <CustomButton
                          ButtonType=""
                          ButtonLabel="Remove"
                          onClick={() =>
                            _dettachOuPermissions(row)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {
                    authReducer.loading === false && 0 === ouPermissionsData.length ? (
                      <TableRow style={{ height: "40px" }}>
                        <TableCell className="tableBorderRightH" align="left">No organisation permissions found.</TableCell>
                      </TableRow>
                    ) : null
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <DialogBox
        Open={dettachOuPermissionPopUp}
        onClose={() => setDettachOuPermissionPopUp(false)}
        DialogTitle="Organisation permission dettached successfully."
        ButtonLabel="Done"
        onClick={loadPermissions}
      />

      <Dialog
        open={removePermisionConfirm}
        TransitionComponent={Transition}
        onClose={cancelRemovePermission}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle className={classes.rootDialog} style={{ background: "#384089" }} id="alert-dialog-slide-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className={"heading"}>Remove organisation permission</h3>
              <div
                onClick={cancelRemovePermission}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "left",
                margin: "25px"
              }}
            >
              Are you sure you want to remove permission from organisation.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "right", marginRight: 10 }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                style={{ marginRight: 5 }}
                onClick={cancelRemovePermission}
              >
                No
              </Button>
            </div>

            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Confirm to remove permission."
                onClick={(e) => {
                  _handleRemovePermission();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>

      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organization details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    displayOUPermissions,
    dettachOuPermissions
  })(OuPermissions)
);
