import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,Grid
} from "@material-ui/core";
import _ from "lodash";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { ReactComponent as K4Logo } from "../../assets/images/K4Logo.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../actions/Users/authenticate";
import "./topHeader.css";
import JWT from "jsonwebtoken";
import { REDIRECT_URL } from "../../../src/config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: '4px',
    margin: '13px',
    padding: '0px 10px 0px 0px'
  },
  title: {
    flexGrow: 1,
    color: "#2F3484",
    fontSize: "24px",
    lineHeight: "29px",
    fontWeight: "bold",
    fontFamily: "Calibri",
    marginLeft: '10px'
  },
  IAMtitle: {
    flexGrow: 1,
    color: "#2F3484",
    fontSize: "24px",
    lineHeight: "40px",
    fontWeight: "bold",
    fontFamily: "Calibri",
    marginLeft: '0px'
  }
}));

function TopHeader({ history, logoutUser }) {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    
    let logoutPayload = {
      idToken: JSON.parse(localStorage.getItem('loggedInUser')).data.id_token,
      redirectUrl: REDIRECT_URL,
      loginId: JSON.parse(localStorage.getItem('loggedInUser')).data.login_id,
    }
    
    logoutUser(logoutPayload);
    localStorage.setItem('loggedInEmail','');
    localStorage.setItem('loggedInUpdateEmail','');
    localStorage.setItem('loggedInUser','');
    localStorage.setItem('Refresh Token','');
    localStorage.clear();
    history.push('/');
    window.location.reload();

  }

  const getDetail = () => {
    const data=JSON.parse(localStorage.getItem("loggedInUser"));
    
    if(data && data.data && data.data.id_token){
      var JWTUser = JWT.decode(data.data.id_token);
    
      setUserName(JWTUser.sub);
      localStorage.setItem('loggedInEmail', JSON.stringify(userName));
      localStorage.setItem('loggedInUpdateEmail', JSON.stringify(JWTUser));
    }

    if(data && data.data && data.data.user && data.data.user.name) {
      setDisplayName(`${data.data.user.name.givenName} ${data.data.user.name.familyName}`)
    }
  }

  useEffect(() => {
    getDetail();
  })

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className="menuButton"
            color="inherit"
            aria-label="menu"
            style={{height:"45px"}}
          >
          <K4Logo style={{height:"45px"}} onClick={() => history.push("/dashboard")} />
          <Typography style={{height:"45px", fontSize:"20px"}} variant="h6" className={classes.IAMtitle} onClick={() => history.push("/dashboard")} >
              IAM
          </Typography>
          </IconButton>

          <Typography variant="h6" className={classes.title}>
          </Typography>

          <Grid item>
              <Typography
                  className={classes.title}
                  name='userName' value={userName} onChange={handleChange}
                  style={{
                      fontSize: "18px", paddingRight:"10px", color: "#FFF"
                  }}
              >
                  {"Hi, " + displayName }
              </Typography>
          </Grid>

          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="#FFF"
                size="medium"
                style={{padding:"0px"}}
                title="Profile Settings"
              >
                <AccountCircle style={{color:"#FFF", fontSize:"50px"}} />
              </IconButton>
              
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                style={{ top: "44px" }}
              >
                <MenuItem onClick={() => history.push("/update-user")}>
                  Update User
                </MenuItem>
                <MenuItem onClick={() => history.push("/change-password")}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout} >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar> 
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    logoutUser
    //All functions defined in action will come here
  })(TopHeader)
);

