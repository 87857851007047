import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Router, Route, Switch } from "react-router-dom";
import InviteUser from "../UserScreens/InviteUser";
import AddUser from "../UserScreens/Registration";
import LoginUser from "../UserScreens/Login";
import Login from "../UserScreens/IamLogin"
// import Dashboard from "../UserScreens/Dashboard";
import IamDashboard from "../UserScreens/NewLogin";
import ForgotPassword from "../UserScreens/ForgotPassword";
import ResetPassword from "../UserScreens/ResetPassword";
import UpdateUser from "../UserScreens/UpdateUser";
import Policies from "../UserScreens/Policies";
import CreatePolicy from "../UserScreens/Policies/CreatePolicy";
import userAccount from "../UserScreens/OU/userAccount";
import CreateNew from "../UserScreens/OU/createNew";
import Rename from "../UserScreens/OU/Rename";
import Users from "../Components/Dashboard/Users";
import OuUser from "../UserScreens/OU/OuUser";
import ChangePassword from "../UserScreens/ChangePassword";
import Services from "../UserScreens/Services";
import AddServices from "../UserScreens/Services/AddServices";
import UpdateService from "../UserScreens/Services/UpdateService";
// import NewUpdate from "../UserScreens/Services/NewUpdate";
import AttachUserPolicy from "../UserScreens/Policies/AttachUserPolicy";
import DetchUserPolicy from "../UserScreens/Policies/DetchUserPolicy";
import UserPolicy from "../Components/Dashboard/Users/UserPolicy";
import PoliciesSummary from "../UserScreens/Policies/PoliciesSummary";
import AttachOUPolicy from "../UserScreens/OU/AttachOUPolicy";
import AttachOuPermission from "../UserScreens/OU/AttachOuPermission";
import AttachOuResource from "../UserScreens/OU/AttachOuResource";
import UpdatePolicy from "../UserScreens/Policies/UpdatePolicy";
import AttachPolicyUser from "../Components/Dashboard/Users/AttachPolicyUser";
import RemovePolicyUser from "../Components/Dashboard/Users/RemovePolicyUser";
import AttachUser from "../UserScreens/OU/AttachUser";
import RemoveOUPolicy from "../UserScreens/OU/RemoveOUPolicy";
import NewLogin from "../UserScreens/IamLogin";
import Groups from "../UserScreens/Groups";
import SummaryGroups from "../UserScreens/Groups/SummaryGroups";
import AddPermissionGroup from "../UserScreens/Groups/AddPermissionGroup";
import RemovePermissionGroup from "../UserScreens/Groups/RemovePermissionGroup";
import AttachUserGroup from "../UserScreens/Groups/AttachUserGroup";
import AttachServiceOu from "../UserScreens/Services/AttachServiceOu";
import UpdateOuService from "../UserScreens/Services/UpdateOuService";
import ServiceSummary from "../UserScreens/Services/ServiceSummary";
import Resources from '../UserScreens/Resources'
import AddResource from "../UserScreens/Resources/AddResource";
import UpdateResource from "../UserScreens/Resources/UpdateResource";
import ResourceSummary from "../UserScreens/Resources/ResourceSummary";

function rootRouter({ history }) {
  console.log = () => {};
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={IamDashboard} />
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        <Route exact path="/dashboard" component={userAccount} />
        {/* Right profile options */}
        <Route exact path="/add-user/:userNamePar/:passwordPar" component={AddUser} />
        <Route exact path="/add-user" component={AddUser} />
        <Route exact path="/invite-user" component={InviteUser} />
        <Route exact path="/login-user" component={LoginUser} />
        <Route exact path="/update-user" component={UpdateUser} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/IAM-login" component={NewLogin} />
        {/* Login page route */}
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:confirmCode" component={ResetPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route exact path="/change-password" component={ChangePassword} />
        {/* Under Users */}
        <Route exact path="/dashboard/users" component={Users} />
        <Route path="/dashboard/users/policy/:userName" component={UserPolicy} />
        <Route path="/dashboard/users/add-permission/:userName" component={AttachPolicyUser} />
        <Route path="/dashboard/users/Remove-permission/:name" component={RemovePolicyUser} />
        {/* Under Services */}
        <Route exact path="/dashboard/services" component={Services} />
        <Route exact path="/dashboard/services/add-service" component={AddServices} />
        <Route exact path="/dashboard/services/update-service" component={UpdateService} />
        {/* <Route exact path="/dashboard/services/new-update" component={NewUpdate} /> */}
        <Route exact path="/dashboard/services/view-service-summary" component={ServiceSummary} />
        {/* Under OU */}
        <Route exact path = "/dashboard/OU/user-account" component={userAccount} />
        <Route exact path = "/dashboard/OU/user-account/create-new" component={CreateNew} />
        <Route path = "/dashboard/OU/user-account/rename/:name" component={Rename} />
        <Route excat path = "/dashboard/OU/user-account/OuUser" component={OuUser} />
        <Route excat path = "/dashboard/OU/user-account/attach-user" component={AttachUser} />
        <Route path="/dashboard/OU/a/:userName" component={AttachOUPolicy} />
        <Route path="/dashboard/OU/Remove-permission/:userName" component={RemoveOUPolicy} />
        <Route path="/dashboard/OU/Service/AttachServiceOu" component={AttachServiceOu} />
        <Route path="/dashboard/OU/Service/UpdateOuService" component={UpdateOuService} />
        {/* Under Policies */}
        <Route exact path="/dashboard/policies/create-policy" component={CreatePolicy} />
        <Route exact path="/dashboard/policies/create-policy/Detch-UserPolicy" component={DetchUserPolicy} />
        <Route exact path="/dashboard/policies/create-policy/Attach-UserPolicy" component={AttachUserPolicy} />
        <Route exact path="/dashboard/policies/update-policy" component={UpdatePolicy} />
        <Route exact path="/dashboard/policies" component={Policies} />
        <Route exact path="/dashboard/policies/summary" component={PoliciesSummary} />
        {/* Under Groups */}
        <Route exact path = "/dashboard/groups" component={Groups} />
        <Route path = "/dashboard/groups/summary-groups/:name" component={SummaryGroups} />
        <Route exact path = "/dashboard/group/add-permission" component={AddPermissionGroup} /> 
        <Route exact path = "/dashboard/group/remove-permission" component={RemovePermissionGroup} /> 
        <Route exact path = "/dashboard/group/attach-user" component={AttachUserGroup} /> 
        {/* Resources */}
        <Route exact path="/dashboard/resources" component={Resources} />
        <Route exact path="/dashboard/resources/add-resource" component={AddResource} />
        <Route exact path="/dashboard/resources/update-resource" component={UpdateResource} />
        <Route exact path="/dashboard/resources/summary" component={ResourceSummary} />

        <Route exact path="/dashboard/ou/attach-permissions" component={AttachOuPermission} />
        <Route exact path="/dashboard/ou/attach-resources" component={AttachOuResource} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(connect(mapStateToProps, {})(rootRouter));
