import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContentText,
  Paper,
  Checkbox,
} from "@material-ui/core";
import "./AttachUser.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  attachUserToOrganization,
  getAttachUser,
} from "../../../actions/Users/authenticate";
import CustomAlert from "../../../Components/CustomAlert";
import CustomButton from "../../../Components/CustomButton";
import SearchField from "react-search-field";
import _ from "lodash";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    // width: '512px',
    //height: '342px',
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    padding: "20px",
    maxWidth: "fit-content",
  },
  dialogPaperClose: {
    // width: '512px',
    //height: '342px',
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  dialogContent: {
    textAlign: "center",
    marginTop: "20px",
  },
  dialogFooter: {
    justifyContent: "center",
    marginBottom: "20px",
  },
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function AttachUser({
  onClose,
  Open,
  history,
  attachUserToOrganization,
  authReducer,
  errorReducer,
  getAttachUser,
  location,
  dispatch,
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const classes = useStyles();
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getAttachUser(location.state.demo);
  }, []);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function createData(name, email) {
    return { name, email };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.attachUser)) {

    }
  }, [authReducer.attachUser]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.attachUserToOrganization)) {
      setShowPopUp(true);
      authReducer.attachUserToOrganization = {};
    }
  }, [authReducer.attachUserToOrganization]);

  const handleAttachUser = () => {
    if (authReducer.attachUser.data && authReducer.attachUser.data.length > 0) {
      if (selectedUsers.length > 0) {
        var theUserList = [...authReducer.attachUser.data];
        let finalPayload = [];
        let payload = {
          ouName: location.state.demo,
          userNames: [],
        };
        for (let i = 0; i < theUserList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (theUserList[i].hasOwnProperty("emails")) {
              payload.userNames.push(theUserList[i].emails[0]);
            }
          }
        }
        finalPayload.push(payload);
        attachUserToOrganization(payload);
        setLoaders({ showLoader: true, loadingText: "Attaching the users..." });
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  const _handleUserClick = (index) => {
    let indexList = [...selectedUsers];
    if (authReducer.attachUser.data && authReducer.attachUser.data.length > 0) {
      var theUsers = [...authReducer.attachUser.data];
      for (let i = 0; i < theUsers.length; i++) {
        if (index == i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item != i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
          }
        }
      }
    } else {
      return [];
    }
  };

  const _getUsers = () => {
    var rows = [];
    if (authReducer.attachUser.data != null) {
      var theUserList = authReducer.attachUser.data;
      if (theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !=
              null &&
              theUserList[i].name.givenName + theUserList[i].name.familyName !== ""
              ? (theUserList[i].name.givenName
                ? theUserList[i].name.givenName
                : "") +
              " " +
              (theUserList[i].name.familyName
                ? theUserList[i].name.familyName
                : "")
              : "-";
          let u_email =
            theUserList[i].hasOwnProperty("emails") &&
              theUserList[i].emails != null &&
              theUserList[i].emails !== ""
              ? theUserList[i].emails[0]
              : "-";
          rows.push(createData(u_name, u_email));
        }
      }
    }
    return rows;
  };

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(users);
  };

  return (
    <div
      className={"nav-route-container"}
    >
      <TopHeader />
      {showErrorMsg ? <CustomAlert type="error" msg={errorMsg} /> : ""}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 120px)",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Organizational Unit"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;
                {" > " + location.state.demo + " > Available Users to Attach"}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid
              container
              spacing={1}
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                container
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{ height: "30px", width: "327px", fontSize: "18px" }}
                  type="text"
                  placeholder="Search..."
                  onChange={(event) => {
                    searchUser(event.target.value);
                  }}
                />
                <Grid style={{ marginRight: "20px" }}>
                  <CustomButton
                    ButtonLabel="Attach"
                    Disabled={selectedUsers.length === 0 ? true : false}
                    onClick={handleAttachUser}
                  />
                </Grid>
              </Grid>
              <div className="container" style={{ backgroundColor: "white" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "10px",
                    padding: "10px",
                    marginLeft: "4px",
                    marginRight: "15px",
                  }}
                >
                  {_getUsers().length != 0 && authReducer.loading === false ? (
                    <TableContainer component={Paper} style={{ width: "100%" }}>
                      <Table aria-label="simple table" size="small">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow style={{ height: "40px" }}>
                            <TableCell className="tableBorderRightH headerCell">
                              Name
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableBorderRightH headerCell"
                            >
                              Email
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(searchResult.length
                            ? searchResult
                            : _getUsers()
                          ).map((row, i) => (
                            <TableRow
                              key={row.name + i}
                              style={{ height: "40px" }}
                              hover
                            >
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                                style={{ color: "#384089" }}
                              >
                                <Checkbox
                                  onClick={() => {
                                    _handleUserClick(i);
                                  }}
                                  role="checkbox"
                                  classes={{
                                    root: classes.CheckBoxroot,
                                    checked: classes.checked,
                                  }}
                                  checked={selectedUsers.includes(i)}
                                />
                                {row.name}
                              </TableCell>
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                              >
                                {row.email}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "12px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No user(s) found
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Attached"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching users please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    attachUserToOrganization,
    getAttachUser,
  })(AttachUser)
);
