import React, { useState, useEffect } from "react";
import {
  Grid,
  DialogContentText,
  Button,
  Paper,
  Checkbox,
} from "@material-ui/core";
import CustomTypography from "../../../Components/CustomTypography";
import CustomButton from "../../../Components/CustomButton";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import {
  groupsDetails,
  groupsUserGet,
  detachUserGroup,
  groupsPolicyGet,
  detachGroupPolicy,
} from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import "./SummaryGroups.css";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import CustomLoader from "../../../Components/Loader/CustomLoader";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function SummaryGroups({
  history,
  authReducer,
  errorReducer,
  location,
  groupsDetails,
  groupsUserGet,
  detachUserGroup,
  groupsPolicyGet,
  detachGroupPolicy,
}) {
  const classes = useStyles();
  const [created, setCreated] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showUp, setShowUp] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [id, setId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [name, setName] = useState([]);
  const [User, setUser] = useState([]);
  const [OU, setOU] = useState([]);
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [selectedButton, setSelectedButton] = useState(
    localStorage.getItem("selectedBut")
      ? localStorage.getItem("selectedBut")
      : "Users"
  );
  const [userApi, setUserApi] = useState(false);
  const [permissionAPi, setPermissionApi] = useState(false);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      setAlertMsg({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.msg = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    groupsDetails(location.state.demo, location.state.demo1);
  }, []);

  useEffect(() => {
    if (selectedButton === "Users" && userApi === false) {
      groupsUserGet(location.state.demo, location.state.demo1);
      setUserApi(true);
    } else if (selectedButton === "Permissions" && permissionAPi === false) {
      groupsPolicyGet(location.state.demo, location.state.demo1);
      setPermissionApi(true);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.groupsDetails)) {
      if(authReducer.groupsDetails.data){
        if(authReducer.groupsDetails.data.groupDetails){
          const groupDetails = authReducer.groupsDetails.data.groupDetails;
          setName( groupDetails.name ? groupDetails.name: "-" );
          setId(groupDetails.id ? groupDetails.id : "-");
          setCreated(groupDetails.createdAt ? groupDetails.createdAt : "-");
        }
        setUser( authReducer.groupsDetails.data.userCount ? authReducer.groupsDetails.data.userCount : "0" );
        setOU( authReducer.groupsDetails.data && authReducer.groupsDetails.data.OU ? authReducer.groupsDetails.data.OU : "-" );
      }
    }
  }, [authReducer.groupsDetails]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.detachUserGroup)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.detachUserGroup = {};
    }
  }, [authReducer.detachUserGroup]);

  const handlePermissions = () => {
    setSelectedButton("Permissions");
    if (permissionAPi === false) {
      groupsPolicyGet(location.state.demo, location.state.demo1);
      setLoaders({ showLoader: true, loadingText: "Fetching permissions please wait..." });
    }
    setPermissionApi(true);
    localStorage.setItem("selectedBut", "Permissions");
  };

  const handleUsers = () => {
    setSelectedButton("Users");
    if (userApi === false) {
      groupsUserGet(location.state.demo, location.state.demo1);
      setLoaders({ showLoader: true, loadingText: "Fetching users please wait..." });
    }
    setUserApi(true);
    localStorage.setItem("selectedBut", "Users");
  };

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(users);
    setSearchResult(users);
  };

  function createData(name, email) {
    return { name, email };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.detachGroupPolicy)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowUp(true);
      authReducer.detachGroupPolicy = {};
    }
  }, [authReducer.detachGroupPolicy]);

  const _getUsers = () => {
    if (authReducer.groupsUserGet != null) {
      var theUserList = authReducer.groupsUserGet.data;
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name.givenName + theUserList[i].name.familyName !=
              null &&
            theUserList[i].name.givenName + theUserList[i].name.familyName !==
              ""
              ? (theUserList[i].name.givenName
                  ? theUserList[i].name.givenName
                  : "") +
                " " +
                (theUserList[i].name.familyName
                  ? theUserList[i].name.familyName
                  : "")
              : "-";
          let u_email =
            theUserList[i].hasOwnProperty("emails") &&
            theUserList[i].emails != null &&
            theUserList[i].emails !== ""
              ? theUserList[i].emails[0]
              : "-";
          rows.push(createData(u_name, u_email));
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  function creationData(name, description) {
    return { name, description };
  }

  const getUse = () => {
    if (authReducer.groupsPolicyGet != null) {
      var theUserList =
        authReducer.groupsPolicyGet &&
        authReducer.groupsPolicyGet.data &&
        authReducer.groupsPolicyGet.data.permissions
          ? authReducer.groupsPolicyGet.data.permissions
          : "-";
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name != null &&
            theUserList[i].name !== ""
              ? theUserList[i].name
              : "-";
          let u_description =
            theUserList[i].hasOwnProperty("description") &&
            theUserList[i].description != null &&
            theUserList[i].description !== ""
              ? theUserList[i].description
              : "-";
          rows.push(creationData(u_name, u_description));
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const _handleUserClick = (index) => {
    let indexList = [...selectedUsers];
    if (
      authReducer.groupsUserGet.data != null &&
      authReducer.groupsUserGet.data.length > 0
    ) {
      var theUsers = [...authReducer.groupsUserGet.data];
      for (let i = 0; i < theUsers.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
          }
        }
      }
    } else {
      return [];
    }
  };

  const handleDetachUser = () => {
    if (
      authReducer.groupsUserGet.data != null &&
      authReducer.groupsUserGet.data.length > 0
    ) {
      if (selectedUsers.length > 0) {
        var theUserList = [...authReducer.groupsUserGet.data];
        let finalPayload = [];
        let payload = {
          name: location.state.demo,
          ouName: location.state.demo1,
          userNames: [],
        };
        for (let i = 0; i < theUserList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (theUserList[i].hasOwnProperty("emails")) {
              console.log("the userlist", theUserList[i].emails);
              payload.userNames.push(theUserList[i].emails[0]);
            }
          }
        }
        finalPayload.push(payload);
        detachUserGroup(payload);
        setLoaders({
          showLoader: true,
          loadingText: "Detaching the user...",
        });
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert &&
      showAlert === true &&
      alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                // onClick={() => history.push("/dashboard/users")}
                onClick={() => {
                  history.goBack();
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 200);
                }}
              >
                {"Group"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                }}
              >
                &nbsp;{"> " + location.state.demo}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <div>
                <div className="innerDiv">
                  <Grid item xs={12}>
                    <CustomTypography
                      size={18}
                      weight="600"
                      color="#4B4C63"
                      content="Group Details"
                    />
                    <div className="horizontal-line"></div>
                    <Grid item container xs={12}>
                      <Grid item container xs={6}>
                        <DialogContentText
                          style={{
                            size: "17",
                            fontWeight: "600",
                            color: "#4B4C63",
                          }}
                          lineHeight={19}
                        >
                          {"Group Name: "}
                        </DialogContentText>
                        <DialogContentText
                          style={{
                            size: "16",
                            weight: "normal",
                            color: "#5A5757",
                          }}
                          lineHeight={19}
                        >
                          &nbsp;{name}
                        </DialogContentText>
                      </Grid>
                      <Grid item container xs={6}>
                        <DialogContentText
                          style={{
                            size: "17",
                            fontWeight: "600",
                            color: "#4B4C63",
                          }}
                          lineHeight={19}
                        >
                          {"Organization Name: "}
                        </DialogContentText>
                        <DialogContentText
                          style={{
                            size: "16",
                            weight: "normal",
                            color: "#5A5757",
                          }}
                          lineHeight={19}
                        >
                          &nbsp;{OU}
                        </DialogContentText>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item container xs={6}>
                        <DialogContentText
                          style={{
                            size: "17",
                            fontWeight: "600",
                            color: "#4B4C63",
                          }}
                          lineHeight={19}
                        >
                          {"Group Id: "}
                        </DialogContentText>
                        <DialogContentText
                          style={{
                            size: "16",
                            weight: "normal",
                            color: "#5A5757",
                          }}
                          lineHeight={19}
                        >
                          &nbsp;{id}
                        </DialogContentText>
                      </Grid>
                      <Grid item container xs={6}>
                        <DialogContentText
                          style={{
                            size: "17",
                            fontWeight: "600",
                            color: "#4B4C63",
                          }}
                          lineHeight={19}
                        >
                          {"Created At: "}
                        </DialogContentText>
                        <DialogContentText
                          style={{
                            size: "16",
                            weight: "normal",
                            color: "#5A5757",
                          }}
                          lineHeight={19}
                        >
                          &nbsp;{moment(created).format("yyyy-MM-D LTS")}
                        </DialogContentText>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <br></br>
                <Grid>
                  <div className="innerDiv">
                    <Grid container>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "200px",
                          borderBottom:
                            selectedButton === "Users"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                        onClick={handleUsers}
                      >
                        Users
                      </Button>
                      <Button
                        style={{
                          marginTop: "-13px",
                          marginLeft: "-10px",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#F2F2F2",
                          color: "#000",
                          height: "44px",
                          width: "200px",
                          borderBottom:
                            selectedButton === "Permissions"
                              ? "2px solid #008FBD"
                              : "none",
                          borderRadius: "0px",
                          borderTop: "none",
                          borderLeft: "2px solid #fff",
                          borderRight: "none",
                        }}
                        onClick={handlePermissions}
                      >
                        Permissions
                      </Button>
                    </Grid>
                    <br />
                    {selectedButton === "Users" &&
                    authReducer.loading === false ? (
                      <Fragment>
                        <div
                          style={{
                            margin: "10px",
                            backgroundColor: "#FFFFFF",
                            height: "auto",
                            width: "auto",
                          }}
                        >
                          <Grid
                            container
                            spacing={1}
                            style={{
                              marginTop: 5,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Grid
                              item
                              container
                              style={{
                                marginLeft: "10px",
                                marginTop: "1px",
                                justifyContent: "space-between",
                              }}
                            >
                              <input
                                style={{
                                  height: "30px",
                                  width: "327px",
                                  fontSize: "18px",
                                }}
                                type="text"
                                placeholder="Search user..."
                                onChange={(event) => {
                                  searchUser(event.target.value);
                                }}
                              />
                              <div
                                style={{ display: "flex", marginRight: "30px" }}
                              >
                                <Grid style={{ marginLeft: "10px" }}>
                                  <CustomButton
                                    ButtonLabel="Attach User"
                                    onClick={() => {
                                      history.push({
                                        pathname:
                                          "/dashboard/group/attach-user",
                                        state: {
                                          demo: location.state.demo,
                                          demo1: OU,
                                        },
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid style={{ marginLeft: "10px" }}>
                                  <CustomButton
                                    ButtonLabel="Detach User"
                                    Disabled={selectedUsers.length === 0}
                                    onClick={handleDetachUser}
                                  />
                                </Grid>
                              </div>
                            </Grid>
                            <div
                              className="container"
                              style={{ backgroundColor: "white" }}
                            >
                              <div
                                className="row"
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "4px",
                                  marginRight: "20px",
                                }}
                              >
                                {_getUsers().length != 0 &&
                                authReducer.loading === false ? (
                                  <div
                                    className="container"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <div
                                      className="row"
                                      style={{
                                        marginTop: "10px",
                                        padding: "10px",
                                      }}
                                    >
                                      <TableContainer
                                        style={{ width: "100%" }}
                                        component={Paper}
                                      >
                                        <Table
                                          aria-label="simple table"
                                          size="small"
                                        >
                                          <TableHead
                                            style={{ background: "#ecf5ff" }}
                                          >
                                            <TableRow
                                              style={{ height: "40px" }}
                                            >
                                              <TableCell className="tableBorderRightH headerCell">
                                                Name
                                              </TableCell>
                                              <TableCell
                                                className="tableBorderRightH headerCell"
                                                align="left"
                                              >
                                                Email
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {(searchResult.length
                                              ? searchResult
                                              : _getUsers()
                                            ).map((row, i) => (
                                              <TableRow
                                                key={row.name + i}
                                                style={{ height: "40px" }}
                                                hover
                                              >
                                                <TableCell
                                                  className="tableBorderRightH"
                                                  align="left"
                                                  style={{ color: "#384089" }}
                                                >
                                                  <Checkbox
                                                    onClick={() => {
                                                      _handleUserClick(i);
                                                    }}
                                                    role="checkbox"
                                                    classes={{
                                                      root: classes.CheckBoxroot,
                                                      checked: classes.checked,
                                                    }}
                                                    checked={selectedUsers.includes(
                                                      i
                                                    )}
                                                  />
                                                  <span
                                                    onClick={() =>
                                                      history.push({
                                                        pathname: `/dashboard/users/policy/${row.email}`,
                                                        state: {
                                                          demo: row.name,
                                                          demo1: row.email,
                                                        },
                                                      })
                                                    }
                                                    style={{
                                                      marginLeft: "8px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {row.name}
                                                  </span>
                                                </TableCell>
                                                <TableCell
                                                  className="tableBorderRightH"
                                                  align="left"
                                                >
                                                  {row.email}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </div>
                                  </div>
                                ) : (
                                  <Grid
                                    container
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      marginTop: "20px",
                                      backgroundColor: "white",
                                      border: "1.5px solid #5A5757",
                                      padding: "10px",
                                      flexDirection: "column",
                                      color: "#5A5757",
                                    }}
                                  >
                                    No user(s) found
                                  </Grid>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Grid
                          container
                          item
                          style={{ marginTop: "10px", marginLeft: "15px" }}
                        >
                          <CustomButton
                            ButtonType=""
                            ButtonLabel="Add Permissions"
                            onClick={() =>
                              history.push({
                                pathname: "/dashboard/group/add-permission",
                                state: {
                                  demo: location.state.demo,
                                  demo1: location.state.demo1,
                                  ouName: location.state.ouName
                                },
                              })
                            }
                          />
                          <div style={{ marginLeft: "10px" }}>
                            <CustomButton
                              ButtonType=""
                              ButtonLabel="Remove Permissions"
                              onClick={() =>
                                history.push({
                                  pathname:
                                    "/dashboard/group/remove-permission",
                                  state: {
                                    demo: location.state.demo,
                                    demo1: location.state.demo1,
                                  },
                                })
                              }
                            />
                          </div>
                        </Grid>
                        <div
                          className="container"
                          style={{ backgroundColor: "white" }}
                        >
                          <div
                            className="row"
                            style={{
                              marginTop: "10px",
                              padding: "10px",
                              marginLeft: "4px",
                              marginRight: "15px",
                              // width: "678px",
                            }}
                          >
                            {getUse().length != 0 ? (
                              <TableContainer
                                component={Paper}
                                style={{ width: "100%" }}
                              >
                                <Table aria-label="simple table" size="small">
                                  <TableHead style={{ background: "#ecf5ff" }}>
                                    <TableRow style={{ height: "40px" }}>
                                      <TableCell
                                        className="tableBorderRightH headerCell"
                                        style={{ width: "50%" }}
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="tableBorderRightH headerCell"
                                      >
                                        Description
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {getUse().map((row, i) => (
                                      <TableRow
                                        key={row.name + i}
                                        style={{ height: "40px" }}
                                      >
                                        <TableCell
                                          className="tableBorderRightH"
                                          align="left"
                                          style={{ color: "#384089" }}
                                        >
                                          <span
                                            onClick={() =>
                                              history.push({
                                                pathname:
                                                  "/dashboard/policies/summary",
                                                state: {
                                                  demo: row.name,
                                                  demo1: OU,
                                                },
                                              })
                                            }
                                            style={{
                                              marginLeft: "8px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {row.name}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          className="tableBorderRightH"
                                          align="left"
                                        >
                                          {row.description}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <Grid
                                container
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  fontSize: "20px",
                                  fontWeight: "600",
                                  marginTop: "20px",
                                  backgroundColor: "white",
                                  border: "1.5px solid #5A5757",
                                  padding: "10px",
                                  flexDirection: "column",
                                  color: "#5A5757",
                                }}
                              >
                                No policy found
                              </Grid>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Grid>
              </div>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "25px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Detached"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <DialogBox
        Open={showUp}
        onClose={() => setShowUp(false)}
        DialogTitle="Policy successfully detached"
        ButtonLabel="OK"
        onClick={refreshPage}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching group details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    groupsDetails,
    groupsUserGet,
    detachUserGroup,
    groupsPolicyGet,
    detachGroupPolicy,
  })(SummaryGroups)
);
