import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import "./userAccount.css";
import { Close as CloseIcon } from "@material-ui/icons/";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomTypography from "../../../Components/CustomTypography";
import AttachUser from "../AttachUser";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ouDerived, ouDelete } from "../../../actions/Users/authenticate";
import _ from "lodash";
import OuUser from "../OuUser";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import Breadcrumbs from '@mui/material/Breadcrumbs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  CheckBoxroot: {
    // fontSize: "13px",
    // padding: "0px 10px 0px 0px",
    color: "rgb(0, 143, 189)",
    "&$checked": {
      color: "rgb(0, 143, 189)",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    // padding: "5px 10px",
  },
}));

function UserAccount({
  history,
  ouDerived,
  authReducer,
  ouDelete,
  errorReducer,
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showAlert, setShowAlert] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [ouData, setOuData] = useState([]);
  const [childOUData, setChildOUData] = useState([]);
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [parentOu, setParentOu] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [alertMessage, setAlertMessage] = useState({
    msg: null,
    type: "error",
  });
  const classes = useStyles();
  const [openUser, setOpenUser] = useState(false);
  const [ouDataChildren, setOuDataChildren] = useState([]);
  const [usersNo, setUsersNo] = useState([]);
  const [textColor, setTextColor] = useState("black");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  const handleClickOpenUser = (value) => {
    if (value && value === "view") {
      setOpenUser(true);
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      !_.isEmpty(authReducer.ouDelete) &&
      history &&
      history.location &&
      history.location.state !== "9997"
    ) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setPopUp(true);
    } else {
      authReducer.ouDelete = "";
    }
  }, [authReducer.ouDelete]);

  useEffect(() => {
    ouDerived();
  }, []);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      // setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmDelete = () => {
    setShowDeleteAlert(!showDeleteAlert);
  };

  const handleDeleteOu = () => {
    var selectedOU = ouData.filter((item) => item[0] === parentOu);
    if (selectedOU.length && !selectedOU[0][1].children.length) {
      let payload = { ouName: parentOu };
      ouDelete(payload);
      setLoaders({ showLoader: true, loadingText: "Deleting organization please wait..." });
    } else {
      setAlertMsg({ msg: "It can't be delete", type: "error" });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(authReducer.ouDerived)) {
      let records = Object.entries(authReducer.ouDerived.data);
      setOuData(records);
      let childArray = [];
      records.map((item, key) => {
        childArray.push(...item[1].children);
      });
      setOuDataChildren(childArray);
      setChildOUData(records.filter((item, key) => item[1].parentid !== null));
      let data = records.filter((item, key) => item[1].children.length);
    }
  }, [authReducer.ouDerived]);

  const handleCheckboxClick = (e, value = null) => {
    setParentOu(value);
    setAttachButtonDisable(false);
  };

  const canDeleteOrg = () => {
    var selectedOU = ouData.filter((item) => item[0] === parentOu);
    //return !(selectedOU.length && selectedOU[0][1].id !== ouData[0][1].id && !selectedOU[0][1].children.length);
    return false
  };



  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert &&
        showAlert === true &&
        alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Organization Units</Typography>
              </Breadcrumbs>
            </div>
            <div
              className="horizontal-line"
              style={{ marginLeft: "2px" }}
            ></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <Grid
                container
                item
                xs={12}
                className="policy-container"
                style={{
                  backgroundColor: "white",
                  border: "1.5px solid #e5e5e5",
                  paddingTop: "40px",
                  padding: "10px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "rgb(0, 143, 189)",
                      fontFamily: "Nunito, sans-serif",
                    }}
                  >
                    Organizational Structure
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{ marginTop: 10, paddingBottom: "10px" }}
                >
                  <Grid
                    item
                    container
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Grid>
                      <Button
                        style={{
                          fontSize: "14px",
                          width: "175px",
                          color: "white",
                          marginLeft: "10px",
                          paddingTop: "5px",
                        }}
                        onClick={() => {
                          history.push({
                            pathname: "/dashboard/OU/user-account/attach-user",
                            state: { demo: parentOu },
                          });
                        }}
                        disabled={attachButtonDisable}
                      >
                        {"Attach User Accounts"}
                      </Button>
                    </Grid>
                    <Grid>
                      <Button
                        style={{
                          width: "175px",
                          marginLeft: "10px",
                        }}
                        onClick={handleMenu}
                        disabled={attachButtonDisable}
                      >
                        {"Actions"}
                        <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                        style={{ top: "35px" }}
                      >
                        <MenuItem
                          onClick={() => {
                            history.push({
                              pathname: "/dashboard/OU/user-account/create-new",
                              state: { demo: parentOu },
                            });
                          }}
                          className="Menu-item">Create new</MenuItem>
                        {parentOu === "K4Mobility" ? (
                          <div></div>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              history.push({
                                pathname: `/dashboard/OU/user-account/rename/${parentOu}`,
                                state: { demo: parentOu },
                              });
                            }}
                            className="Menu-item">Update</MenuItem>
                        )}

                        {
                          canDeleteOrg() ? (
                            <div></div>
                          ) : (
                            <MenuItem
                              onClick={confirmDelete}
                              className="Menu-item">Delete
                            </MenuItem>
                          )
                        }

                      </Menu>
                    </Grid>
                  </Grid>
                  <div
                    className={classes.root}
                    style={{
                      marginTop: "25px",
                      marginLeft: "13px",
                      marginRight: "15px",
                    }}
                  >
                    {ouData &&
                      ouData.length > 0 &&
                      ouData.map((ouDatakey, key) => (
                        <div>
                          {((localStorage.getItem("loggedInUser") &&
                            (JSON.parse(localStorage.getItem("loggedInUser"))[
                              "data"
                            ]["level"] === 1 ||
                              JSON.parse(localStorage.getItem("loggedInUser"))[
                              "data"
                              ]["level"] === "1")
                            ? ouDatakey[1].parentid === 0
                            : ouDatakey[1].parentid === 0 ||
                            (ouDatakey[1].parentid != 0 && key === 0)) &&
                            !showSearchBar) ||
                            (showSearchBar &&
                              searchResult &&
                              searchResult !== "" &&
                              ouDatakey[0].includes(searchResult)) ? (
                            <Accordion>
                              {/* Parent */}
                              {
                                // (ouDatakey[1].parentid === null || ouDatakey[1].parentid != null) ?

                                (
                                  localStorage.getItem("loggedInUser") &&
                                    (JSON.parse(
                                      localStorage.getItem("loggedInUser")
                                    )["data"]["level"] === 1 ||
                                      JSON.parse(
                                        localStorage.getItem("loggedInUser")
                                      )["data"]["level"] === "1")
                                    ? ouDatakey[1].parentid === 0
                                    : ouDatakey[1].parentid === 0 ||
                                    ouDatakey[1].parentid != 0
                                ) ? (
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header"
                                  >
                                    <FormControl>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: "17px",
                                          fontWeight: "400",
                                          color: "rgb(0, 143, 189)",
                                        }}
                                      >
                                        <Checkbox
                                          size="small"
                                          checked={
                                            parentOu &&
                                              parentOu === ouDatakey[0]
                                              ? true
                                              : false
                                          }
                                          // color= "rgb(0, 143, 189)"
                                          onChange={(event) => {
                                            handleCheckboxClick(
                                              event,
                                              ouDatakey[0]
                                            );
                                          }}
                                          classes={{
                                            root: classes.CheckBoxroot,
                                            checked: classes.checked,
                                          }}
                                          icon={<CircleUnchecked />}
                                          checkedIcon={<CircleCheckedFilled />}
                                        />
                                        <FolderOutlinedIcon />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {ouDatakey[0]}
                                      </div>
                                    </FormControl>
                                    <Button
                                      className="users-all"
                                      onClick={() => {
                                        history.push({
                                          pathname: `/dashboard/OU/user-account/OuUser/${ouDatakey[0]}`,
                                          // "/dashboard/OU/user-account/OuUser",
                                          // state: { demo: parentOu },
                                          state: { demo: ouDatakey[0] },
                                        });
                                      }}
                                    // disabled={attachButtonDisable}
                                    >
                                      view details
                                    </Button>
                                    {openUser && openUser === true ? (
                                      <OuUser parentOu={parentOu} />
                                    ) : null}
                                  </AccordionSummary>
                                ) : null
                              }
                              {showSearchBar &&
                                searchResult &&
                                searchResult !== ""
                                ? ouDataChildren.map((child, key) => {
                                  return (
                                    // Children
                                    <div>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-label="Expand"
                                          aria-controls="additional-actions1-content"
                                          id="additional-actions1-header"
                                          style={{ marginLeft: "50px" }}
                                        >
                                          <FormControl>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "rgb(0, 143, 189)",
                                              }}
                                            >
                                              <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={
                                                  parentOu &&
                                                    parentOu === child
                                                    ? true
                                                    : false
                                                }
                                                onChange={(event) => {
                                                  handleCheckboxClick(
                                                    event,
                                                    child
                                                  );
                                                }}
                                                inputProps={{
                                                  "aria-label":
                                                    "primary checkbox",
                                                }}
                                                classes={{
                                                  root: classes.CheckBoxroot,
                                                  checked: classes.checked,
                                                }}
                                                icon={<CircleUnchecked />}
                                                checkedIcon={
                                                  <CircleCheckedFilled />
                                                }
                                              />
                                              <FileCopyOutlinedIcon />
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {child}
                                            </div>
                                          </FormControl>
                                          <Button
                                            style={{
                                              backgroundColor: "white",
                                              color: "#008fbd",
                                              border: "#fff",
                                              minHeight: "15px",
                                              fontSize: "14px",
                                              paddingTop: "10px",
                                              weight: "500",
                                              textDecorationLine: "underline",
                                              paddingLeft: "30px",
                                            }}
                                            onClick={(e) => {
                                              history.push({
                                                pathname:
                                                  `/dashboard/OU/user-account/OuUser/${child}`,
                                                state: { demo: child },
                                              });
                                            }}
                                          // disabled={attachButtonDisable}
                                          >
                                            view details
                                          </Button>
                                          {openUser && openUser === true ? (
                                            <OuUser parentOu={parentOu} />
                                          ) : null}
                                        </AccordionSummary>
                                        {/* {Grandchild} */}
                                        {ouDatakey[1].children.map(
                                          (item, key) => {
                                            return childOUData.map(
                                              (childOU, key) => {
                                                return (
                                                  item === child &&
                                                  childOU[0] === item &&
                                                  childOU[1].children.map(
                                                    (grandchild, key) => {
                                                      return (
                                                        <AccordionSummary
                                                          id="additional-actions1-header"
                                                          style={{
                                                            marginLeft:
                                                              "100px",
                                                          }}
                                                        >
                                                          <FormControl>
                                                            <div
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                alignItems:
                                                                  "center",
                                                                color:
                                                                  "rgb(0, 143, 189)",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                size="small"
                                                                checked={
                                                                  parentOu &&
                                                                    parentOu ===
                                                                    grandchild
                                                                    ? true
                                                                    : false
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleCheckboxClick(
                                                                    event,
                                                                    grandchild
                                                                  );
                                                                }}
                                                                classes={{
                                                                  root: classes.CheckBoxroot,
                                                                  checked:
                                                                    classes.checked,
                                                                }}
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "primary checkbox",
                                                                }}
                                                                icon={
                                                                  <CircleUnchecked />
                                                                }
                                                                checkedIcon={
                                                                  <CircleCheckedFilled />
                                                                }
                                                              />
                                                              <FileCopyOutlinedIcon />
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {grandchild}
                                                            </div>
                                                          </FormControl>
                                                          <Button
                                                            className="users-all"
                                                            onClick={() => {
                                                              history.push({
                                                                pathname:
                                                                  // "/dashboard/OU/user-account/OuUser",
                                                                  `/dashboard/OU/user-account/OuUser/${grandchild}`,
                                                                state: {
                                                                  demo: grandchild,
                                                                },
                                                              });
                                                            }}
                                                            disabled={
                                                              attachButtonDisable
                                                            }
                                                          >
                                                            view all user
                                                          </Button>
                                                          {openUser &&
                                                            openUser ===
                                                            true ? (
                                                            <OuUser
                                                              parentOu={
                                                                parentOu
                                                              }
                                                            />
                                                          ) : null}
                                                        </AccordionSummary>
                                                      );
                                                    }
                                                  )
                                                );
                                              }
                                            );
                                          }
                                        )}
                                      </Accordion>
                                    </div>
                                  );
                                })
                                : ouDatakey[1].children.map((child, key) => {
                                  return (
                                    // Children
                                    <div>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-label="Expand"
                                          aria-controls="additional-actions1-content"
                                          id="additional-actions1-header"
                                          style={{ marginLeft: "50px" }}
                                        >
                                          <FormControl>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "rgb(0, 143, 189)",
                                              }}
                                            >
                                              <Checkbox
                                                size="small"
                                                checked={
                                                  parentOu &&
                                                    parentOu === child
                                                    ? true
                                                    : false
                                                }
                                                classes={{
                                                  root: classes.CheckBoxroot,
                                                  checked: classes.checked,
                                                }}
                                                onChange={(event) => {
                                                  handleCheckboxClick(
                                                    event,
                                                    child
                                                  );
                                                }}
                                                icon={<CircleUnchecked />}
                                                checkedIcon={
                                                  <CircleCheckedFilled />
                                                }
                                              />
                                              <FileCopyOutlinedIcon />
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {child}
                                            </div>
                                          </FormControl>
                                          <Button
                                            className="users-all"
                                            onClick={() => {
                                              history.push({
                                                pathname:
                                                  `/dashboard/OU/user-account/OuUser/${child}`,
                                                state: { demo: child },
                                              });
                                            }}
                                          >
                                            view details
                                          </Button>
                                          {openUser && openUser === true ? (
                                            <OuUser parentOu={parentOu} />
                                          ) : null}
                                        </AccordionSummary>
                                        {/* {Grandchild} */}
                                        {ouDatakey[1].children.map(
                                          (item, key) => {
                                            return childOUData.map(
                                              (childOU, key) => {
                                                return (
                                                  item === child &&
                                                  childOU[0] === item &&
                                                  childOU[1].children.map(
                                                    (grandchild, key) => {
                                                      return (
                                                        <AccordionSummary
                                                          id="additional-actions1-header"
                                                          style={{
                                                            marginLeft:
                                                              "100px",
                                                          }}
                                                        >
                                                          <FormControl>
                                                            <div
                                                              style={{
                                                                display:
                                                                  "flex",
                                                                alignItems:
                                                                  "center",
                                                                color:
                                                                  "rgb(0, 143, 189)",
                                                              }}
                                                            >
                                                              <Checkbox
                                                                size="small"
                                                                classes={{
                                                                  root: classes.CheckBoxroot,
                                                                  checked:
                                                                    classes.checked,
                                                                }}
                                                                checked={
                                                                  parentOu &&
                                                                    parentOu ===
                                                                    grandchild
                                                                    ? true
                                                                    : false
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  handleCheckboxClick(
                                                                    event,
                                                                    grandchild
                                                                  );
                                                                }}
                                                                icon={
                                                                  <CircleUnchecked />
                                                                }
                                                                checkedIcon={
                                                                  <CircleCheckedFilled />
                                                                }
                                                              />
                                                              <FileCopyOutlinedIcon />
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {grandchild}
                                                            </div>
                                                          </FormControl>
                                                          <Button
                                                            className="users-all"
                                                            onClick={() => {
                                                              history.push({
                                                                pathname: `/dashboard/OU/user-account/OuUser/${grandchild}`,
                                                                state: {
                                                                  demo: grandchild,
                                                                },
                                                              });
                                                            }}
                                                          // disabled={
                                                          //   attachButtonDisable
                                                          // }
                                                          >
                                                            view details
                                                          </Button>
                                                          {openUser &&
                                                            openUser ===
                                                            true ? (
                                                            <OuUser
                                                              parentOu={
                                                                parentOu
                                                              }
                                                            />
                                                          ) : null}
                                                        </AccordionSummary>
                                                      );
                                                    }
                                                  )
                                                );
                                              }
                                            );
                                          }
                                        )}
                                      </Accordion>
                                    </div>
                                  );
                                })}
                            </Accordion>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={popUp}
        onClose={() => setPopUp(false)}
        DialogTitle="Organization Successfully Deleted"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <Dialog
        open={showDeleteAlert}
        TransitionComponent={Transition}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              Please confirm to delete selected organization.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  handleDeleteOu();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching organizations please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    ouDerived,
    ouDelete,
  })(UserAccount)
);
