import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Paper,
  Input,
  Typography as MyTypography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Typography } from "../../Components/wrappers/Wrappers";
import CustomSnackbar from "../../Components/snackbar/CustomSnackbar";
import useStyles from "./styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CustomLoader from "../../Components/Loader/CustomLoader";
import DialogForgotPswrd from "./DialogForgotPswrd";
import KonnectLogo from "../../assets/images/K4-logo.png";
import useWindowDimensions from "../../utils/screenWidthHight";
import { Base64 } from "js-base64";
import _ from "lodash";
import "./loginnn.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAPI } from "../../actions/Users/authenticate";
import { policyAPI } from "../../actions/Users/authenticate";
import DialogBox from "../../Components/DialogBoxError";
import CustomAlert from "../../Components/CustomAlert";
import { returnVersion } from "../../utils/validation";

// import Login from "../Login";

function Login({ history, authReducer, errorReducer, loginAPI, policyAPI }) {
  const [checked, setChecked] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [fullLoader, setFullLoader] = useState(false);
  const classes = useStyles();
  //......
  const [releaseNo, setReleaseNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotDialog, setShowForgotDialog] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //........

  useEffect(() => {
    if (window.location && window.location.search) {
      let idParams = window.location.search;
      let id = idParams.replace("?redirectUrl=", "").trim();

      setRedirectUrl(id);
    }
  }, [location]);

  useEffect(() => {
    var rememberStr = localStorage.getItem("rememberMe");
    if (rememberStr === "true") {
      setUserName(localStorage.getItem("userName"));
      setPassword(localStorage.getItem("password"));
    }
    console.log("authReducer.loginUser---", authReducer.loginUser);
    if (!_.isEmpty(authReducer.loginUser)) {
      
      // let payload = {
      //   userName: userName,
      // };
      // policyAPI(payload, redirectUrl);

      if (checked === true) {
        localStorage.setItem("rememberMe", true);
        localStorage.setItem("userName", userName);
        localStorage.setItem("password", password);
      } else {
        localStorage.setItem("rememberMe", false);
        localStorage.setItem("userName", "");
        localStorage.setItem("password", "");
      }

      localStorage.setItem(
        "loggedInUser",
        JSON.stringify(authReducer.loginUser)
      );

      let ret = authReducer.loginUser;
      var args = "";
      var loginUrl;
      args += "id_token=" + ret.id_token;
      args += "&access_token=" + ret.access_token;
      args += "&expires_in=" + ret.expires_in;
      args += "&refresh_token=" + ret.refresh_token;
      args += "&token_type=" + ret.token_type;
      loginUrl = window.location.href + "dashboard" + "#" + args;
      // loginUrl = window.location.href + 'dashboard';
      console.log("loginUrl---", loginUrl);
      window.location.href = loginUrl;
      authReducer.loginUser = {};

    }
  }, [authReducer.loginUser]);

  // useEffect(() => {
  //   console.log("authReducer agreePolicy---", authReducer.agreePolicy);
  //   if (!_.isEmpty(authReducer.agreePolicy)) {
  //     let retPolicy = authReducer.agreePolicy;
  //     let arr = retPolicy.permissions;

  //     if ("permissions" in retPolicy && retPolicy.permissions.length !== 0) {
  //       if (_.isEmpty(arr)) {
  //         setFullLoader(false);
  //         setShowPopUp(true);
  //       } else {
  //         let isIAMExist = 0;
  //         for (var i = 0; i < arr.length; i++) {
  //           let serviceStr = arr[i].service;
  //           let serviceArr = arr[i].servicefunctions;
  //           if (serviceStr === "IAM" || serviceStr.includes("IAM")) {
  //             for (var j = 0; j < serviceArr.length; j++) {
  //               let funtionStr = serviceArr[j].function;
  //               if (funtionStr === "Admin") {
  //                 isIAMExist = 1;
  //                 console.log(
  //                   "authReducer.loginUser---",
  //                   authReducer.loginUser
  //                 );

  //                 if (checked === true) {
  //                   localStorage.setItem("rememberMe", true);
  //                   localStorage.setItem("userName", userName);
  //                   localStorage.setItem("password", password);
  //                 } else {
  //                   localStorage.setItem("rememberMe", false);
  //                   localStorage.setItem("userName", "");
  //                   localStorage.setItem("password", "");
  //                 }
  //                 localStorage.setItem(
  //                   "loggedInUser",
  //                   JSON.stringify(authReducer.loginUser)
  //                 );
  //                 let ret = authReducer.loginUser;
  //                 var args = "";
  //                 var loginUrl;
  //                 args += "id_token=" + ret.id_token;
  //                 args += "&access_token=" + ret.access_token;
  //                 args += "&expires_in=" + ret.expires_in;
  //                 args += "&refresh_token=" + ret.refresh_token;
  //                 args += "&token_type=" + ret.token_type;
  //                 loginUrl = window.location.href + "dashboard" + "#" + args;
  //                 // loginUrl = window.location.href + 'dashboard';
  //                 console.log("loginUrl---", loginUrl);
  //                 window.location.href = loginUrl;
  //                 authReducer.loginUser = {};
  //                 break;
  //               }
  //             }
  //           }
  //           if (isIAMExist === 1) {
  //             break;
  //           }
  //         }

  //         if (isIAMExist === 0) {
  //           setShowPopUp(true);
  //         }
  //       }
  //     } else {
  //       setShowPopUp(true);
  //     }
  //     authReducer.agreePolicy = {};
  //   }
  // }, [authReducer.agreePolicy]);


  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.message === "User Not Authorize") {
        setFullLoader(false);
        setShowPopUp(true);
      } else {
        setFullLoader(false);
        setError({ message: errorReducer.description, status: true });
      }
      errorReducer.message = "";
    }
  }, [errorReducer]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;

    if (name === "userName") {
      setUserName(event.target.value);
    }
    if (name === "password") {
      setPassword(event.target.value);
    }
  };

  const handleChangeRemember = (event) => {
    setChecked(event.target.checked);
  };

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (_.isEmpty(userName) || _.isEmpty(password)) {
      setError({
        message: "Please Enter All the Mandatory Fields",
        status: true,
      });
    } else {
      let payload = {
        userName: userName,
        password: Base64.encode(password),
        // password: password
      };
      loginAPI(payload, redirectUrl);
      setFullLoader(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      {showErrorMsg && errorMsg!="You are not authorized to access this feature." ? <CustomAlert type="error" msg={errorMsg} /> : ""}
      <Paper className={classes.cardRoot}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={KonnectLogo} />
          <Typography
            variant="h5"
            className={classes.logoCaption}
            style={{
              fontWeight: 600,
              lineHeight: "33px",
              color: "#384089",
              marginLeft: "118px",
              width: "313px",
            }}
          >
            Simple. Reliable. Powerful.
          </Typography>
          <Typography className={classes.copyright}>
            {`© 2022 K4 Mobility Inc-All Rights Reserved. | IAM Version ${releaseNo ? releaseNo : returnVersion()} (Official Build)`}
          </Typography>
        </div>
        <div className={classes.inputRootContainer}>
          <Typography
            className={classes.usernameHeading}
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              marginTop: "202px",
            }}
          >
            Username
          </Typography>
          <Input
            inputProps={{
              "aria-label": "userName",
              className: "login-input-border",
            }}
            value={userName}
            name="userName"
            onChange={handleChange}
          />
          <Typography
            className={classes.pswrdHeading}
            style={{ fontSize: "20px", marginBottom: "10px" }}
          >
            Password
          </Typography>
          <Input
            inputProps={{
              "aria-label": "username",
              className: "login-input-border",
            }}
            name="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <div
            className={classes.submitBtnWrapper}
            style={{ marginTop: "50px" }}
          >
            <Button
              style={{ width: "120px", fontSize: "20px" }}
              color="primary"
              //   variant="contained"
              disabled={
                userName.length === 0 || password.length === 0 || isLoading
              }
              onClick={handleLogin}
            >
              Login
            </Button>
            {isLoading && (
              <CircularProgress size={26} className={classes.loginLoader} />
            )}
          </div>
          <Grid
            className={classes.forgrBtnWrapper}
            onClick={() => history.push("/forgot-password")}
          >
            <Typography
              className={classes.forgotPass}
              style={{ fontSize: "16px" }}
            >
              Forgot Password?
            </Typography>
          </Grid>
        </div>
      </Paper>
      {error ? (
        <CustomSnackbar
          variant={error.variant ? error.variant : "error"}
          message={error.message}
          open={error.status}
          onClose={() => {
            setError(null);
          }}
        />
      ) : null}
      {fullLoader && <CustomLoader showLoader={fullLoader} />}
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="K4IAM"
        DialogBody="You are not authorized to access the K4IAM dashboard."
        ButtonLabel="Done"
        onClick={() => {
          setFullLoader(false)
          setShowPopUp(false)
        }}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    loginAPI,
    policyAPI,
  })(Login)
);
