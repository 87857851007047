import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Paper,
  Box,
  TextField,
  InputAdornment
} from "@material-ui/core";
import "./Resources.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import DialogBox from "../../Components/DialogBox";
import {
  resourceGet, resourceDelete, activateResource,
  deActivateResource,
} from "../../actions/Users/authenticate";
import CustomAlert from "../../Components/CustomAlert";
import _ from "lodash";
import TopHeader from "../../Components/TopHeader";
import SideMenu from "../../Components/SideMenu";
import { Close as CloseIcon } from "@material-ui/icons/";
import CustomLoader from "../../Components/Loader/CustomLoader";
import Pagination from '@mui/material/Pagination';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { PAGE_SIZE } from "../../constants/Constants";
import disable from "../../assets/images/disable.svg";
import enable from "../../assets/images/enable.svg";
import ConfirmDialog from "../../Components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function Resources({
  history,
  authReducer,
  resourceGet,
  resourceDelete,
  activateResource,
  deActivateResource,
  errorReducer
}) {
  const classes = useStyles();
  // const [errorMsg, setErrorMsg] = useState(null);
  // const [showErrorMsg, setShowError] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  // const [popUp, setPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedResource, setSelectedResource] = useState([]);
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: {},
  });

  useEffect(() => {
    resourceGet(1, PAGE_SIZE, searchValue);
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceGet) && authReducer.resourceGet.data) {
      setResourceData(authReducer.resourceGet.data.records);
      setPage(authReducer.resourceGet.data.page);
      setPages(authReducer.resourceGet.data.pages);
      setTotal(authReducer.resourceGet.data.total);
    }
  });

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceDelete)) {
      setShowDeleteAlert(false);
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.resourceDelete = {};
    }
  }, [authReducer.resourceDelete]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceActivate)) {
      refreshPage();
      authReducer.resourceActivate = {};
    }
  }, [authReducer.resourceActivate]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceDeactivate)) {
      refreshPage();
      authReducer.resourceDeactivate = {};
    }
  }, [authReducer.resourceActivate]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.resourceDelete)) {
      setShowDeleteAlert(false);
      refreshPage();
    }
  });

  const handleCheckboxClick = (e, value = null) => {
    setSelectedResource(value);
    setAttachButtonDisable(false);
  };

  const confirmDelete = () => {
    setShowDeleteAlert(true);
  };

  const cancelConfirmDelete = () => {
    setShowDeleteAlert(false);
  };
  const handlePageChange = (event, page) => {
    setLoaders({ showLoader: true, loadingText: "Loading resources." });
    resourceGet(page, PAGE_SIZE, searchValue);
  }
  const handleDeleteResourceClick = () => {
    var selectService = selectedResource;
    if (selectService.length) {
      let payload = {
        name: selectedResource
      };
      resourceDelete(payload);
      setLoaders({ showLoader: true, loadingText: "Deleting the resource..." });
    } else {
      setAlertMsg({ msg: "It can't be delete", type: "error" });
      setShowAlert(true);
    }
  };

  const handleActivateResource = (row) => {
    let payload = { name: row.name, status: "active" };
    setLoaders({ showLoader: true, loadingText: `Activating the resource ${row.name}` });
    activateResource(payload);
  }

  const handleDeactivateResource = (row) => {
    let payload = { name: row.name, status: "deactive" };
    setLoaders({ showLoader: true, loadingText: `Dectivating the resource ${row.name}` });
    deActivateResource(payload);
  }

  const doSearch = () => {
    resourceGet(1, PAGE_SIZE, searchValue);
  }

  return (
    <div className={"nav-route-container"} style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert &&
        showAlert === true &&
        alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Resources</Typography>
              </Breadcrumbs>
            </div>
            <div className="horizontal-line"></div>
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box sx={{ "& .MuiTextField-root": { m: 1, width: "100%", maxWidth: "215px", marginLeft: "10px" } }}>
                    <TextField
                      id="search"
                      label="Search"
                      variant="outlined"
                      placeholder="Type something"
                      size="small"
                      style={{paddingRight: '10px'}}
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                      InputLabelProps={{ className: "serachLabel" }}
                      InputProps={{
                        className: "serachBar",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ position: "absolute", right: "12px" }}
                              className="cursorPointer" onClick={doSearch}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <Button
                    className="add-user-button"
                    onClick={() =>
                      history.push("/dashboard/resources/add-resource")
                    }
                  >
                    {"Add"}
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    className="add-user-button"
                    onClick={() => {
                      history.push({
                        pathname: "/dashboard/resources/update-resource",
                        state: { demo: selectedResource },
                      });
                    }}
                    disabled={attachButtonDisable}
                  >
                    {"Update"}
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                      marginLeft: "10px",
                    }}
                    className="delete-user-button"
                    onClick={() => {
                      confirmDelete();
                    }}
                    disabled={attachButtonDisable}
                  >
                    {"Delete"}
                  </Button>
                </div>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    backgroundColor: "white",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {resourceData.length != 0 && authReducer.loading === false ? (
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingLeft: "5px",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <Table aria-label="simple table" size="small">
                          <TableHead style={{ background: "#ecf5ff" }}>
                            <TableRow style={{ height: "40px" }}>
                              <TableCell className="tableBorderRightH headerCell">Name</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Type</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Is All</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Description</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Supported Sub Types</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Created At</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Updated At</TableCell>
                              <TableCell className="tableBorderRightH headerCell">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resourceData.map((row, i) => (
                              <TableRow key={row.name + i} style={{ height: "40px" }} hover>
                                <TableCell className="tableBorderRightH" align="left" style={{ color: "#384089" }}>
                                  <Checkbox
                                    onClick={(event) => {
                                      handleCheckboxClick(event, row.name);
                                    }}
                                    role="checkbox"
                                    classes={{
                                      root: classes.CheckBoxroot,
                                      checked: classes.checked,
                                    }}
                                    checked={
                                      selectedResource && selectedResource === row.name ? true : false
                                    }
                                  />
                                  <span
                                    onClick={() =>
                                      history.push({
                                        pathname: "/dashboard/resources/summary",
                                        state: {
                                          demo: row.name,
                                          demo1: "K4Mobility",
                                        },
                                      })
                                    }
                                    style={{
                                      marginLeft: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {row.name}
                                  </span>
                                </TableCell>
                                <TableCell className="tableBorderRightH" align="center">{row.type}</TableCell>
                                <TableCell className="tableBorderRightH">{JSON.stringify(row.is_all).toUpperCase()}</TableCell>
                                <TableCell className="tableBorderRightH">{row.description}</TableCell>
                                <TableCell className="tableBorderRightH">{row.sub_types ? row.sub_types.toString() : ""}</TableCell>
                                <TableCell className="tableBorderRightH" align="center">{moment(row.createdAt).format("yyyy-MM-D LTS")}</TableCell>
                                <TableCell className="tableBorderRightH" align="center">{row.updatedAt != null ? moment(row.updatedAt).format("yyyy-MM-D LTS") : '-'}</TableCell>
                                <TableCell className="tableBorderRightH">
                                  {row.isActive ? (
                                    <img className="icon-cursor" src={enable} style={{ height: "20px", width: "30px" }} title="Activate resource"
                                      onClick={() => {
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: "Deactivate resource",
                                          subTitle: `Are you sure! you want to deactivate resource ${row.name}`,
                                          onConfirm: () => {
                                            handleDeactivateResource(row);
                                          },
                                        });
                                      }}
                                    />
                                  ) : (
                                    <img className="icon-cursor" src={disable} style={{ height: "20px", width: "30px" }} title="Deactivate resource"
                                      onClick={() => {
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: "Activate resource",
                                          subTitle: `Are you sure! you want to activate resource ${row.name}`,
                                          onConfirm: () => {
                                            handleActivateResource(row);
                                          },
                                        });
                                      }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Pagination count={pages} page={page} onChange={handlePageChange} />
                    </div>
                  ) : (
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "22px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No Resource Found
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "22px",
                  // fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "rgb(245 144 144)",
                }}
              >
                You are not authorized to access this feature.
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Resource Successfully Deleted"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <Dialog
        open={showDeleteAlert}
        TransitionComponent={Transition}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={cancelConfirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
                textAlign: "center",
              }}
            >
              Please confirm to delete selected resources.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={cancelConfirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  handleDeleteResourceClick();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching resources please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    resourceGet,
    resourceDelete,
    activateResource,
    deActivateResource
  })(Resources)
);
