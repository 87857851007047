import React, { useState, useEffect } from "react";
import { Grid, Typography, DialogContentText } from "@material-ui/core";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import { serviceFetch } from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import CustomLoader from "../../../Components/Loader/CustomLoader";

function ServiceSummary({
  history,
  authReducer,
  errorReducer,
  location,
  serviceFetch,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [detailPolicy, setDetailPolicy] = useState([]);
  const [sdName, setSdName] = useState("");
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    console.log("inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    serviceFetch(location.state.demo1, location.state.demo);
  }, []);

  useEffect(() => {
    setSummary(
      authReducer.serviceFetch.data &&
        authReducer.serviceFetch.data[0] &&
        authReducer.serviceFetch.data[0].servicefunctions
        ? authReducer.serviceFetch.data[0].servicefunctions
        : "-"
    );
  });

  return (
    <div className="add-user" style={{ overflow: "hidden", position: "fixed" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "2px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Service "}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;{"> " + location.state.demo + " > Summary"}
              </DialogContentText>
            </Grid>
            <br />
            {authReducer.loading === false ? (
              <Grid>
                <div className="innerDiv">
                  <pre>{JSON.stringify(summary, null, 2)}</pre>
                </div>
              </Grid>
            ) : (
              ""
            )}
            <br></br>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          history.push("/dashboard");
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching service details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    serviceFetch,
  })(ServiceSummary)
);
