// Before login
export const GET_ERRORS = "get_errors";
export const ADD_USER = "add_user";
export const LOGIN_USER = "login_user";
export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = 'reset_password';
export const CHANGE_PASSWORD = 'change_password';
export const AGREE_POLICY = 'agree_policy';
export const SET_LOADING = 'set_loading';

// Dashboard
export const DASHBOARD = "dashboard";
export const DASHBOARD_MENU_ID = "dashboardId";
export const FETCH_LOGINDATA = 'fetch_logindata';
export const UPDATE_USERDATA = 'update_userData';
export const LOGOUT_USER = 'logout_user';

//Users
export const INVITE_USER = "invite_user";
export const FETCH_USERS = "fetch_users";
export const DELETE_USERS = "delete_users";
export const RESEND_INVITE = 'resend_invite';
export const GET_USER_POLICIES = 'get_user_policies';
export const ATTACH_POLICIES = 'attach_policies';
export const NOT_ATTACHED_POLICIES = 'not_attached_policies';
export const DETACH_POLICIES = 'detachPolicies';

// OU 
export const OU_CREATE = 'ou_create';
export const OU_UPDATE = 'ou_update';
export const OU_DELETE = 'ou_delete';
export const OU_DERIVED = 'ou_derived';
export const ATTACH_USER = 'attach_user';
export const FETCH_OU = 'fetch_ou';
export const ORGANIZATIONUSER_USER = 'organizationuser_user';
export const ATTACH_USER_TO_ORGANIZATION = 'attach_user_to_organization';
export const ORGANIZATIONUSER_DETACH = 'organizationuser_detach';
export const CLEAR_USER_PERMISSION_CACHE = 'clear_user_permission_cache';
export const GET_OU_POLICIES = 'get_OU_policies';
export const DISPLAY_OU_POLICIES = 'display_OU_Policies';
export const DISPLAY_OU_PERMISSIONS = 'display_OU_Permissions';
export const DETACH_ORGANIZATION_POLICIES = 'detach_organization_policies';
export const GET_CHILD_OU_SERVICE = 'get_child_ou_service';
export const NOT_ATTACHED_OU_PERMISSIONS = 'notAttachedOuPermissions';
export const ATTACH_OU_PERMISSIONS = 'attachOuPermissions';
export const DETTACH_OU_PERMISSIONS = 'dettachOuPermissions';
export const DISPLAY_OU_RESOURCES = 'display_OU_Resources';
export const NOT_ATTACHED_OU_RESOURCES = 'notAttachOuResources';
export const ATTACH_OU_RESOURCES = 'attachOuResources';
export const DETTACH_OU_RESOURCES = 'dettachOuResources';
export const OU_ATTACHABLE_PERMISSIONS = 'ouAttachablePermissions'



// Services
export const SERVICE_GET = 'service_get';
export const SERVICE_CREATE = 'service_create';
export const SERVICE_UPDATE = 'service_update';
export const SERVICE_DELETE = 'service_delete';
export const SERVICE_FETCH = 'service_fetch';
export const SERVICE_PARENT_FETCH = 'service_parent_fetch';
export const SERVICE_FUNCTION_DELETE = 'service_function_delete';
export const SERVICE_FUNCTION_UPDATE = 'service_function_update';
export const SERVICE_FUNCTION_ADD = 'service_function_add';

// Group
export const GROUP_GET = 'group_get';
export const GROUP_CREATE = 'group_create';
export const GROUP_UPDATE = 'group_update';
export const GROUP_ORGANIZATION = 'group_organization';
export const GROUP_DETAILS = 'group_details';
export const GROUP_DELETE = 'group_delete';
export const USER_GROUP_ATTACH = 'user_group_attach';
export const GROUP_ATTACH_USER = 'group_attach_user';
export const GROUP_USER_GET = 'group_user_get';
export const DETACH_USERS_GROUP = 'detach_users_group';
export const GROUP_POLICY_GET = 'group_policy_get';
export const GROUP_POLICY_ATTACH_POLICIES = 'group_policy_attach_policies';
export const GROUP_POLICY_POLICY_ATTACH = 'group_policy_policy_attach';
export const DETACH_GROUP_POLICY = 'detach_group_policy';

// Policies
export const POLICIES_GET = 'policies_get';
export const POLICIES_CREATE = 'policies_create';
export const POLICIES_DELETE = 'policies_delete';
export const USER_POLICIES = 'user_policies';
export const POLICIES_DETACH = 'policies_detach';
export const POLICIES_FETCH = 'policies_fetch';
export const UPDATE_POLICIES = 'updatePolicies';
export const POLICY_DETAIL = 'policy_detail';
export const POLICY_UPDATE = 'policy_update';
export const GET_USER_ATTACH_POLICES = 'get_User_Attach_Policies';

// Token
export const REFRESH_TOKEN = 'refresh_token';

// Search bar
export const SEARCH_USER_LIST = 'search_user_list';
export const SEARCH_OU_LIST = 'search_ou_list';


// Resources
export const RESOURCE_GET = 'resource_get';
export const RESOURCE_CREATE = 'resource_create';
export const RESOURCE_UPDATE = 'resource_update';
export const RESOURCE_DELETE = 'resource_delete';
export const RESOURCE_FETCH = 'resource_fetch';
export const RESOURCE_PARENT_FETCH = 'resource_parent_fetch';
export const RESOURCE_ACTIVATE = 'resource_activate';
export const RESOURCE_DEACTIVATE = 'resource_deactivate';


