import React, { useState, useEffect } from "react";
import "./inviteUser.css";
import {
  Button
} from "@material-ui/core";
import CustomTypography from "../../Components/CustomTypography";
import CustomTextField from "../../Components/CustomTextField";
import CustomButton from "../../Components/CustomButton";
import TopHeader from "../../Components/TopHeader";
import DialogBox from "../../Components/DialogBox";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import CustomAlert from "../../Components/CustomAlert";
import { isValidEmail } from "../../utils/validation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { inviteUser } from "../../actions/Users/authenticate";
import CustomLoader from "../../Components/Loader/CustomLoader";

function InviteUser({ inviteUser, authReducer, errorReducer, history, location }) {
  const [email, setEmail] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.inviteUser)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.inviteUser = {};
    }
  }, [authReducer.inviteUser]);

  useEffect(() => {
    if (errorReducer && errorReducer.description && errorReducer.description !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      })
      setShowAlert(true);
      errorReducer.description = "";
    }
  }, [errorReducer]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInviteClient = () => {
    if (_.isEmpty(email)) {
      setAlertMsg({
        msg: "Please Enter a Email Address",
        type: "error",
      });
      setShowAlert(true);
    } else if (!isValidEmail(email)) {
      setAlertMsg({
        msg: "Please Enter a Valid Email Address",
        type: "error",
      });
      setShowAlert(true);
    }
    else {
      let payload = { userName: email };
      if (location && location.state && location.state.demo) {
        payload.ouName = location.state.demo
      }
      inviteUser(payload);
      setLoaders({
        showLoader: true,
        loadingText: "Sending the E-mail",
      })
    }
  };

  return (
    <div className="invite-user">
      <TopHeader />
      {
        showAlert && showAlert === true ? (
          <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
        ) : (
          ""
        )
      }
      <Grid item container xs={12}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className="outerDiv">
          <div className="innerDiv">
            <CustomTypography
              size={20}
              weight="900"
              color="rgba(56, 64, 137, 1)"
              content="Send Invite"
              lineHeight={17.14}
            />
            <br></br>
            <CustomTextField
              value={email}
              id="outlined-basic"
              variant="outlined"
              label="Email Address"
              onChange={handleChange}
              charLength={40}
            /><br></br>
            <div style={{ marginTop: "1rem" }}>
              <Grid item container xs={12} md={4} spacing={2} >
                <Grid item>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                      width: "70px"
                    }}
                    className="delete-user-button"
                    onClick={() => history.goBack()}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    ButtonType=""
                    ButtonLabel="Submit"
                    loading
                    onClick={handleInviteClient}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle={"A verification link has been sent to the email id " + email}
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
        }}
      />
      {loaders["showLoader"] ? (
        <CustomLoader showLoader={loaders["showLoader"]} loadingText={loaders["loadingText"] ? loaders["loadingText"] : "Please wait..."} />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    inviteUser,
  })(InviteUser)
);
