import React, { useState, useEffect } from "react";
import { Grid, DialogContentText, Button } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./Rename.css";
import SideMenu from "../../../Components/SideMenu";
import { ouUpdate, fetchOu } from "../../../actions/Users/authenticate";
import CustomLoader from "../../../Components/Loader/CustomLoader";

const scroll = Scroll.animateScroll;

function Rename({
  history,
  authReducer,
  errorReducer,
  ouUpdate,
  fetchOu,
  location
}) {
  const [OrganizationalUnitName, setOrganizationalUnitName] = useState("");
  const [OrganizationalId, setOrganizationalId] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [parentOUName, setparentOUName] = useState("");
  const [value, setValue] = useState("");
  const [notErrorInFields, setNotErrorInFields] = useState({
    OrganizationalUnitName: true
  });
  const [allFieldsIsValidORnot, setAllFieldsIsValidORnot] = useState(false);
  const [helperTextList, setHelperTextList] = useState({
    OrganizationalUnitName: "Name is invalid"
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.ouUpdate)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.ouUpdate = {};
    }
  }, [authReducer.ouUpdate]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    fetchOu(location.state.demo);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.fetchOu.data)) {
      setOrganizationalUnitName(
        authReducer.fetchOu.data.ou.name
          ? authReducer.fetchOu.data.ou.name
          : "-"
      );
      setOrganizationalId(
        authReducer.fetchOu.data.ou.id
          ? authReducer.fetchOu.data.ou.id
          : "-"
      );

      let parentOUName =
        authReducer.fetchOu.data && authReducer.fetchOu.data.parentOUName
          ? authReducer.fetchOu.data.parentOUName
          : "-";

      let ouName = authReducer.fetchOu.data.ou.name
        ? authReducer.fetchOu.data.ou.name
        : "-";

      setparentOUName(parentOUName);

      let payload = {
        parentOUName: parentOUName,
      };
    }
  }, [authReducer.fetchOu]);

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "OrganizationalUnitName") {
      const re = /^[a-zA-Z0-9\s-\s_ ]+$/;
      notErrorInFields["OrganizationalUnitName"] = re.test(event.target.value);
      setOrganizationalUnitName(event.target.value);
      setNotErrorInFields(notErrorInFields);
      valueIsVlidOrNot(re.test(event.target.value));
    }
    if (_.isEmpty(OrganizationalUnitName)) {
      setAllFieldsIsValidORnot(false);
    }
  };

  const valueIsVlidOrNot = (value) => {
    setAllFieldsIsValidORnot(value);
  };

  const handleUpdateOu = () => {
    scroll.scrollToTop();
    if ( _.isEmpty(OrganizationalUnitName)) {
      setAlertMsg({ msg: "Please Enter All the Mandatory Fields", type: "error" });
      setShowAlert(true);
    } else {
      let payload = {
        newName: OrganizationalUnitName,
        name: location.state.demo,
        parentOUName: parentOUName,
      };
      ouUpdate(payload);
      setLoaders({ showLoader: true, loadingText: "Updating..." });
    }
  };

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.push("/dashboard/OU/user-account")}
              >
                {"Organizational Unit"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;
                {" > " + location.state.demo + " > Update"}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content={"Organizational ID: " + " " + OrganizationalId}
                  lineHeight={17.14}
                />
                <div className="horizontal-line"></div>
                <CustomTypography
                  size={18}
                  weight="400"
                  color="#5A5757"
                  content="Organizational Unit Name"
                  lineHeight={19}
                />
                <br></br>
                <CustomTextField
                  label=""
                  name="OrganizationalUnitName"
                  value={OrganizationalUnitName}
                  onChange={handleChange}
                  charLength={50}
                  isValidOrInvalid={
                    notErrorInFields["OrganizationalUnitName"]
                      ? ""
                      : "isInvalid"
                  }
                  isValidOrInvalidMessage={
                    helperTextList["OrganizationalUnitName"]
                  }
                />
                <br></br>
                <Grid item container xs={12} md={4} spacing={2}>
                  <Button
                    style={{ width: "150px" }}
                    onClick={handleUpdateOu}
                    // disabled={!allFieldsIsValidORnot}
                  >
                    {"Update"}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Organization Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    ouUpdate,
    fetchOu
  })(Rename)
);
