import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog, DialogContent, DialogContentText, DialogTitle, Slide
} from "@material-ui/core";
import { primaryColor, pureWhite, secondryColor } from '../../constants/Color'
import { Close as CloseIcon } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    CheckBoxroot: {
        fontSize: "13px",
        padding: "0px",
        color: "#384089",
        "&$checked": {
            color: "#384089",
        },
    },
    checked: {},
    rootDialog: {
        flexGrow: 1,
        padding: "5px 10px",
    },
    heading: {
        fontWeight: "500 !important",
        fontSize: "15px !important",
        color: "#FFF !important",
        paddingTop: "6px"
    },
    secondaryOutlineButton: {
        background: `transparent !important`,
        color: `${secondryColor} !important`,
        textTransform: "none !important",
        border: `1px solid ${secondryColor} !important`
    },
    primaryOutlineButton: {
        background: `${primaryColor} !important`,
        color: `${pureWhite} !important`,
        textTransform: "none !important",
    },
}));

export default function ConfirmDialogBox(props) {
    const classes = useStyles();
    return (
        <Dialog
            open={props.deleteConfirm}
            TransitionComponent={Transition}
            onClose={props.closeDialog}
            fullWidth={true}
            maxWidth={props.boxWidth ? props.boxWidth : "xs"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            position={'bottom'}
        >
            <DialogContent style={{ padding: "0px" }}>
                <DialogTitle className={classes.rootDialog} style={{ background: primaryColor }} id="alert-dialog-slide-title">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className={classes.heading}> <span>{props.dialogTitle}</span> </div>
                        <div onClick={props.closeDialog} title="Close" style={{ marginTop: "3px", cursor: "pointer", color: pureWhite }}>
                            <CloseIcon
                            />
                        </div>
                    </div>
                </DialogTitle>

                <DialogContentText id="alert-dialog-slide-description">
                    <div style={{ color: primaryColor, marginTop: "30px", textAlign: "center", padding: "0px 30px" }}>
                        <span>{props.dialogContentText}</span> 
                    </div>
                </DialogContentText>

                <br />
                <div style={{ display: "flex", justifyContent: "flex-end", marginRight:"15px" }}>
                    <div style={{marginRight:"15px"}}>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.secondaryOutlineButton}
                            title="Click No to Cancel"
                            onClick={props.closeDialog}
                        >
                            No
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            className={classes.primaryOutlineButton}
                            title="Click Yes to Confirm"
                            onClick={(e) => { props.confirmDeleteSelectedData(e) }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
                <br />
            </DialogContent>
        </Dialog>
    );
}
