import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import {
  GET_ERRORS,
  INVITE_USER,
  ADD_USER,
  LOGIN_USER,
  DASHBOARD,
  FETCH_USERS,
  DELETE_USERS,
  FORGOT_PASSWORD,
  FETCH_LOGINDATA,
  RESET_PASSWORD,
  UPDATE_USERDATA,
  LOGOUT_USER,
  RESEND_INVITE,
  OU_CREATE,
  OU_UPDATE,
  OU_DELETE,
  OU_DERIVED,
  REFRESH_TOKEN,
  ATTACH_USER,
  ORGANIZATIONUSER_USER,
  ATTACH_USER_TO_ORGANIZATION,
  ORGANIZATIONUSER_DETACH,
  CLEAR_USER_PERMISSION_CACHE,
  CHANGE_PASSWORD,
  SERVICE_GET,
  SERVICE_DELETE,
  SERVICE_CREATE,
  SERVICE_UPDATE,
  SERVICE_FETCH,
  FETCH_OU,
  POLICIES_GET,
  POLICIES_CREATE,
  POLICIES_DELETE,
  USER_POLICIES,
  POLICIES_DETACH,
  POLICIES_FETCH,
  GET_USER_ATTACH_POLICES,
  GET_USER_POLICIES,
  AGREE_POLICY,
  UPDATE_POLICIES,
  ATTACH_POLICIES,
  POLICY_DETAIL,
  POLICY_UPDATE,
  NOT_ATTACHED_POLICIES,
  DETACH_POLICIES,
  DISPLAY_OU_POLICIES,
  DISPLAY_OU_PERMISSIONS,
  NOT_ATTACHED_OU_PERMISSIONS,
  ATTACH_OU_PERMISSIONS,
  DETTACH_OU_PERMISSIONS,
  DISPLAY_OU_RESOURCES,
  NOT_ATTACHED_OU_RESOURCES,
  ATTACH_OU_RESOURCES,
  DETTACH_OU_RESOURCES,
  GET_OU_POLICIES,
  GROUP_GET,
  GROUP_CREATE,
  GROUP_UPDATE,
  GROUP_ORGANIZATION,
  GROUP_DETAILS,
  GROUP_DELETE,
  DETACH_ORGANIZATION_POLICIES,
  USER_GROUP_ATTACH,
  GROUP_ATTACH_USER,
  GROUP_USER_GET,
  DETACH_USERS_GROUP,
  GROUP_POLICY_GET,
  GROUP_POLICY_ATTACH_POLICIES,
  GROUP_POLICY_POLICY_ATTACH,
  DETACH_GROUP_POLICY,
  GET_CHILD_OU_SERVICE,
  SET_LOADING,
  SERVICE_PARENT_FETCH,
  RESOURCE_GET,
  RESOURCE_DELETE,
  RESOURCE_CREATE,
  RESOURCE_UPDATE,
  RESOURCE_FETCH,
  RESOURCE_ACTIVATE,
  RESOURCE_DEACTIVATE,
  OU_ATTACHABLE_PERMISSIONS,
  SERVICE_FUNCTION_DELETE,
  SERVICE_FUNCTION_UPDATE,
  SERVICE_FUNCTION_ADD
} from "../types";
// import ConsoleHelper from "../../utils/consoleHelper";

export const inviteUser = (formData) => (dispatch) => {
  api
    .postRequest("/user/invite", formData)
    .then((res) => handleResponse(res, dispatch, INVITE_USER, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const resendInvite = (formData) => (dispatch) => {
  api
    .postRequest("/user/resendInvite", formData)
    .then((res) => handleResponse(res, dispatch, RESEND_INVITE, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const addUserAPI = (formData) => (dispatch) => {
  api
    .postRequestOut("/user/verify/v2", formData)
    .then((res) => handleResponse(res, dispatch, ADD_USER, false))
    .catch((err) => handleError(err, dispatch, false));
};


export const loginAPI = (formData) => (dispatch) => {
  api
    .postRequestOut("/user/login/v2", formData)
    .then((res) => handleResponse(res, dispatch, LOGIN_USER, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const policyAPI = (formData, redirectUrl) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest("/policy/getUserPolicies?include_resources=true&userName=" + formData["userName"])
    .then((res) => handleResponse(res, dispatch, AGREE_POLICY, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const logoutUser = (logoutPayload) => (dispatch) => {
  api
    .postRequest("/user/logout", logoutPayload)
    .then((res) => handleResponse(res, dispatch, LOGOUT_USER, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const dashboardAPI = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest("/dashboard")
    .then((res) => handleResponse(res, dispatch, DASHBOARD, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const fetchUserListAPI = (page, limit, filterBy, search) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/user/info?page=${page}&limit=${limit}&filterBy=${filterBy}&search=${search}`)
    .then((res) => handleResponse(res, dispatch, FETCH_USERS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const fetchUserDetails = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/user/details`)
    .then((res) => handleResponse(res, dispatch, FETCH_LOGINDATA, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const deleteUserAPI = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/user", formData)
    .then((res) => handleResponse(res, dispatch, DELETE_USERS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const forgotPassword = (formData) => (dispatch) => {
  api
    .postRequestOut("/user/recovery", formData)
    .then((res) => handleResponse(res, dispatch, FORGOT_PASSWORD, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const updateUserData = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/user/details/v2", formData)
    .then((res) => handleResponse(res, dispatch, UPDATE_USERDATA, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const resetPassword = (formData) => (dispatch) => {
  api
    .putRequest("/user/recovery/v2", formData)
    .then((res) => handleResponse(res, dispatch, RESET_PASSWORD, false))
    .catch((err) => handleError(err, dispatch, false));
};

// OU API's

export const ouCreate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/ou", formData)
    .then((res) => handleResponse(res, dispatch, OU_CREATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const ouUpdate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/OU", formData)
    .then((res) => handleResponse(res, dispatch, OU_UPDATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const ouDelete = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/OU", formData)
    .then((res) => handleResponse(res, dispatch, OU_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const ouDerived = (loggedInUser) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/ou/derived`)
    .then((res) => handleResponse(res, dispatch, OU_DERIVED, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const attachUserToOrganization = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/organizationuser/attachUser", formData)
    .then((res) => handleResponse(res, dispatch, ATTACH_USER_TO_ORGANIZATION, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const getAttachUser = (organizationName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/organizationuser/attachUser?ouName=${organizationName}`)
    .then((res) => handleResponse(res, dispatch, ATTACH_USER, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const organizationuserUser = (organizationName, page, limit, filterBy, search) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/organizationuser/user?ouName=${organizationName}&page=${page}&limit=${limit}&filterBy=${filterBy}&search=${search}`)
    .then((res) => handleResponse(res, dispatch, ORGANIZATIONUSER_USER, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const organizationuserDetach = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/organizationuser/dettach", formData)
    .then((res) => handleResponse(res, dispatch, ORGANIZATIONUSER_DETACH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const clearUserPermissionCache = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/policy/clearUserPermissionCache", formData)
    .then((res) => handleResponse(res, dispatch, CLEAR_USER_PERMISSION_CACHE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const refreshToken = (formData) => (dispatch) => {
  api
    .postRequest("/token", formData)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("Refresh Token", JSON.stringify(res.data));
        let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        loggedInUser.refresh_token = JSON.parse(
          res.data.message.message
        ).refresh_token;
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
        dispatch({ type: REFRESH_TOKEN, payload: res.data });
      } else {
        dispatch({ type: GET_ERRORS, payload: res });
      }
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: { message: err.message } });
    });
};

export const changePassword = (formData) => (dispatch) => {
  api
    .patchRequest("/user/changePassword/v2", formData)
    .then((res) => handleResponse(res, dispatch, CHANGE_PASSWORD, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const serviceGet = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/service?ouName=${ouName}`)
    .then((res) => handleResponse(res, dispatch, SERVICE_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const serviceCreate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/service", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_CREATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const serviceUpdate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/service", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_UPDATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const serviceDelete = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/service", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const serviceFetch = (ouName, name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/service/details?ouName=${ouName}&name=${name}`)
    .then((res) => handleResponse(res, dispatch, SERVICE_FETCH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const serviceParentFetch = (ouName, name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/service/parentDetails?ouName=${ouName}&name=${name}`)
    .then((res) => handleResponse(res, dispatch, SERVICE_PARENT_FETCH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const fetchOu = (name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/details?name=${name}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, FETCH_OU, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const policiesGet = (ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/organizationpolicy?ouName=${ou}`)
    .then((res) => handleResponse(res, dispatch, POLICIES_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const policiesCreate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/oupolicy", formData)
    .then((res) => handleResponse(res, dispatch, POLICIES_CREATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const policiesDelete = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/oupolicy", formData)
    .then((res) => handleResponse(res, dispatch, POLICIES_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const userPolicies = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/policy/attachUsers", formData)
    .then((res) => handleResponse(res, dispatch, USER_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const policiesFetch = (ou, policy) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/policy/detachUsers?ouName=${ou}&policy=${policy}`)
    .then((res) => handleResponse(res, dispatch, POLICIES_FETCH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const policiesDetach = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/policy/detachUsers", formData)
    .then((res) => handleResponse(res, dispatch, POLICIES_DETACH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const getUserAttachPolicies = (ou, policy) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/policy/attachUsers?ouName=${ou}&policy=${policy}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GET_USER_ATTACH_POLICES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const getUserPolicies = (userName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/policy/displayUserPolicies?userName=${userName}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GET_USER_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const updatePolicies = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/oupolicy", formData)
    .then((res) => handleResponse(res, dispatch, UPDATE_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const attachPolicies = (formData) => (dispatch) => {
  api
    .postRequest("/policy/attachPolicies", formData)
    .then((res) => handleResponse(res, dispatch, ATTACH_POLICIES, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const policyDetail = (ouName, name, haveLoader) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/oupolicy/details?ouName=${ouName}&name=${name}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, POLICY_DETAIL, haveLoader))
    .catch((err) => handleError(err, dispatch, true));
};

export const policyUpdate = (formData) => (dispatch) => {
  api
    .putRequest("/oupolicy", formData)
    .then((res) => handleResponse(res, dispatch, POLICY_UPDATE, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const notAttachedPolicies = (userName, ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  if(ouName == undefined) {
    ouName = ""
  }
  let url = `/organizationpolicy/displayApplicableUserPolicies?userName=${userName}&ouName=${ouName}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, NOT_ATTACHED_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const detachPolicies = (formData) => (dispatch) => {
  api
    .deleteRequest("/policy/detachPolicies", formData)
    .then((res) => handleResponse(res, dispatch, DETACH_POLICIES, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const getOUPolicies = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/organizationpolicy/getOUPolicies?ouName=${ouName}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GET_OU_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const displayOUPolicies = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/organizationpolicy/displayOUPolicies?ouName=${ouName}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, DISPLAY_OU_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const displayOUPermissions = (name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/permissions?name=${name}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, DISPLAY_OU_PERMISSIONS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const dettachOuPermissions = (payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/permissions/dettach`;
  api
    .postRequest(url, payload)
    .then((res) => handleResponse(res, dispatch, DETTACH_OU_PERMISSIONS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const displayOUResources = (ouName, page, limit) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/resources?ouName=${ouName}&page=${page}&limit=${limit}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, DISPLAY_OU_RESOURCES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const dettachOuResources = (payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/resources/dettach`;
  api
    .postRequest(url, payload)
    .then((res) => handleResponse(res, dispatch, DETTACH_OU_RESOURCES, true))
    .catch((err) => handleError(err, dispatch, true));
};


export const GroupGet = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest("/groups")
    .then((res) => handleResponse(res, dispatch, GROUP_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const GroupCreate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/groups", formData)
    .then((res) => handleResponse(res, dispatch, GROUP_CREATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const GroupUpdate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/groups", formData)
    .then((res) => handleResponse(res, dispatch, GROUP_UPDATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsOrganization = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/groups/organization?ouName=${ouName}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_ORGANIZATION, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsDetails = (name, ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/groups/details?name=${name}&ouName=${ou}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_DETAILS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupDelete = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/groups", formData)
    .then((res) => handleResponse(res, dispatch, GROUP_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const detachOrganizationPolicies = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/organizationPolicy", formData)
    .then((res) => handleResponse(res, dispatch, DETACH_ORGANIZATION_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const UserGroupAttach = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/usergroup/attachUsers", formData)
    .then((res) => handleResponse(res, dispatch, USER_GROUP_ATTACH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsAttachUser = (name, ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/usergroup/attachUsers?name=${name}&ouName=${ou}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_ATTACH_USER, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsUserGet = (name, ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/usergroup/detachUsers?name=${name}&ouName=${ou}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_USER_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const detachUserGroup = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/usergroup/detachUsers", formData)
    .then((res) => handleResponse(res, dispatch, DETACH_USERS_GROUP, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsPolicyGet = (name, ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/grouppolicy?name=${name}&ouName=${ou}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_POLICY_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const groupsPolicyAttachPolicies = (name, ou) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/grouppolicy/attachPolicies?name=${name}&ouName=${ou}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, GROUP_POLICY_ATTACH_POLICIES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const GroupPolicyPolicyAttach = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/grouppolicy/attachPolicies", formData)
    .then((res) => handleResponse(res, dispatch, GROUP_POLICY_POLICY_ATTACH, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const detachGroupPolicy = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/grouppolicy/detachPolicies", formData)
    .then((res) => handleResponse(res, dispatch, DETACH_GROUP_POLICY, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const getChildOuServices = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/service/getChildOuServices?ouName=${ouName}`)
    .then((res) => handleResponse(res, dispatch, GET_CHILD_OU_SERVICE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const notAttachedOuPermissions = (name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/permissions/attach?name=${name}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, NOT_ATTACHED_OU_PERMISSIONS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const attachOuPermissions = (formData) => (dispatch) => {
  api
    .postRequest("/ou/permissions/attach", formData)
    .then((res) => handleResponse(res, dispatch, ATTACH_OU_PERMISSIONS, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const resourceGet = (page, limit, search) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/resource?page=${page}&limit=${limit}&search=${search}`)
    .then((res) => handleResponse(res, dispatch, RESOURCE_GET, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const resourceCreate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/resource", formData)
    .then((res) => handleResponse(res, dispatch, RESOURCE_CREATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const resourceFetch = (name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/resource/details?name=${name}`)
    .then((res) => handleResponse(res, dispatch, RESOURCE_FETCH, true))
    .catch((err) => handleError(err, dispatch, true));
};


export const resourceDelete = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/resource", formData)
    .then((res) => handleResponse(res, dispatch, RESOURCE_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const activateResource = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/resource/status", formData)
    .then((res) => handleResponse(res, dispatch, RESOURCE_ACTIVATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const deActivateResource = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/resource/status", formData)
    .then((res) => handleResponse(res, dispatch, RESOURCE_DEACTIVATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const resourceUpdate = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/resource", formData)
    .then((res) => handleResponse(res, dispatch, RESOURCE_UPDATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const notAttachedOuResources = (name) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  let url = `/ou/resources/attach?name=${name}`;
  api
    .getRequest(url)
    .then((res) => handleResponse(res, dispatch, NOT_ATTACHED_OU_RESOURCES, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const attachOuResources = (formData) => (dispatch) => {
  api
    .postRequest("/ou/resources/attach", formData)
    .then((res) => handleResponse(res, dispatch, ATTACH_OU_RESOURCES, false))
    .catch((err) => handleError(err, dispatch, false));
};

export const getOuAttachablePermissions = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/oupolicy/attachable?ouName=${ouName}`)
    .then((res) => handleResponse(res, dispatch, OU_ATTACHABLE_PERMISSIONS, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const deleteServiceFunction = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest("/servicefunction", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_FUNCTION_DELETE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const updateServiceFunction = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .putRequest("/servicefunction", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_FUNCTION_UPDATE, true))
    .catch((err) => handleError(err, dispatch, true));
};

export const addServiceFunction = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/servicefunction", formData)
    .then((res) => handleResponse(res, dispatch, SERVICE_FUNCTION_ADD, true))
    .catch((err) => handleError(err, dispatch, true));
};