import React, { useState, useEffect } from "react";
import { Grid, Paper, Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomButton from "../../../../Components/CustomButton";
import CustomLoader from "../../../../Components/Loader/CustomLoader";
import CustomAlert from "../../../../Components/CustomAlert";
import DialogBox from "../../../../Components/DialogBox";
import { displayOUResources, dettachOuResources } from "../../../../actions/Users/authenticate";
import Pagination from '@mui/material/Pagination';
import _ from "lodash";
import "../OuUser.css";
import { PAGE_SIZE } from "../../../../constants/Constants";

function OuUser({ history, authReducer, errorReducer, location, displayOUResources, dettachOuResources }) {
  const [dettachOuResourcePopUp, setDettachOuResourcePopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [alertMessage, setAlertMessage] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [ouResourcesData, setOuResourcesData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    displayOUResources(location.state.demo, 1, PAGE_SIZE);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.dettachOuResources)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setDettachOuResourcePopUp(true);
      authReducer.dettachOuResources = {};
    }
  }, [authReducer.dettachOuResources]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setAlertMessage({ msg: errorReducer.message, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.displayOUResources)
      && authReducer.displayOUResources.data
      && authReducer.displayOUResources.data.records) {
      const data = authReducer.displayOUResources.data;
      setOuResourcesData(data.records);
      setPage(data.page);
      setPages(data.pages);
      setTotal(data.total);
    }
  }, [authReducer.displayOUResources]);

  function refreshPage() {
    window.location.reload(false);
  }

  const handleOuResourcePageChange = (event, page) => {
    setLoaders({ showLoader: true, loadingText: "Loading organisation resources." });
    displayOUResources(location.state.demo, page, PAGE_SIZE);
  }

  const _dettachOuResources = (item) => {
    let payload = {
      name: location.state.demo,
      resources: [item.name]
    };
    dettachOuResources(payload);
    setLoaders({ showLoader: true, loadingText: "Dettaching resource..." });
  }

  return (
    <div style={{ width: "100%" }}>
      {
        alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
          <div>
            <Grid
              container
              item
              style={{ marginTop: "10px", marginLeft: "15px", display: "flex" }}
            >
              <CustomButton
                style={{ align: 'right' }}
                ButtonType=""
                ButtonLabel="Add Resources"
                onClick={() =>
                  history.push({
                    pathname: "/dashboard/ou/attach-resources",
                    state: {
                      demo: location.state.demo
                    },
                  })
                }
              />
            </Grid>
            <div
              className="container"
              style={{ backgroundColor: "white" }}
            >
              <div
                className="row"
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  marginLeft: "4px",
                  marginRight: "15px",
                  // width: "678px",
                }}
              >
                <TableContainer component={Paper} style={{ width: "100%" }}>
                  <Table aria-label="simple table" size="small">
                    <TableHead style={{ background: "#ecf5ff" }}>
                      <TableRow style={{ height: "40px" }}>
                        <TableCell className="tableBorderRightH headerCell">Name</TableCell>
                        <TableCell className="tableBorderRightH headerCell">Type</TableCell>
                        <TableCell className="tableBorderRightH headerCell">Is All</TableCell>
                        <TableCell align="left" className="tableBorderRightH headerCell">Description</TableCell>
                        <TableCell align="left" className="tableBorderRightH headerCell">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ouResourcesData.map((row, i) => (
                        <TableRow key={row.name + i} style={{ height: "40px" }}>
                          <TableCell className="tableBorderRightH" align="left" style={{ color: "#384089" }}>
                            <span
                              onClick={() =>
                                history.push({
                                  pathname:
                                    "/dashboard/resources/view-details",
                                  state: {
                                    demo: row.name
                                  },
                                })
                              }
                              style={{
                                marginLeft: "8px",
                                cursor: "pointer",
                              }}
                            >
                              {row.name}
                            </span>
                          </TableCell>
                          <TableCell className="tableBorderRightH" align="left">{row.type}</TableCell>
                          <TableCell className="tableBorderRightH" align="left">{JSON.stringify(row.is_all).toUpperCase()}</TableCell>
                          <TableCell className="tableBorderRightH" align="left">{row.description}</TableCell>
                          <TableCell className="tableBorderRightH" align="left">
                            <CustomButton ButtonType="" Disabled= {'K4Mobility' === location.state.demo} ButtonLabel="Remove" onClick={() => _dettachOuResources(row)} />
                          </TableCell>
                        </TableRow>
                      ))}
                      {
                        false === authReducer.loading && 0 === ouResourcesData.length ? (
                          <TableRow >
                            <TableCell className="tableBorderRightH" align="center" colSpan={5}>No resources found.</TableCell>
                          </TableRow>
                        ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                {
                  false === authReducer.loading && 0 !== ouResourcesData.length ? (
                    <Pagination style={{marginTop: 10}} count={pages} page={page} onChange={handleOuResourcePageChange} />
                  ) : null
                }
              </div>
            </div>
          </div>
        ) : (
          <Grid
            container
            style={{
              alignItems: "center",
              display: "flex",
              fontSize: "22px",
              // fontWeight: "600",
              marginTop: "15%",
              backgroundColor: "white",
              padding: "10px",
              flexDirection: "column",
              color: "rgb(245 144 144)",
            }}
          >
            You are not authorized to access this feature.
          </Grid>
        )
      }

      <DialogBox
        Open={dettachOuResourcePopUp}
        onClose={() => setDettachOuResourcePopUp(false)}
        DialogTitle="Organisation resouce dettached successfully."
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      {
        authReducer.loading ? (
          <CustomLoader
            showLoader={true}
            loadingText={
              loaders["loadingText"]
                ? loaders["loadingText"]
                : "Fetching organization details please wait..."
            }
          />
        ) : null
      }
    </div >
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    displayOUResources,
    dettachOuResources
  })(OuUser)
);
