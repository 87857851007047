import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Paper, Button, DialogContentText } from "@material-ui/core";
import "./AttachPermissionGroup.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { groupsPolicyAttachPolicies, GroupPolicyPolicyAttach } from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomAlert from "../../../Components/CustomAlert";
import _ from "lodash";
import DialogBox from "../../../Components/DialogBox";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function AttachPermissionGroup({
  // history,
  GroupPolicyPolicyAttach,
  authReducer,
  errorReducer,
  groupsPolicyAttachPolicies,
  location,
  match,
}) {
  const classes = useStyles();
  let history = useHistory();
  const { userName } = match.params;
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    groupsPolicyAttachPolicies(location.state.demo, location.state.demo1);
  }, []);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({ msg: errorReducer.description, type: "error" });
      setLoaders({ showLoader: false, loadingText: "" });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {

      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function createData(name, description, is_common) {
    return { name, description,is_common };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.groupsPolicyAttachPolicies.data)) {
      // console.log(
      //   "authReducer.groupsPolicyAttachPolicies-> ",
      //   authReducer.groupsPolicyAttachPolicies.data
      // );
    }
  }, [authReducer.groupsPolicyAttachPolicies.data]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.GroupPolicyPolicyAttach)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.GroupPolicyPolicyAttach = {};
    }
  }, [authReducer.GroupPolicyPolicyAttach]);

  const handleAttachUser = () => {
    if (
      authReducer.groupsPolicyAttachPolicies.data != null &&
      authReducer.groupsPolicyAttachPolicies.data.length > 0
    ) {
      if (selectedUsers.length > 0) {
        var thePolicyList = [...authReducer.groupsPolicyAttachPolicies.data];
        let payload = {
          ouName: location.state.demo1,
          policies: [],
          name: location.state.demo,
        };
        for (let i = 0; i < thePolicyList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (thePolicyList[i].hasOwnProperty("name")) {
              payload.policies.push(thePolicyList[i].name);
            }
          }
        }
        GroupPolicyPolicyAttach(payload);
        setLoaders({ showLoader: true, loadingText: "Attaching..." });
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  const _handlePolicyClick = (index) => {
    let indexList = [...selectedUsers];
    if (
      authReducer.groupsPolicyAttachPolicies.data != null &&
      authReducer.groupsPolicyAttachPolicies.data.length > 0
    ) {
      var thePolicy = [...authReducer.groupsPolicyAttachPolicies.data];
      for (let i = 0; i < thePolicy.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
            setAttachButtonDisable(false);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
            setAttachButtonDisable(false);
          }
        }
      }
    } else {
      return [];
    }
  };

  const _getUsers = () => {
    if (authReducer.groupsPolicyAttachPolicies.data != null) {
      var theUserList = authReducer.groupsPolicyAttachPolicies.data;
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name != null &&
            theUserList[i].name !== ""
              ? theUserList[i].name
              : "-";
          let u_description =
            theUserList[i].hasOwnProperty("description") &&
            theUserList[i].description != null &&
            theUserList[i].description !== ""
              ? theUserList[i].description
              : "-";
          let is_common =
              theUserList[i].hasOwnProperty("is_common") &&
              theUserList[i].is_common != null &&
              theUserList[i].is_common !== ""
                ? theUserList[i].is_common
                : "-";
          rows.push(createData(u_name, u_description, is_common));
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <div
      className={"nav-route-container"}
      style={{ overflow: "hidden", position: "fixed" }}
    >
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10} overflowY="scroll" className="outerDiv">
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowX: "hidden",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "auto",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignContent: "center",
                display: "flex",
                backgroundColor: "#FBFBFB",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Group"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;{" > " + location.state.demo + " > Attach Policies"}
              </DialogContentText>
            </div>
            <div className="horizontal-line"></div>
            <Grid
              container
              spacing={1}
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{ marginLeft: "8px", marginTop: "5px" }}
              >
                {/* <input
                  style={{
                    height: "30px",
                    width: "300px",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                  type="text"
                  placeholder="Search policy..."
                  onChange={(event) => {
                    searchUser(event.target.value);
                  }}
                /> */}
                <div
                  style={{
                    cursor: attachButtonDisable ? "not-allowed" : "pointer",
                  }}
                >
                  <Button
                    style={{ width: "100px" }}
                    disabled={attachButtonDisable}
                    onClick={handleAttachUser}
                  >
                    {"attach"}
                  </Button>
                </div>
              </Grid>
              {_getUsers().length != 0 && authReducer.loading === false ? (
                <div className="container" style={{ backgroundColor: "white" }}>
                  <div
                    className="row"
                    style={{ marginTop: "10px", padding: "10px" }}
                  >
                    <TableContainer component={Paper} style={{ width: "100%" }}>
                      <Table aria-label="simple table" size="small">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow style={{ height: "40px" }}>
                            <TableCell align="left" className="tableBorderRightH headerCell">Name</TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell">Is Global</TableCell>
                            <TableCell align="left" className="tableBorderRightH headerCell">Description</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(searchResult.length
                            ? searchResult
                            : _getUsers()
                          ).map((row, i) => (
                            <TableRow key={row.name} style={{ height: "40px" }} hover>
                              <TableCell className="tableBorderRightH" align="left" style={{ color: "#384089", fontSize: "15px" }}>
                                <Checkbox
                                  onClick={() => {
                                    _handlePolicyClick(i);
                                  }}
                                  role="checkbox"
                                  classes={{
                                    root: classes.CheckBoxroot,
                                    checked: classes.checked,
                                  }}
                                  checked={selectedUsers.includes(i)}
                                />
                                {row.name}
                              </TableCell>
                              <TableCell className="tableBorderRightH" align="left">{JSON.stringify(row.is_common).toUpperCase()}</TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid #E5E5E5",
                                  padding: "10px",
                                  fontSize: "15px",
                                }}
                                align="left"
                              >
                                {row.description}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <div className="horizontal-line"></div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      fontSize: "22px",
                      fontWeight: "600",
                      marginTop: "15%",
                      backgroundColor: "white",
                      padding: "10px",
                      flexDirection: "column",
                      color: "#5A5757",
                    }}
                  >
                    There is no policy available
                  </div>
                  <div
                    className="horizontal-line"
                    style={{ marginTop: "10px" }}
                  ></div>
                </div>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Policy Successfully Attached"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching policies please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    GroupPolicyPolicyAttach,
    groupsPolicyAttachPolicies,
  })(AttachPermissionGroup)
);
