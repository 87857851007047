import React, { useState, useEffect } from "react";
import { Grid, Button, DialogContentText, Checkbox,Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import Scroll from "react-scroll";
import CustomTypography from "../../../Components/CustomTypography";
import CustomTextField from "../../../Components/CustomTextField";
import CustomButton from "../../../Components/CustomButton";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import "./UpdateService.css";
import SideMenu from "../../../Components/SideMenu";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Close as CloseIcon } from "@material-ui/icons/";
// import AddIcon from "@material-ui/icons/Add";
import {
  serviceFetch,
  serviceUpdate,
  deleteServiceFunction,
  updateServiceFunction,
  addServiceFunction,
} from "../../../actions/Users/authenticate";
import { isValidName } from "../../../utils/validation";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import { makeStyles } from "@material-ui/core/styles";

const scroll = Scroll.animateScroll;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));


function UpdateServices({
  history,
  authReducer,
  errorReducer,
  serviceFetch,
  serviceUpdate,
  location,
  deleteServiceFunction,
  updateServiceFunction,
  addServiceFunction
}) {
  const classes = useStyles();
  const [Name, setName] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [description, setDescription] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [textFieldCount, setTextFieldCount] = useState(1);
  const [inputList, setInputList] = useState(null);
  const [rootServices, setRootServices] = useState([
    {
      function: "",
      isFunctionValid: true,
      isDescriptionValid: true
    },
  ]);
  // const [textFieldResources, setTextFieldResources] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: null, type: "error" });
  const [loaders, setLoaders] = useState({ showLoader: false, loadingText: "" });
  // const [notErrorInFields, setNotErrorInFields] = useState({ Name: true, isFunctionValid: true });
  const [helperTextList, setHelperTextList] = useState({
    Name: "Name is invalid",
    isFunctionValid: "Function is invalid"
  });
  const [updateDisabled, setUpdateDisabled] = useState(true)
  const [deleteDisabled, setDeleteDisabled] = useState(true)
  const [selectedServiceFunction, setSelectedServiceFunction] = useState("")
  const [selectedServiceFunctionDesc, setSelectedServiceFunctionDesc] = useState("")
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [popupMessage, setPopupMessage] = useState("")
  const [showUpdateAlert, setShowUpdateAlert] = useState(false)
  const [updatedServiceFunctionName, setUpdatedServiceFunctionName] = useState("")
  const [updatedServiceFunctionDesc, setUpdatedServiceFunctionDesc] = useState("")
  const [showAddAlert, setShowAddAlert] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceUpdate)) {
      setLoaders({ showLoader: false, loadingText: "" });
      setShowPopUp(true);
      authReducer.serviceUpdate = {};
    }
  }, [authReducer.serviceUpdate]);

  useEffect(() => {
    serviceFetch(
      location.state.demo1 ? location.state.demo1 : "K4Mobility",
      location.state.demo
    );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(authReducer.serviceFetch)) {
      if (authReducer.serviceFetch.data) {
        const data = authReducer.serviceFetch.data;
        if (Array.isArray(data) && data.length > 0) {
          if (data[0].name) {
            setName(data[0].name);
          }

          if (data[0].description) {
            setDescription(data[0].description);
          }
          let services = [];
          if (data[0].servicefunctions && Array.isArray(data[0].servicefunctions)) {
            for (let servicefunction of data[0].servicefunctions) {
              services.push({ 
                'function': servicefunction.name,
                'description': servicefunction.description,
                isFunctionValid: true,
                isDescriptionValid: true
              });
            }
          }
          setRootServices(services);
          setTextFieldCount(services.length);
        }
      }
    }
  }, [authReducer.serviceFetch]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({ msg: errorReducer.description, type: "error" });
        setShowAlert(true);
      }
      setLoaders({ showLoader: false, loadingText: "" });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(()=>{
    if (!_.isEmpty(authReducer.serviceFunctionDelete)){
      setLoaders({ showLoader: false, loadingText: "" });
      setShowDeleteAlert(false)
      setPopupMessage("Service Function Deleted Successfully")
      setShowPopUp(true);
      authReducer.serviceFunctionDelete = {}
    }
  }, [authReducer.serviceFunctionDelete])

  useEffect(()=>{
    if (!_.isEmpty(authReducer.serviceFunctionUpdate)){
      setLoaders({ showLoader: false, loadingText: "" });
      setShowUpdateAlert(false)
      setPopupMessage("Service Function Updated Successfully")
      setShowPopUp(true);
      authReducer.serviceFunctionUpdate = {}
      setUpdatedServiceFunctionName("")
      setUpdatedServiceFunctionDesc("")
    }
  }, [authReducer.serviceFunctionUpdate])

  useEffect(()=>{
    if (!_.isEmpty(authReducer.serviceFunctionAdd)){
      setLoaders({ showLoader: false, loadingText: "" });
      setShowUpdateAlert(false)
      setPopupMessage("Service Function Added Successfully")
      setShowPopUp(true);
      authReducer.serviceFunctionAdd = {}
      setUpdatedServiceFunctionName("")
      setUpdatedServiceFunctionDesc("")
    }
  }, [authReducer.serviceFunctionAdd])

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    if (name === "Name") {
      setName(event.target.value);
    }
    if (name === "description") {
      setDescription(event.target.value);
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rootServices];
    if ("function" === name) {
      list[index]["function"] = value;
      list[index]["isFunctionValid"] = isValidName(list[index]["function"]);
    }
    if ("description" === name) {
      list[index]["description"] = value;
      list[index]["isDescriptionValid"] = isValidName(list[index]["description"]);
    }

    setInputList(list);
    setDisableSave(isValidName(value));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...rootServices];
    list.splice(index, 1);
    let isError = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i]["isValid"] === false) {
        isError = 1;
        break;
      }
    }
    if (isError === 1) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
    setRootServices(list);
    setTextFieldCount(textFieldCount - 1);
  };

  const handleAddClick = () => {
    setUpdatedServiceFunctionName("")
    setUpdatedServiceFunctionDesc("")
    setShowAddAlert(true)
    // const list = [...rootServices];
    // list.push({ function: "", description: "", isFunctionValid: true, isDescriptionValid: true });
    // setRootServices(list);
    // setTextFieldCount(textFieldCount + 1);
  };

  const handleUpdateOu = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
    let functionList = [];
    for (let i = 0; i < rootServices.length; i++) {
      functionList.push({ name: rootServices[i]["function"], description: rootServices[i]["description"] });
    }
    let payload = {
      name: Name,
      serviceFunctions: functionList,
      description: description,
      ouName: location.state.demo1 ? location.state.demo1 : "K4Mobility",
    };
    serviceUpdate(payload);
    setLoaders({ showLoader: true, loadingText: "Updating the service.." });
  };

  const handleCheckboxClick = (e, name, description)=>{
    setSelectedServiceFunction(name)
    setSelectedServiceFunctionDesc(description)
    setUpdateDisabled(false)
    setDeleteDisabled(false)
  }

  const confirmDelete = () => {
    setShowDeleteAlert(!showDeleteAlert);
  };


  const handleUpdateServiceFunction = ()=>{
    let payload = {
      serviceName: Name,
      serviceFunctionName: selectedServiceFunction,
      serviceFunctionNewName: updatedServiceFunctionName,
      description: updatedServiceFunctionDesc
    }
    updateServiceFunction(payload)
  }

  const handleDeleteServiceFunction = ()=>{
    let payload = {
      serviceName: Name,
      serviceFunctionName: selectedServiceFunction
    }
    deleteServiceFunction(payload)
  }

  const handleAddServiceFunction = () =>{
    let payload = {
      serviceName: Name,
      serviceFunctionName: updatedServiceFunctionName,
      description: updatedServiceFunctionDesc
    }
    addServiceFunction(payload)
  }

  const handleUpdateClick = ()=>{
    setShowUpdateAlert(true)
    setUpdatedServiceFunctionName(selectedServiceFunction)
    setUpdatedServiceFunctionDesc(selectedServiceFunctionDesc)
  }

  const handleUpdateSFName = (e)=>{
    setUpdatedServiceFunctionName(e.target.value)
  }

  const handleUpdateSFDescription = (e)=>{
    setUpdatedServiceFunctionDesc(e.target.value)
  }

  return (
    <div className="add-user" style={{ overflow: "hidden" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              marginTop: "6px",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              height: "770px",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "5px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Service"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;{" > " + location.state.demo + " > Update"}
              </DialogContentText>
            </Grid>
            <div className="horizontal-line"></div>
            <Grid style={{ marginTop: "12px", width: "100%" }}>
              <div className="innerDiv">
                <CustomTypography
                  size={16}
                  weight="600"
                  color="#4B4C63"
                  content="Enter Service Details"
                  lineHeight={17.14}
                />
                <div className="horizontal-line"></div>
                <form
                  onSubmit={(e) => {
                    handleUpdateOu(e);
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Name"
                        lineHeight={19}
                        required="true"
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="Name"
                          value={Name}
                          onChange={handleChange}
                          disabled
                          charLength={50}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={6}>
                      <CustomTypography
                        size={16}
                        weight="normal"
                        color="#5A5757"
                        content="Description"
                        required="true"
                        lineHeight={19}
                        styles={{
                          fontSize: "16px",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      />
                      <Grid style={{ marginTop: "10px", marginRight: "10px" }}>
                        <CustomTextField
                          label=""
                          name="description"
                          value={description}
                          onChange={handleChange}
                          charLength={50}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                    }}
                    onClick={handleAddClick}
                  >
                    Add Service Function
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                    }}
                    onClick={handleUpdateClick}
                    disabled={updateDisabled}
                  >
                    Update Service Function
                  </Button>

                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "#008fbd",
                    }}
                    onClick={confirmDelete}
                    disabled={deleteDisabled}
                  >
                    Delete Service Function
                  </Button>
                  <Grid style={{ background: "#F0F8FF", marginTop: '40px' }}>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: "15px", marginLeft: "10px" }}
                    >
                      <Grid xs={4}>
                        <CustomTypography
                          size={16}
                          weight="normal"
                          color="#5A5757"
                          content="Functions"
                          lineHeight={19}
                          styles={{
                            fontSize: "16px",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>

                    </Grid>
                    {rootServices.map((obj, index) => {
                      return (
                        <div
                          className="box"
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={1}>
                            <Checkbox
                                    onClick={(event) => {
                                      handleCheckboxClick(event,obj.function, obj.description);
                                    }}
                                    role="checkbox"
                                    checked={
                                      selectedServiceFunction &&
                                      selectedServiceFunction === obj.function
                                        ? true
                                        : false
                                    }
                                  />
                            </Grid>
                            <Grid item xs={3} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="function"
                                placeholder="Function name"
                                value={obj.function}
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj["isFunctionValid"] ? "" : "isInvalid"
                                }
                                isValidOrInvalidMessage={
                                  helperTextList["isFunctionValid"]
                                }
                                disabled={true}
                              />
                            </Grid>
                            <Grid item xs={8} style={{ marginBottom: "5px" }}>
                              <CustomTextField
                                name="description"
                                placeholder="Description"
                                value={obj["description"]}
                                onChange={(e) => handleInputChange(e, index)}
                                isValidOrInvalid={
                                  obj.isDescriptionValid ? "" : "isInvalid"
                                }
                                isValidOrInvalidMessage={
                                  helperTextList["isDescriptionValid"]
                                }
                                disabled={true}
                              />
                            </Grid>
                            {/* <Grid>
                              <CloseOutlinedIcon
                                className={
                                  rootServices.length <= 1
                                    ? "iconNotAllow"
                                    : "iconAllow"
                                }
                                onClick={
                                  rootServices.length === 1
                                    ? () => { }
                                    : () => handleRemoveClick(index)
                                }
                              />
                            </Grid> */}
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={4}
                    spacing={2}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item>
                      {/* <CustomButton
                        ButtonType=""
                        ButtonLabel="Update"
                        Disabled={!disableSave}
                        type="submit"
                      /> */}
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "#008fbd",
                          width: "100px",
                        }}
                        className="delete-user-button"
                        onClick={() => history.goBack()}
                      >
                        {"Cancel"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle={popupMessage}
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />

      <Dialog
        open={showDeleteAlert}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px"
              }}
            >
              Please confirm to delete selected service function.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  handleDeleteServiceFunction();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showUpdateAlert}
        onClose={ ()=>{
          setShowUpdateAlert(false)
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}>Update Service Function</div>
              <div
                onClick={()=>{
                  setShowUpdateAlert(false)
                }}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px"
              }}
            >
              <Grid container style={{ padding: "5px" }}>
                <Grid item xs={12} style={{ marginBottom: "5px" }}>
                  <span style={{position:"relative", left:"-100px"}}>Function Name</span>
                  <CustomTextField
                    name="function"
                    placeholder="Function name"
                    value={updatedServiceFunctionName}
                    onChange={(e) => handleUpdateSFName(e)}
                    isValidOrInvalid={
                      updatedServiceFunctionName ? "" : "isInvalid"
                    }
                    isValidOrInvalidMessage={
                      helperTextList["isFunctionValid"]
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "5px" }}>
                  <span style={{position:"relative", left:"-100px"}}>Function Description</span>
                  <CustomTextField
                    name="description"
                    placeholder="Function description"
                    value={updatedServiceFunctionDesc}
                    onChange={(e) => handleUpdateSFDescription(e)}
                    isValidOrInvalid={
                      updatedServiceFunctionDesc ? "" : "isInvalid"
                    }
                    isValidOrInvalidMessage={
                      helperTextList["isDescriptionValid"]
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={ ()=>{
                  setShowUpdateAlert(false)
                }}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Update"
                onClick={(e) => {
                  handleUpdateServiceFunction();
                }}
              >
                Update
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddAlert}
        onClose={ ()=>{
          setShowAddAlert(false)
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}>Add Service Function</div>
              <div
                onClick={()=>{
                  setShowAddAlert(false)
                }}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px"
              }}
            >
              <Grid container style={{ padding: "5px" }}>
                <Grid item xs={12} style={{ marginBottom: "5px" }}>
                  <span style={{position:"relative", left:"-100px"}}>Function Name</span>
                  <CustomTextField
                    name="function"
                    placeholder="Function name"
                    value={updatedServiceFunctionName}
                    onChange={(e) => handleUpdateSFName(e)}
                    isValidOrInvalid={
                      updatedServiceFunctionName ? "" : "isInvalid"
                    }
                    isValidOrInvalidMessage={
                      helperTextList["isFunctionValid"]
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "5px" }}>
                  <span style={{position:"relative", left:"-100px"}}>Function Description</span>
                  <CustomTextField
                    name="description"
                    placeholder="Function description"
                    value={updatedServiceFunctionDesc}
                    onChange={(e) => handleUpdateSFDescription(e)}
                    isValidOrInvalid={
                      updatedServiceFunctionDesc ? "" : "isInvalid"
                    }
                    isValidOrInvalidMessage={
                      helperTextList["isDescriptionValid"]
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={ ()=>{
                  setShowAddAlert(false)
                }}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Update"
                onClick={(e) => {
                  handleAddServiceFunction();
                }}
              >
                Add
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching service details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    serviceFetch,
    serviceUpdate,
    deleteServiceFunction,
    updateServiceFunction,
    addServiceFunction,
  })(UpdateServices)
);
