import React, { useEffect, useState } from "react";
import {
  Grid,
  // Typography,
  Paper,
  Button,
  // Menu,
  // MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import "./policies.css";
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { Close as CloseIcon } from "@material-ui/icons/";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogBox from "../../Components/DialogBox";
import CustomAlert from "../../Components/CustomAlert";
// import TopHeader from "../../Components/TopHeader";
// import SideMenu from "../../Components/SideMenu";
// import Checkbox from "@material-ui/core/Checkbox";
// import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
// import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { policiesGet, policiesDelete } from "../../actions/Users/authenticate";
import CustomLoader from "../../Components/Loader/CustomLoader";
import _ from "lodash";
import moment from "moment";
// import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function Policies({
  history,
  authReducer,
  errorReducer,
  policiesGet,
  policiesDelete,
}) {
  // const [errorMsg, setErrorMsg] = useState(null);
  const classes = useStyles();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [showErrorMsg, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popUp, setPopUp] = useState(false);
  const open = Boolean(anchorEl);
  const [searchResult, setSearchResult] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  const [selPolicyPayload, setSelPolicyPayload] = useState(null);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [alertMessage, setAlertMessage] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  console.log("gascfgawvfhajhvashfgcy", authReducer.loading);

  useEffect(() => {
    policiesGet("K4Mobility");
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setAlertMessage({
        msg: errorReducer.message,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesDelete)) {
      setShowDeleteAlert(false);
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setPopUp(true);
      authReducer.policiesDelete = {};
    }
  }, [authReducer.policiesDelete]);

  const _handlePolicyClick = (e, value = null, policyData) => {
    console.log("insiiddddeeee handleCheckboxClick-->", e.target.value);
    console.log("value66666>>>>>>", value);
    setSelectedPolicy(value);
    setAttachButtonDisable(false);
    setSelPolicyPayload(policyData);
  };

  const confirmDelete = () => {
    setShowDeleteAlert(!showDeleteAlert);
  };

  const handleDeletePolicyClick = () => {
    var selectPolicy = selectedPolicy;
    if (selectPolicy.length) {
      let payload = {
        name: selectedPolicy,
      };
      policiesDelete(payload);
      console.log("delete users", selectedPolicy);
      setLoaders({
        showLoader: true,
        loadingText: "Deleting policy please wait...",
      });
    } else {
      setAlertMsg({
        msg: "It can't be delete",
        type: "error",
      });
      setShowAlert(true);
    }
  };

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(users);
    setSearchResult(users);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _getUsers = () => {
    console.log("@@ authList policies get", authReducer.policiesGet);
    if (authReducer.policiesGet != null) {
      var theUserList = authReducer.policiesGet.data;
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          theUserList[i]["name"] = theUserList[i]["name"]
            ? theUserList[i]["name"]
            : "-";
          theUserList[i]["description"] = theUserList[i]["description"]
            ? theUserList[i]["description"]
            : "-";
        }
        return theUserList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <div
      className={"nav-route-container"}
      style={{ overflow: "hidden", 
      // position: "fixed"
     }}
    >
      {/* <TopHeader /> */}
      {console.log("user clickkkkk", selectedPolicy)}
      {showAlert &&
      showAlert === true &&
      alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      {/* <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10} overflowY="scroll" className="outerDiv"> */}
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              // overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {/* <div
              style={{
                height: "50px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#008FBD",
                  marginTop: "20px",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                {"Policies"}
              </Typography>
            </div> */}
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <div>
                {_getUsers().length != 0 && authReducer.loading === false ? (
                  <Grid
                    container
                    item
                    xs={12}
                    className="policy-container"
                    style={{
                      backgroundColor: "white",
                      border: "1.5px solid #e5e5e5",
                      padding: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          style={{
                            height: "30px",
                            width: "300px",
                            fontSize: "18px",
                            marginRight: "10px",
                          }}
                          type="text"
                          placeholder="Search policy..."
                          onChange={(event) => {
                            console.log("event", event);
                            searchUser(event.target.value);
                          }}
                        />
                        {/* <Button
                    className="add-user-button"
                    onClick={() => history.push("/dashboard/policies/create-policy")}
                  >
                    {"Create Policy"}
                  </Button> */}
                        {/* <div style={{cursor: attachButtonDisable ? "not-allowed" : "pointer",}}>
                    <Button
                      style={{
                        marginLeft: "10px",
                      }}
                      className="delete-user-button"
                      onClick={handleMenu}
                      disabled={attachButtonDisable}
                    >
                      {"Filter Actions"}
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{ top: "35px", width: "200px", height: "500px" }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {/* <MenuItem
                        onClick={() => {
                          history.push({
                            pathname: "/dashboard/policies/create-policy/Attach-UserPolicy",
                            state: { demo: selectedPolicy },
                          })
                        }}
                        className="Menu-item"
                      >
                       &nbsp;&nbsp;&nbsp; Attach Users &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </MenuItem>
                      <MenuItem className="Menu-item" onClick={() => {
                        history.push({
                          pathname: "/dashboard/policies/create-policy/Detch-UserPolicy",
                          state: { demo: selectedPolicy },
                        })
                      }}>
                        &nbsp;&nbsp; Detach Users &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </MenuItem> */}
                        {/* <MenuItem className="Menu-item" onClick={() => {
                        history.push({
                          pathname: `/dashboard/policies/update-policy/?name=${selectedPolicy}`,
                          state: { demo: selectedPolicy },
                        })
                      }}>
                       &nbsp;&nbsp; Update Policy &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </MenuItem>
                      <MenuItem className="Menu-item" 
                      onClick={() => {
                        confirmDelete()
                      }}>
                       &nbsp;&nbsp;&nbsp; Delete Policy &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </MenuItem>
                    </Menu>
                  </div>
                  */}
                      </div>
                      <div
                        className="container"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          className="row"
                          style={{ marginTop: "10px", padding: "10px" }}
                        >
                          <TableContainer
                            component={Paper}
                            style={{ width: "100%" }}
                          >
                            <Table aria-label="simple table" size="small">
                              <TableHead style={{ background: "#ecf5ff" }}>
                                <TableRow style={{ height: "40px" }}>
                                  <TableCell
                                    align="left"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Description
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Created At
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Updated At
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(searchResult.length
                                  ? searchResult
                                  : _getUsers()
                                ).map((row, i) => (
                                  <TableRow
                                    key={row.name}
                                    style={{ height: "40px" }}
                                    hover
                                  >
                                    <TableCell
                                      className="tableBorderRightH"
                                      align="left"
                                      style={{ color: "#384089" }}
                                    >
                                      {/* <Checkbox
                                    size="medium"
                                    checked={
                                      selectedPolicy &&
                                      selectedPolicy === row.name
                                        ? true
                                        : false
                                    }
                                    color="primary"
                                    onChange={(event) => {
                                      _handlePolicyClick(event, row.name, row);
                                    }}
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    classes={{
                                      root: classes.CheckBoxroot,
                                      checked: classes.checked,
                                    }}
                                    icon={<CircleUnchecked />}
                                    checkedIcon={<CircleCheckedFilled />}
                                  /> */}
                                      <span
                                        onClick={() =>
                                          history.push({
                                            pathname:
                                              "/dashboard/policies/summary",
                                            state: {
                                              demo: row.name,
                                              demo1: "K4Mobility",
                                            },
                                          })
                                        }
                                        style={{
                                          marginLeft: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {row.name}{" "}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      align="center"
                                    >
                                      {row.description}
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      align="center"
                                    >
                                      {moment(row.createdAt).format(
                                        "yyyy-MM-D LTS"
                                      )}
                                    </TableCell>
                                    {row.updatedAt != null ? (
                                      <TableCell
                                        className="tableBorderRightH"
                                        align="center"
                                      >
                                        {moment(row.updatedAt).format(
                                          "yyyy-MM-D LTS"
                                        )}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        className="tableBorderRightH"
                                        align="center"
                                      >
                                        -
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>
                    <div className="horizontal-line"></div>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "22px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No Policy Found
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : (
              <Grid>
                <div className="horizontal-line"></div>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: "22px",
                    // fontWeight: "600",
                    marginTop: "15%",
                    backgroundColor: "white",
                    padding: "10px",
                    flexDirection: "column",
                    color: "rgb(245 144 144)",
                  }}
                >
                  You are not authorized to access this feature.
                </Grid>
              </Grid>
            )}
          </div>
        {/* </Grid>
      </Grid> */}
      <DialogBox
        Open={popUp}
        onClose={() => setPopUp(false)}
        DialogTitle="Policy Successfully Deleted"
        ButtonLabel="Done"
        onClick={refreshPage}
      />
      <Dialog
        open={showDeleteAlert}
        TransitionComponent={Transition}
        onClose={confirmDelete}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        position={"bottom"}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogTitle
            className={classes.rootDialog}
            style={{ background: "#384089" }}
            id="alert-dialog-slide-title"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"heading"}></div>
              <div
                onClick={confirmDelete}
                title="Close"
                style={{ marginTop: "3px", cursor: "pointer", color: "#FFF" }}
              >
                <CloseIcon />
              </div>
            </div>
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div
              style={{
                color: "#384089",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              Please confirm to delete selected policy.
            </div>
          </DialogContentText>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="grayButton"
                title="Cancel"
                onClick={confirmDelete}
              >
                Cancel
              </Button>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                variant="contained"
                size="small"
                color="default"
                className="primaryButton"
                title="Click on Confirm for deleting users."
                onClick={(e) => {
                  handleDeletePolicyClick();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching policies please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    policiesGet,
    policiesDelete,
  })(Policies)
);
