import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Paper, DialogContentText } from "@material-ui/core";
import "./DetachUserPolicy.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  userPolicies,
  policiesFetch,
  policiesDetach,
} from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import TopHeader from "../../../Components/TopHeader";
import CustomAlert from "../../../Components/CustomAlert";
import CustomButton from "../../../Components/CustomButton";
import _ from "lodash";
import DialogBox from "../../../Components/DialogBox";
import { makeStyles } from "@material-ui/core/styles";
import CustomLoader from "../../../Components/Loader/CustomLoader";

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function DetchUserPolicy({
  history,
  policiesDetach,
  authReducer,
  errorReducer,
  policiesFetch,
  location,
}) {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorMsg, setShowError] = useState(false);
  const [selectedUsers, updateSelectedUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });

  console.log("location of ou", location);

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    policiesFetch(location.state.demo1, location.state.demo);
  }, []);

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (
      errorReducer &&
      errorReducer.description &&
      errorReducer.description !== ""
    ) {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.description = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      console.log("@@ in error");
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function createData(name, email) {
    return { name, email };
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesFetch)) {
      console.log("authReducer.policiesFetch-> ", authReducer.policiesFetch);
    }
  }, [authReducer.policiesFetch]);

  useEffect(() => {
    if (!_.isEmpty(authReducer.policiesDetach)) {
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowPopUp(true);
      authReducer.policiesDetach = {};
    }
  }, [authReducer.policiesDetach]);

  const handleDetachUser = () => {
    if (
      authReducer.policiesFetch != null &&
      authReducer.policiesFetch.data.length > 0
    ) {
      if (selectedUsers.length > 0) {
        var theUserList = [...authReducer.policiesFetch.data];
        let finalPayload = [];
        let payload = {
          policy: location.state.demo,
          userNames: [],
          ou: location.state.demo1,
        };
        for (let i = 0; i < theUserList.length; i++) {
          if (selectedUsers.includes(i)) {
            if (theUserList[i].hasOwnProperty("emails")) {
              console.log("the userlist", theUserList[i].emails);
              payload.userNames.push(theUserList[i].emails[0]);
            }
          }
        }
        finalPayload.push(payload);
        console.log("finalPaylaod--> ", finalPayload);
        policiesDetach(payload);
        setLoaders({
          showLoader: true,
          loadingText: "Dettaching...",
        });
        console.log("add user list", theUserList, selectedUsers);
      } else {
        alert("Please select the users which you want to added.");
      }
    } else {
      alert("Dummy users cannot be added.");
    }
  };

  const _handleUserClick = (index) => {
    let indexList = [...selectedUsers];
    console.log("user click", authReducer.policiesFetch);
    if (
      authReducer.policiesFetch != null &&
      authReducer.policiesFetch.data.length > 0
    ) {
      var theUsers = [...authReducer.policiesFetch.data];
      for (let i = 0; i < theUsers.length; i++) {
        if (index === i) {
          if (indexList.includes(i)) {
            var arr = indexList.filter((item) => {
              if (item !== i) {
                return true;
              }
              return false;
            });
            updateSelectedUsers(arr);
          } else {
            indexList.push(i);
            updateSelectedUsers(indexList);
          }
        }
      }
    } else {
      return [];
    }
  };

  const _getUsers = () => {
    console.log("@@ authList", authReducer.policiesFetch);
    console.log("@@authList2", authReducer.policiesFetch);
    if (authReducer.policiesFetch != null) {
      var theUserList = authReducer.policiesFetch.data;
      console.log("@@authList3", theUserList);
      var rows = [];
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          let u_name =
            theUserList[i].hasOwnProperty("name") &&
            theUserList[i].name.givenName + theUserList[i].name.familyName !=
              null &&
            theUserList[i].name.givenName + theUserList[i].name.familyName !==
              ""
              ? (theUserList[i].name.givenName
                  ? theUserList[i].name.givenName
                  : "") +
                " " +
                (theUserList[i].name.familyName
                  ? theUserList[i].name.familyName
                  : "")
              : "-";
          let u_email =
            theUserList[i].hasOwnProperty("emails") &&
            theUserList[i].emails != null &&
            theUserList[i].emails !== ""
              ? theUserList[i].emails[0]
              : "-";
          rows.push(createData(u_name, u_email));
          console.log("Getting all users' details ---------->", rows);
        }
        return rows;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(users);
    setSearchResult(users);
  };

  return (
    <div
      className={"nav-route-container"}
      style={{ overflow: "hidden", position: "fixed" }}
    >
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10} overflowY="scroll" className="outerDiv">
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowX: "hidden",
              overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "auto",
            }}
          >
            <div
              style={{
                height: "50px",
                paddingLeft: "10px",
                alignContent: "center",
                display: "flex",
                backgroundColor: "#FBFBFB",
              }}
            >
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => history.goBack()}
              >
                {"Organizational Unit"}
              </DialogContentText>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#008FBD",
                  alignContent: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                &nbsp;
                {" > " +
                  location.state.demo1 +
                  " > Polciy > " +
                  location.state.demo +
                  " > Detach Users"}
              </DialogContentText>
            </div>
            <div className="horizontal-line"></div>
            {_getUsers().length != 0 && authReducer.loading === false ? (
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginLeft: "8px", justifyContent: "space-between" }}
                >
                  <input
                    style={{
                      height: "30px",
                      width: "300px",
                      fontSize: "18px",
                      marginRight: "10px",
                    }}
                    type="text"
                    placeholder="Search..."
                    onChange={(event) => {
                      console.log("event", event);
                      searchUser(event.target.value);
                    }}
                  />
                  <Grid style={{ marginRight: "10px" }}>
                    <CustomButton
                      ButtonLabel="Detach"
                      width="100px"
                      Disabled={selectedUsers.length === 0}
                      onClick={handleDetachUser}
                    />
                  </Grid>
                </Grid>
                <div className="container" style={{ backgroundColor: "white" }}>
                  <div
                    className="row"
                    style={{ marginTop: "10px", padding: "10px" }}
                  >
                    <TableContainer style={{ width: "100%" }} component={Paper}>
                      <Table aria-label="simple table" size="small">
                        <TableHead style={{ background: "#ecf5ff" }}>
                          <TableRow style={{ height: "40px" }}>
                            <TableCell className="tableBorderRightH headerCell">
                              Name
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableBorderRightH headerCell"
                            >
                              Email
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(searchResult.length
                            ? searchResult
                            : _getUsers()
                          ).map((row, i) => (
                            <TableRow
                              key={row.name + i}
                              style={{ height: "40px" }}
                              hover
                            >
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                                style={{ color: "#384089" }}
                              >
                                <Checkbox
                                  onClick={() => {
                                    _handleUserClick(i);
                                  }}
                                  role="checkbox"
                                  classes={{
                                    root: classes.CheckBoxroot,
                                    checked: classes.checked,
                                  }}
                                  checked={selectedUsers.includes(i)}
                                />
                                {row.name}
                              </TableCell>
                              <TableCell
                                className="tableBorderRightH"
                                align="left"
                              >
                                {row.email}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "25px",
                  fontWeight: "600",
                  marginTop: "15%",
                  backgroundColor: "white",
                  padding: "10px",
                  flexDirection: "column",
                  color: "#5A5757",
                }}
              >
                No User Attached to this Policy
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      {/* </Dialog> */}
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="User Successfully Detached"
        ButtonLabel="Done"
        onClick={() => {
          setShowPopUp(false);
          history.goBack();
          setTimeout(() => {
            refreshPage();
          }, 100);
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching users please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    userPolicies,
    policiesFetch,
    policiesDetach,
  })(DetchUserPolicy)
);
