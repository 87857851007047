import React, { useState, useEffect } from "react";
import { Grid, Typography, DialogContentText } from "@material-ui/core";
import TopHeader from "../../../Components/TopHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomAlert from "../../../Components/CustomAlert";
import DialogBox from "../../../Components/DialogBox";
import _ from "lodash";
import { resourceFetch } from "../../../actions/Users/authenticate";
import SideMenu from "../../../Components/SideMenu";
import CustomLoader from "../../../Components/Loader/CustomLoader";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function ResourceSummary({
  history,
  authReducer,
  errorReducer,
  location,
  resourceFetch,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      if (errorReducer.description !== "Child organization does not exist") {
        setAlertMsg({
          msg: errorReducer.description,
          type: "error",
        });
        setShowAlert(true);
      }
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    resourceFetch(location.state.demo);
  }, []);

  useEffect(() => {
    setSummary(authReducer.resourceFetch.data ? authReducer.resourceFetch.data : "-");
  });

  return (
    <div className="add-user" style={{ overflow: "hidden", position: "fixed" }}>
      <TopHeader />
      {showAlert && showAlert === true ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Grid
              container
              item
              style={{
                height: "50px",
                paddingLeft: "2px",
                alignItems: "center",
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard/resources">
                  Resources
                </Link>
                <Typography color="text.primary">Summary - {location.state.demo}</Typography>
              </Breadcrumbs>
            </Grid>
            <br />
            {authReducer.loading === false ? (
              <Grid>
                <div className="innerDiv">
                  <pre>{JSON.stringify(summary, null, 2)}</pre>
                </div>
              </Grid>
            ) : (
              ""
            )}
            <br></br>
          </div>
        </Grid>
      </Grid>
      <DialogBox
        Open={showPopUp}
        onClose={() => setShowPopUp(false)}
        DialogTitle="Resource Successfully Updated"
        ButtonLabel="Done"
        onClick={() => {
          history.push("/dashboard");
        }}
      />
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching resource details please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    //All functions defined in action will come here
    resourceFetch,
  })(ResourceSummary)
);
