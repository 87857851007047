import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import moment from "moment";
import "./groups.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { Close as CloseIcon } from "@material-ui/icons/";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomAlert from "../../Components/CustomAlert";
import TopHeader from "../../Components/TopHeader";
import SideMenu from "../../Components/SideMenu";
import { GroupGet, policiesDelete } from "../../actions/Users/authenticate";
import CustomLoader from "../../Components/Loader/CustomLoader";
import _ from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  CheckBoxroot: {
    fontSize: "13px",
    padding: "0px 10px 0px 0px",
    color: "#384089",
    "&$checked": {
      color: "#384089",
    },
  },
  checked: {},
  rootDialog: {
    flexGrow: 1,
    padding: "5px 10px",
  },
}));

function Groups({
  history,
  authReducer,
  errorReducer,
  GroupGet,
  //   policiesDelete,
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const classes = useStyles();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showErrorMsg, setShowError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popUp, setPopUp] = useState(false);
  const open = Boolean(anchorEl);
  const [searchResult, setSearchResult] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [attachButtonDisable, setAttachButtonDisable] = useState(true);
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  const [selPolicyPayload, setSelPolicyPayload] = useState(null);
  const [alertMsg, setAlertMsg] = useState({
    msg: null,
    type: "error",
  });
  const [alertMessage, setAlertMessage] = useState({
    msg: null,
    type: "error",
  });
  const [loaders, setLoaders] = useState({
    showLoader: false,
    loadingText: "",
  });
  const [searchData, setSearchData] = useState("");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    GroupGet();
  }, []);

  useEffect(() => {
    console.log("resend inside useeffect--> ", errorReducer);
    if (errorReducer && errorReducer.message && errorReducer.message !== "") {
      setAlertMsg({
        msg: errorReducer.description,
        type: "error",
      });
      setAlertMessage({
        msg: errorReducer.message,
        type: "error",
      });
      setLoaders({
        showLoader: false,
        loadingText: "",
      });
      setShowAlert(true);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }, [showAlert]);

  useEffect(() => {
    console.log("errorReducererrorReducererrorReducer", errorReducer);
    if (
      errorReducer &&
      errorReducer.errors &&
      errorReducer.errors.length > 0 &&
      errorReducer.errors[0].msg !== ""
    ) {
      console.log("@@ in error");
      setErrorMsg(errorReducer.errors[0].msg);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 4000);
      errorReducer.message = "";
    }
  }, [errorReducer]);

  function creationGroup(
    name,
    userCount,
    policyCount,
    OU,
    createdAt,
    updatedAt
  ) {
    return { name, userCount, policyCount, OU, createdAt, updatedAt };
  }

  const searchUser = (value) => {
    let users = _getUsers().filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log("hvav", users);
    setSearchResult(users);
  };

  const _getUsers = () => {
    console.log("@@ authListhebhvwgegwbg", authReducer.GroupGet);
    if (authReducer.GroupGet != null) {
      var theUserList = authReducer.GroupGet.data;
      if (theUserList && theUserList.length > 0) {
        for (let i = 0; i < theUserList.length; i++) {
          theUserList[i]["name"] = theUserList[i]["name"]
            ? theUserList[i]["name"]
            : "-";
          theUserList[i]["userCount"] = theUserList[i]["userCount"]
            ? theUserList[i]["userCount"]
            : "0";
          theUserList[i]["policyCount"] = theUserList[i]["policyCount"]
            ? theUserList[i]["policyCount"]
            : "0";
          theUserList[i]["OU"] = theUserList[i]["OU"]
            ? theUserList[i]["OU"]
            : "-";
          theUserList[i]["createdAt"] = theUserList[i]["createdAt"]
            ? theUserList[i]["createdAt"]
            : "-";
          theUserList[i]["updatedAt"] = theUserList[i]["updatedAt"]
            ? theUserList[i]["updatedAt"]
            : "-";
        }
        return theUserList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <div
      className={"nav-route-container"}
      style={{ overflow: "hidden", 
      // position: "fixed" 
    }}
    >
      {/* <TopHeader /> */}
      {console.log("user clickkkkk", selectedPolicy)}
      {showAlert &&
      showAlert === true &&
      alertMsg.msg !== "You are not authorized to access this feature." ? (
        <CustomAlert type={alertMsg.type} msg={alertMsg.msg} />
      ) : (
        ""
      )}
      {/* <Grid item container xs={12}>
        <Grid item xs={2}>
          <SideMenu />
        </Grid>
        <Grid item xs={10} overflowY="scroll" className="outerDiv"> */}
          <div
            style={{
              margin: "10px",
              backgroundColor: "#FFFFFF",
              height: "calc(100vh - 80px)",
              // overflowY: "scroll",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {/* <div
              style={{
                height: "50px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#008FBD",
                  marginTop: "10px",
                  fontFamily: "Nunito, sans-serif",
                }}
              >
                {"Groups"}
              </Typography>
            </div> */}
            {alertMessage.msg !== "UNAUTHORIZED_REQUEST" ? (
              <div>
                {_getUsers().length != 0 && authReducer.loading === false ? (
                  <Grid
                    container
                    item
                    xs={12}
                    className="policy-container"
                    style={{
                      backgroundColor: "white",
                      border: "1.5px solid #e5e5e5",
                      padding: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        style={{
                          height: "30px",
                          width: "300px",
                          fontSize: "18px",
                          marginLeft: "12px",
                        }}
                        type="text"
                        placeholder="Search group..."
                        onChange={(event) => {
                          searchUser(event.target.value);
                        }}
                      />
                      <div
                        className="container"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          className="row"
                          style={{ marginTop: "10px", padding: "10px" }}
                        >
                          <TableContainer
                            component={Paper}
                            style={{ width: "100%" }}
                          >
                            <Table aria-label="simple table" size="small">
                              <TableHead style={{ background: "#ecf5ff" }}>
                                <TableRow style={{ height: "40px" }}>
                                  <TableCell
                                    align="left"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Users
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Policies
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Organization Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Created At
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="tableBorderRightH headerCell"
                                  >
                                    Updated At
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(searchResult.length
                                  ? searchResult
                                  : _getUsers()
                                ).map((row, i) => (
                                  <TableRow
                                    key={row.name}
                                    style={{ height: "40px" }}
                                    hover
                                  >
                                    <TableCell
                                      className="tableBorderRightH"
                                      align="left"
                                      style={{
                                        color: "#384089",
                                        cursor: "pointer",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span
                                        onClick={() =>
                                          history.push({
                                            pathname: `/dashboard/groups/summary-groups/${row.name}`,
                                            state: {
                                              demo: row.name,
                                              demo1: row.OU,
                                            },
                                          })
                                        }
                                        style={{ marginLeft: "8px" }}
                                      >
                                        {row.name}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      style={{
                                        border: "1px solid #E5E5E5",
                                        padding: "10px",
                                        fontSize: "15px",
                                      }}
                                      align="center"
                                    >
                                      {row.userCount}
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      style={{
                                        border: "1px solid #E5E5E5",
                                        padding: "10px",
                                        fontSize: "15px",
                                      }}
                                      align="center"
                                    >
                                      {row.policyCount}
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      style={{
                                        border: "1px solid #E5E5E5",
                                        padding: "10px",
                                        fontSize: "15px",
                                      }}
                                      align="center"
                                    >
                                      {row.OU}
                                    </TableCell>
                                    <TableCell
                                      className="tableBorderRightH"
                                      style={{
                                        border: "1px solid #E5E5E5",
                                        padding: "10px",
                                        fontSize: "15px",
                                      }}
                                      align="center"
                                    >
                                      {moment(row.createdAt).format(
                                        "yyyy-MM-D LTS"
                                      )}
                                    </TableCell>
                                    {row.updatedAt !== "-" ? (
                                      <TableCell
                                        className="tableBorderRightH"
                                        style={{
                                          border: "1px solid #E5E5E5",
                                          padding: "10px",
                                          fontSize: "15px",
                                        }}
                                        align="center"
                                      >
                                        {moment(row.updatedAt).format(
                                          "yyyy-MM-D LTS"
                                        )}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        className="tableBorderRightH"
                                        style={{
                                          border: "1px solid #E5E5E5",
                                          padding: "10px",
                                          fontSize: "15px",
                                        }}
                                        align="center"
                                      >
                                        -
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>
                    <div className="horizontal-line"></div>
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "22px",
                        fontWeight: "600",
                        marginTop: "15%",
                        backgroundColor: "white",
                        padding: "10px",
                        flexDirection: "column",
                        color: "#5A5757",
                      }}
                    >
                      No Group Found
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : (
              <Grid>
                <div className="horizontal-line"></div>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: "22px",
                    // fontWeight: "600",
                    marginTop: "15%",
                    backgroundColor: "white",
                    padding: "10px",
                    flexDirection: "column",
                    color: "rgb(245 144 144)",
                  }}
                >
                  You are not authorized to access this feature.
                </Grid>
              </Grid>
            )}
          </div>
        {/* </Grid>
      </Grid> */}
      {authReducer.loading ? (
        <CustomLoader
          showLoader={true}
          loadingText={
            loaders["loadingText"]
              ? loaders["loadingText"]
              : "Fetching groups please wait..."
          }
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    GroupGet,
    policiesDelete,
  })(Groups)
);
